import React, { useEffect, lazy, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import { COOKIE_AUTH_DETAILS } from '@/config/constants/keys';
import AuthContext from '@/services/auth';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, getQueryStringKeys } from '@/utils/utils';
import { deleteCookie, getCookie } from '@/services/cookie';
import { red } from '@material-ui/core/colors';
let Styled = loadStyle(ParentStyled, 'pages/web/Login');

const config = loadPageConfig();
const Content = lazy(() => import(`@/${config.login[0].name}`));

const Login = (parentProps) => {
  const { history, i18n, fetchLogin, clearRedux } = parentProps;
  const auth = useContext(AuthContext);
  const token = getCookie(COOKIE_AUTH_DETAILS);
  const [redirectedFromFleetops, setRedirectedFromFleetops] = useState(null)
  const commonParentProps = {
    history,
    Styled,
    i18n
  };
  useEffect(() => {
    if (auth?.auth) {
      if (
        window.$environment.SERVICE_PROVIDER === 'spireon' &&
        history.location.state?.search
      ) {
        deleteCookie(COOKIE_AUTH_DETAILS);
        clearRedux();
      } else {
        const redirectionURL = getQueryStringKeys(
          history.location?.search,
          'redirect'
        );
        if(redirectionURL && redirectionURL.includes(window.$environment?.FLEETOPS_URL)){
          setRedirectedFromFleetops(redirectionURL)
        }
        else{
        history.push(
          window.$environment.CURRENT_LAYOUT === 'SpireonNew'
            ? history.location.state?.referrer
            : '/'
        );
      }
    }
    }
  });
  const loginWithToken = () => {
    const newClientToken = getQueryStringKeys(
      history.location.state.search,
      'token'
    );
    if (newClientToken) {
      deleteCookie(COOKIE_AUTH_DETAILS);
      clearRedux();
      fetchLogin({
        apiId: 'handleLogin',
        data: { token: newClientToken },
        callback: (type, message) => {
          if(type == 'mfaEnabled') {
            history.push('/user/mfa')
          }
          else if (type === 'error' && message === 'permission denied') {
            history.push({
              pathname: history.location.state.referrer,
              search: history.location.state.search,
              state: { error: 'permission denied' }
            });
          } else {
            history.push(
              window.$environment.CURRENT_LAYOUT === 'SpireonNew'
                ? history.location.state?.referrer
                : '/'
            );
          }
        }
      });
    }
  };
  useEffect(() => {
    const redirectionURL = getQueryStringKeys(
      history.location?.search,
      'redirect'
    );
    if (
      history.location.state?.search &&
      window.$environment.SERVICE_PROVIDER === 'spireon'
    ) {
      loginWithToken();
    }
    else if(redirectionURL && redirectionURL.includes(window.$environment?.FLEETOPS_URL)){
      setRedirectedFromFleetops(redirectionURL)
    }
    // if(window.$environment.SERVICE_PROVIDER === 'tp' && window?.zE){
    //   window.zE(function() {
    //     window.zE.hide()
    //   })
    // }
    // return ()=>{
    // if(window.$environment.SERVICE_PROVIDER === 'tp' && window?.zE){
    //   window.zE.show()
    // }
    // }
  }, []);

  if (
    history.location.state?.search &&
    window.$environment.SERVICE_PROVIDER === 'spireon'
  )
    return null;
  else
    return (
      <RenderComponent
        {...config.login[0]}
        parentProps={parentProps}
        commonParentProps={commonParentProps}
        component={Content}
        redirectedFromFleetops={redirectedFromFleetops}
      />
    );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    login: state.login
  };
};
const mapDispatch = (dispatch) => {
  return {
    fetchLogin: (data) => dispatch.login.fetchData(data),
    clearRedux: () => dispatch({ type: 'RESET_APP' })
  };
};

export default connect(mapState, mapDispatch)(Login);

Login.propTypes = {
  login: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  fetchLogin: PropTypes.func.isRequired
};
