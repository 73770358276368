import React, { useState, lazy } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Filters from './Filters';
import DataUsageFilters from './DateUsageFilters';

import { Link as LinkDom } from 'react-router-dom';
import { getImagePath, hasValue } from '@/utils/utils';
import { imageUrls, pageUrls } from '@/config/constants/keys';
import { Link as LinkLi } from '@material-ui/core';
import VideoJS from '../Vehicles/videoComponent';
import moment from 'moment';

const layout = window?.$environment?.CURRENT_LAYOUT;

const Breadcrumb = lazy(() => import(`../../../components/Breadcrumb`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Tab = lazy(() => import(`@/components/UI/Tab/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;

const eventTypes = [
  {
    label: 'Parking Motion',
    value: 'PARKING_MOTION'
  },
  {
    label: 'Parking Hit',
    value: 'PARKING_HIT'
  },
  {
    label: 'Parking Heavy Hit',
    value: 'PARKING_HEAVY_HIT'
  },
  {
    label: 'Driving Hit',
    value: 'DRIVING_HIT'
  },
  {
    label: 'Driving Heavy Hit',
    value: 'DRIVING_HEAVY_HIT'
  },
  {
    label: 'Hard Acceleration',
    value: 'HARD_ACCEL'
  },
  {
    label: 'Hard Brake',
    value: 'HARD_BRAKE'
  },
  {
    label: 'Sharp Turn',
    value: 'SHARP_TURN'
  },
  {
    label: 'Harsh Acceleration',
    value: 'HARSH_ACCEL'
  },
  {
    label: 'Harsh Brake',
    value: 'HARSH_BRAKE'
  },
  {
    label: 'Harsh Turn',
    value: 'HARSH_TURN'
  },
  {
    label: 'Severe Acceleration',
    value: 'SEVERE_ACCEL'
  },
  {
    label: 'Severe Brake',
    value: 'SEVERE_BRAKE'
  },
  {
    label: 'Severe Turn',
    value: 'SEVERE_TURN'
  },
  {
    label: 'Potential Incident Low',
    value: 'POTENTIAL_INCIDENT_LOW'
  },
  {
    label: 'Potential Incident Medium',
    value: 'POTENTIAL_INCIDENT_MEDIUM'
  },
  {
    label: 'Potential Incident High',
    value: 'POTENTIAL_INCIDENT_HIGH'
  },
  {
    label: 'Asleep',
    value: 'ASLEEP'
  },
  {
    label: 'Drowsiness',
    value: 'DROWSINESS'
  },
  {
    label: 'Yawn',
    value: 'YAWN'
  },
  {
    label: 'Daydreaming',
    value: 'DAYDREAMING'
  },
  {
    label: 'Using Phone',
    value: 'USING_PHONE'
  },
  {
    label: 'Distracted',
    value: 'DISTRACTED'
  },
  {
    label: 'Smoking',
    value: 'SMOKING'
  },
  {
    label: 'No Seatbelt',
    value: 'NO_SEATBELT'
  },
  {
    label: 'Idle No Driver',
    value: 'IDLE_NO_DRIVER'
  },
  {
    label: 'Headway Monitoring Warning',
    value: 'HEADWAY_MONITORING_WARNING'
  },
  {
    label: 'Headway Monitoring Emergency',
    value: 'HEADWAY_MONITORING_EMERGENCY'
  },
  {
    label: 'Lane Departure Warning',
    value: 'LANE_DEPARTURE_WARNING'
  },
  {
    label: 'Stop Sign Violation',
    value: 'STOP_SIGN_VIOLATION'
  },
  {
    label: 'Power Lost',
    value: 'POWER_LOST'
  },
  {
    label: 'No Driver',
    value: 'NO_DRIVER'
  },
  {
    label: 'Manual',
    value: 'MANUAL'
  },
  {
    label: 'Forward Collision Warning',
    value: 'FORWARD_COLLISION_WARNING'
  }
];
const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Breadcrumb filters={filters} />
      <Component {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;

const Component = ({
  fetchVideoUrl,
  callback,
  eventsData,
  dataUsageList,
  Styled,
  changeEventType,
  changeDataUsageFilter,
  changeDataUsageRequest,
  dataUsageRequest,
  i18n,
  pagination,
  changeRequest,
  request,
  setPagination }) => {

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [cameraModalList, setCameraModalList] = useState({
    header: '',
    content: () => (
      <video width="720" height="445" id="video" controls></video>
    )
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dataUsageColumns = [
    {
      id: 'vehicle',
      numeric: false,
      disablePadding: true,
      label: i18n.t('camera.vehicle'),
      needSorting: true
    },
    {
      id: 'camera_serial_no',
      numeric: false,
      disablePadding: true,
      label: i18n.t('camera.camera_serial_no'),
      needSorting: true
    },
    {
      id: 'data_usage',
      numeric: false,
      disablePadding: true,
      label: i18n.t('camera.tabs.dataUsage'),
      needSorting: true
    },
    {
      id: 'cycle',
      numeric: false,
      disablePadding: true,
      label: i18n.t('camera.cycle'),
      needSorting: true
    },
  ]
  const columns = [
    {
      id: 'vehicle',
      numeric: false,
      disablePadding: true,
      label: i18n.t('camera.vehicle'),
      needSorting: true
    },
    {
      id: 'camera_serial_no',
      numeric: true,
      disablePadding: false,
      label: i18n.t('camera.camera_serial_no'),
      needSorting: true
    },
    {
      id: 'event_type',
      numeric: false,
      disablePadding: false,
      label: i18n.t('camera.event_type'),
      needSorting: true
    },
    {
      id: 'camera_view',
      numeric: false,
      disablePadding: false,
      label: i18n.t('camera.camera_view'),
      needSorting: false
    },
    {
      id: 'event_level',
      numeric: true,
      disablePadding: false,
      label: i18n.t('camera.event_level'),
      needSorting: true
    },
    {
      id: 'date',
      numeric: false,
      disablePadding: false,
      label: i18n.t('camera.date'),
      needSorting: false
    },
    {
      id: 'time',
      numeric: false,
      disablePadding: false,
      label: i18n.t('camera.time'),
      needSorting: true
    },
    {
      id: 'actions',
      label: i18n.t('Actions')
    }
  ];
  const generateRows = () => {
    return eventsData.data ? eventsData?.data?.map(el => { return {...el, camera_view: 360}}) : [];
  };
  const generateDataUsageRows = () => {
    return dataUsageList.data ? dataUsageList.data : []
  }
  const [values, setInitialValues] = useState({
    eventType: null
  });

  const [dataUsageValues, setDataUsageInitialValues] = useState({
    startDate: null,
    endDate: null
  });

  const information = i18n.t('common.tableEmptyMessage');

  const isEventOld = (eventDate, eventTime) => {
    const eventDateTime = moment(`${eventDate}T${eventTime}Z`);
    const sixMonthsAgo = moment().subtract(6, 'months');
    const isOld = eventDateTime.isBefore(sixMonthsAgo);
    return isOld;
  }

  const tabValues = [
    {
      name: i18n.t('camera.tabs.events'),
      value: (
        <Styled.TableWrapper>
          <Filters
            fleetCompanyList={eventTypes}
            filterList={(data) => {
              changeEventType(data)
            }}
            handleReset={() => {
              changeEventType({ eventType: '' })
            }}
            values={values}
            setInitialValues={setInitialValues}
          />
          <Table
            type="server"
            defaultSortBy={{ sortBy: 'vehicle', order: 'asc' }}
            title=""
            changeRequest={changeRequest}
            baseRequest={request}
            totalRecords={eventsData.total}
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            itemsPerPage={10}
            actions={[
              {
                icon: (row) => {
                  return (
                    <LinkLi
                      className="normalLink" onClick={() => {
                        const callbackFunction = (videoUrl) => {
                          setOpen(true)
                          setCameraModalList({
                            header: '',
                            content: () => {
                              return (
                              <div>
                                <VideoJS options={{
                                  autoplay: true,
                                  controls: true,
                                  responsive: true,
                                  fluid: true,
                                  aspectRatio: '16:8',
                                  preload: 'auto',
                                  sources: [{
                                    src: videoUrl,
                                    type: 'video/mp4'
                                  }]
                                }} />
                              </div>
                            )}
                        })
                        }
                        fetchVideoUrl({
                          apiId: 'videoUrlAPI', 
                          data: {
                            clipID: row?.clip_id,
                            isOld: isEventOld(row?.date, row?.time)
                          },
                          callbackFirst: callbackFunction,
                          callbackSecond: callback,
                        })
                      }}
                    >
                      <Icon src={getImagePath(imageUrls.viewMoreIcon)} />
                    </LinkLi>
                  );
                }
              },
              {
                icon: (row) => {
                  return (
                    <LinkLi className="normalLink" onClick={() => {
                      const callbackFunction = (urlVideo) => {
                        let xhr = new XMLHttpRequest();
                        xhr.open('GET', urlVideo, true);
                        xhr.responseType = 'blob';
                        xhr.onload = function () {
                          let urlCreator = window.URL || window.webkitURL;
                          let videoUrl = urlCreator.createObjectURL(this.response);
                          let tag = document.createElement('a');
                          tag.href = videoUrl;
                          tag.target = '_blank';
                          tag.download = 'file.mp4';
                          document.body.appendChild(tag);
                          tag.click();
                          document.body.removeChild(tag);
                        };
                        xhr.onerror = (err) => { };
                        xhr.send();
                      }
                      fetchVideoUrl({
                        apiId: 'videoUrlAPI', 
                        data: {
                          clipID: row?.clip_id,
                          isOld: isEventOld(row?.date, row?.time)
                        },
                        callbackFirst: callbackFunction,
                        callbackSecond: callback,
                      })
                    }} ><Icon src={getImagePath(imageUrls.downloadIcon)} /></LinkLi>

                  );
                }
              }
            ]}
            defaultSort={'full_name'}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
        </Styled.TableWrapper>
      )
    },
    {
      name: i18n.t('camera.tabs.dataUsage'),
      value: (
        <Styled.TableWrapper>
          <DataUsageFilters
            fleetCompanyList={eventTypes}
            filterList={(data) => {
              changeDataUsageFilter(data)
            }}
            handleReset={() => {
              changeDataUsageFilter({ startDate: '', endDate: '' })
            }}
            values={dataUsageValues}
            setInitialValues={setDataUsageInitialValues}
          />
          <Table
            type="server"
            defaultSortBy={{ sortBy: 'vehicle', order: 'asc' }}
            totalRecords={dataUsageList.total}
            title=""
            changeRequest={changeDataUsageRequest}
            baseRequest={dataUsageRequest}
            columns={[...dataUsageColumns]}
            data={generateDataUsageRows()}
            emptyMessage={information}
            itemsPerPage={10}
            actions={[]}
            defaultSort={'vehicle'}
            i18n={i18n}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
          />
          <Modal
            open={open}
            setOpen={setOpen}
            data={cameraModalList}
            width={'760px'}
            withCloseIcon
            alignItemsLeft
            removeTopSpace
          />
        </Styled.TableWrapper>
      )
    }
  ];
  return (
    <>
      <Styled.Wrapper>
        <Styled.BottomWrapper>
          <Tab
            data={tabValues}
            currentTab={value}
            onChange={handleChange}
            className="cameraTabs"
          />
        </Styled.BottomWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  callback: PropTypes.func.isRequired,
  vehicles: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  filterSuccess: PropTypes.func.isRequired,
};
