import React, { useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import {
  loadStyle,
  objectArrayFromSameField,
  loadPageConfig
} from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/CompanyData');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.companyDataEdit[0].name}`));
const Content = lazy(() => import(`@/${config.companyDataEdit[1].name}`));
const TopActions =
  config.companyDataEdit[0].children &&
  lazy(() => import(`${config.companyDataEdit[0].children.name}`));

const CompanyData = (parentProps) => {
  const { companyData, getCompanyData, history, i18n, theme, module } = parentProps;
  const commonParentProps = { history, i18n, Styled, theme };

  const stateToDisplay = objectArrayFromSameField(companyData?.states);
  const webfleetProviders = objectArrayFromSameField(companyData?.webfleetProviders);
  const allCycleRules = objectArrayFromSameField(companyData?.allCycleRules);
  const allCycleExceptions = objectArrayFromSameField(
    companyData?.allCycleExceptions
  );
  const allVehicleTypes = objectArrayFromSameField(
    companyData?.allVehicleTypes
  );
  useEffect(() => {
    if (Object.keys(companyData.data).length < 1) {
      getCompanyData({
        apiId: 'getCompanyData',
        data: {
          error: ''
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RenderComponent
        {...config[module][0]}
        parentProps={{
          ...parentProps
        }}
        commonParentProps={commonParentProps}
        component={Title}
        childComponent={TopActions}
      />
      <RenderComponent
        {...config[module][1]}
        parentProps={{
          ...parentProps,
          companyData: companyData?.data,
          states: stateToDisplay,
          allCycleRules,
          webfleetProviders,
          allCycleExceptions,
          allVehicleTypes
        }}
        commonParentProps={commonParentProps}
        component={Content}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    companyData: state.companyData
  };
};
const mapDispatch = (dispatch) => {
  return {
    getCompanyData: (data) => dispatch.companyData.fetchData(data),
    saveCompanyData: (data) => dispatch.companyData.saveData(data),
    cancelSubscription: (data) => dispatch.companyData.cancelSubscription(data),
    updateCard: (data) => dispatch.companyData.updateCard(data),
    updateCardDetails: (data) => dispatch.companyData.updateCardDetails(data)
  };
};

export default connect(mapState, mapDispatch)(CompanyData);

CompanyData.propTypes = {
  login: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  fetchLogin: PropTypes.func.isRequired
};
