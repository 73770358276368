import React, { useState, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import RenderComponent from './RenderComponent';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const Filters = ({
  hasApiStatus,
  callback,
  history,
  fleetCompanyList,
  filterList,
  handleReset,
  values, setInitialValues
}) => {

  const [validationError, setValidationError] = useState('');

  return (
    <>
      <Form
        type="server"
        direction="row"
        initialValues={{ ...values }}
        className="CSAForms"
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          setValidationError('');
          const finalData = { ...fields, eventType: fields.eventType?.value };
          if (fields.eventType?.value ) {
            Object.keys(finalData).forEach(
              (key) => !finalData[key] && delete finalData[key]
            );
            filterList(finalData);
          } else {
            setValidationError('Please select filter');
          }
        }}
        handleOnCancel={(fields) => {
          handleReset();
          setInitialValues({
            eventType: null
          });
          setValidationError('');
        }}
        customProps={{
          history,
          callback,
          hasApiStatus,
          validationError,
          fleetCompanyList,
          setInitialValues
        }}
      />
    </>
  );
};

Filters.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

export default Filters;
