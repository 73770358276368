import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import React, { lazy, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { imageUrls, pageUrls } from '@/config/constants/keys';
import {
  getImagePath,
  hasValue, isInsuracePortalUser
} from '@/utils/utils';
import { Link as LinkLi } from '@material-ui/core';
import TopActions from './TopActions';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const CheckBox = lazy(() => import(`@/components/UI/Toggle/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} filters={filters} />
    </Box>
  );
};
export default Index;

const Component = ({
  history,
  fleetCompanies,
  apiStatus,
  fetchFleetCompanies,
  callback,
  filterSuccess,
  Styled,
  i18n,
  topAction,
  pagination,
  setPagination,
  filters,
  setremoveData,
  removeData,
  columns
}) => {
  useEffect(() => {
    setTableData(fleetCompanies.filteredList);
  }, [fleetCompanies.filteredList]);

  const isInsurancePortalUserRole = isInsuracePortalUser();
  const [tableData, setTableData] = React.useState(fleetCompanies.filteredList);

  const generateRows = () => {
    const updatedList = tableData.map((item, index) => {
      let leftCurve = item.severity?.green ? 'green' : item.severity?.yellow ? 'yellow' : 'red'
      let rightCurve = item.severity?.red ? 'red' : item.severity?.yellow ? 'yellow' : 'green'
      item.severityGraph = item.severity ? (<div className="severity-wrapper">
        <div style={{width: `${item.severity?.green}%`}} className={`green-wrapper ${leftCurve == 'green' ? 'leftCurve addBorder' : ''} ${rightCurve == 'green' ? 'rightCurve' : ''}`}></div>
        <div style={{width: `${item.severity?.yellow}%`}} className={`yellow-wrapper ${item.severity?.yellow ? 'addBorder' : ''} ${leftCurve == 'yellow' ? 'leftCurve' : ''} ${rightCurve == 'yellow' ? 'rightCurve' : ''}`}></div>
        <div style={{width: `${item.severity?.red}%`}} className={`red-wrapper ${leftCurve == 'red' ? 'leftCurve' : ''} ${rightCurve == 'red' ? 'rightCurve' : ''}`}></div>
      </div>) : (
        <div className="severity-wrapper">
        <div style={{width: `100%`}} className={`green-wrapper leftCurve addBorder rightCurve`}></div>
      </div>
      );
      item.alerts = ( 
        <Link to={pageUrls.fleetAlertsViewMore.replace(':id', item.id)}>
          View Alerts
        </Link>
      );
      return item;
    });
    return updatedList;
  };

  const hasUserListApiStatus = hasValue(apiStatus.status, 'api', fetchFleetCompanies);
  const information =
    hasUserListApiStatus && hasUserListApiStatus.errorMessage ? (
      <Message
        type="error"
        message={i18n.t(hasUserListApiStatus.errorMessage)}
      />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );

  return (
    <>
      <Styled.Wrapper>
        {topAction && (
          <TopActions
            {...{
              history,
              fleetCompanies,
              callback,
              filterSuccess,
              Styled,
              i18n,
              setPagination,
              filters,
              setremoveData,
              removeData
            }}
          />
        )}
        {isInsurancePortalUserRole && <div className="renewalsFilter">
          <CheckBox
            label={i18n.t('onlyUpcomingRenewals')}
            type="NormalGrey"
            onClick={() => {
              history.push(window.location.href.includes('upcomingRenewals') ? pageUrls.fleetCompanies : pageUrls.upcomingRenewalCompanies)
            }}
            checked={window.location.href.includes('upcomingRenewals')}
          />
        </div>}
        <Styled.TableWrapper>
          <Table
            type="client"
            defaultSortBy={{ sortBy: 'full_name', order: 'asc' }}
            title=""
            columns={[...columns]}
            data={generateRows()}
            emptyMessage={information}
            itemsPerPage={10}
            actions={[
              {
                icon: (row, index) => {
                  return (
                    <LinkLi
                      component={Link}
                      to={pageUrls.scoreCard.replace(':id', row.id)}
                    >
                      <Icon
                        src={getImagePath(imageUrls.scoreCard)}
                        tooltip={'Score card'}
                      />
                    </LinkLi>
                  );
                }
              },
              {
                icon: (row, index) => {
                  return (
                    <LinkLi
                      component={Link}
                      to={pageUrls.companiesEdit.replace(':id', row.id)}
                    >
                      <Icon
                        src={getImagePath(imageUrls.edit)}
                        tooltip={i18n.t('Edit')}
                      />
                    </LinkLi>
                  );
                }
              }
            ]}
            pageinationReset={pagination}
            pagenationClicked={(e) => {
              setPagination(!e);
            }}
            i18n={i18n}
          />
        </Styled.TableWrapper>
      </Styled.Wrapper>
    </>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired,
  fleetCompanies: PropTypes.object.isRequired,
  fetchUsers: PropTypes.func.isRequired,
  filterSuccess: PropTypes.func.isRequired
};
