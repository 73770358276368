/* eslint-disable no-unused-expressions */
import React, {
  lazy,
  forwardRef,
  useImperativeHandle,
  useState,
  useRef,
  useEffect
} from 'react';
import lodash from 'lodash';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';

import { getImagePath, hasEnabledFeature, hasRestrictedRole } from '../../../utils/utils';
import { imageUrls } from '../../../config/constants/keys';
import { COOKIE_AUTH_DETAILS } from '@/config/constants/keys';
import { getCookie } from '../../../services/cookie';

const Chat = require('twilio-chat');

const layout = window?.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`../../UI/Input/${layout}`));
const Select = lazy(() => import(`../../UI/Select/${layout}`));
const Button = lazy(() => import(`../../UI/Button/${layout}`));
const Search = lazy(() => import(`../../UI/SimpleSearch/${layout}`));

const Chats = forwardRef((props, ref) => {
  const {
    ChatWrapper,
    getDrivers,
    chat,
    sendBroadCastMessageApi,
    createChatToken,
    addUsertoChannel,
    setChatPopupOpen
  } = props;
  let tempArray = [];
  const [open, setOpen] = useState('none');
  const [newConversation, setOpen1] = useState('none');
  const [openWindow, setOpen2] = useState('block');
  const [openBroadcast, setOpen3] = useState('none');
  const [openMsgWindow, setOpen4] = useState('block');
  const [newConversation2, setOpen5] = useState('none');
  const [openRightWindow, rightWin] = useState('none');
  const [widthAlter, setWidth] = useState('400px');
  const [currentUser, setcurrentUser] = useState('');
  const [incomingMessageList, setInMessageList] = useState([]);
  const [incomingMessageList2, setInMessageList2] = useState([]);
  const [messageListAll, setMessageListAll] = useState([]);
  const [client, setClient] = useState('');
  const [userName, setUserName] = useState('');
  const inputref = useRef();
  const SelectRef = useRef();
  const [generalChannel, setgeneralChannel] = useState(null);
  const [drivers, setdrivers] = useState([]);
  const [drivers1, setdrivers1] = useState([]);
  const [isLoaderLoading, setLoader] = useState(false);
  const [selectedUserforChat, setselectedUserforChat] = useState({});
  const [noDriver, setnoDriver] = useState('none');
  const [message, setMessage] = useState('');
  const [broadcastmessageText, setbroadcastmessageText] = useState('');
  const [broadcastUsers, setbroadcastUsers] = useState('clear');
  const [removeData, setremoveData] = useState('false');
  const token = getCookie(COOKIE_AUTH_DETAILS);
  // const [isZendeskChatOpen, setIsZendeskChatOpen] = useState(false)

  useEffect(() => {
    setOpen(props?.open);
    if (props?.open) {
      initialFunction();
    }
    // if(window.$environment.SERVICE_PROVIDER === 'tp' && window?.zE){
    //   window.zE('webWidget:on', 'close', function() {
    //     setIsZendeskChatOpen(false)
    //   });
    //   window.zE('webWidget:on', 'open', function() {
    //     setIsZendeskChatOpen(true)
    //   });
    // }
    if (
      window.$environment.APP_LAYOUT !== 'OldUi' &&
      hasEnabledFeature('chatEnabled') &&
      Object.keys(chat.chatToken).length === 0 &&
      !window.location.href.includes('resetPassword') &&
      token
    ) {
      createChatToken({
        apiId: 'createChatToken'
      });
      getDrivers({ apiId: 'getDrivers' });
    }
  }, [props?.open]);

  // let broadcastmessageText = '';
  let username;
  let isMsgSend = false;
  let previousMsg = '';
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday'
  ];
  const { i18n } = useTranslation();
  useImperativeHandle(ref, () => ({
    handleOpen1(event) {
      setOpen(event);
      setLoader(true);
    },
    handleClose(event) {
      setOpen(false);
    }
  }));
  const closeChat = () => {
    setChatPopupOpen && setChatPopupOpen(false);
    var elements = document.getElementsByClassName('chat-tile');
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].classList.remove('selected-chat-highlight');
    }

    setremoveData('clear');
    setOpen('none');
    setOpen1('none');
    setOpen2('none');
    setOpen3('none');
    rightWin('none');
    setWidth('400px');
    goBack();
    goBack2();
  };
  const newConversationClick = (e) => {
    setremoveData('false');
    var elements = document.getElementsByClassName('chat-tile');
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].classList.remove('selected-chat-highlight');
    }
    setLoader(true);

    if (widthAlter === '780px') {
      setWidth('780px');
      setOpen1('none');
      setOpen2('flex');
      setOpen4('none');
      rightWin('inline-block');
      setOpen5('block');
    } else {
      setOpen1('block');
      setOpen2('none');
      setWidth('400px');
    }
    getDriversList();
  };
  const broadcastClick = (e) => {
    setremoveData('false');
    var elements = document.getElementsByClassName('chat-tile');
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].classList.remove('selected-chat-highlight');
    }
    setLoader(true);
    setOpen3('block');
    setOpen2('none');
    setWidth('400px');
    rightWin('none');
    getDriversList();
  };
  const goBack = (e) => {
    setInMessageList([...incomingMessageList2]);
    setremoveData('clear');
    setbroadcastUsers('clear');
    setbroadcastmessageText('');
    setOpen1('none');
    setOpen2('block');
    setOpen3('none');
    rightWin('none');
    setWidth('400px');
  };
  const dropdownChange = (e) => {
    setbroadcastUsers(e);
  };
  const selectChange = (e) => {
    setInMessageList([...e]);
  };

  const selectChange1 = (e) => {
    setdrivers([...e]);
  };
  const selectChange2 = (e) => {
    setdrivers([...e]);
  };
  const messageText = (e) => {
    setMessage(e);
  };
  const broadcastmessage = (e) => {
    setbroadcastmessageText(e);
    // broadcastmessageText = e
  };
  const openChatWindow = (e, index) => {
    // scrollToBottom('chatContainer');
    setselectedUserforChat(e);
    setLoader(true);
    messageListAll.length ? (messageListAll.length = 0) : '';
    var elements = document.getElementsByClassName('chat-tile');
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].classList.remove('selected-chat-highlight');
    }

    setWidth('780px');
    setOpen4('inline-block');
    rightWin('inline-block');

    if (e && e.username && e.username.length > 0 && openBroadcast === 'none') {
      setcurrentUser(e.username);
      var selectedChannel = e.dataChannel;
      if (
        selectedChannel !== undefined &&
        selectedChannel !== null &&
        selectedChannel !== ''
      ) {
        client.getChannelByUniqueName(selectedChannel).then((channel) => {
          setgeneralChannel(channel);
          setupChannel(channel);
          getRecentMessagesOfChannel(channel);
        });
      }

      if (e.type === 'driver') {
        // var link = document.getElementById('newSearch');
        // link.click();
        setOpen2('block');
        setOpen1('none');
        setOpen5('none');
        createChannelForUser(e.userId, e.userIdentity);
        var channelId =
          'ch_' + getUserIdFromIdentity(userName) + '_' + e.userId;
        var presentOrNot = incomingMessageList.find(
          (checkUsername) => checkUsername.dataChannel === channelId
        );
        if (presentOrNot !== undefined) {
          var presentNode = document.querySelectorAll(
            `[data-channel= ${presentOrNot.dataChannel}]`
          );
          for (var a = 0; a < presentNode.length; a++) {
            presentNode[a].classList.add('selected-chat-highlight');
          }

          // scrollToBottom('chatContainer');
        } else {
          let index = 0;
          var channel =
            'ch_' + getUserIdFromIdentity(userName) + '_' + e.userId;
          var user = e.userIdentity;
          var userFullName = getFullNameFromIdentity(user);
          let obj = {
            username: userFullName,
            lastMessage: '',
            dataChannel: channel,
            type: 'newDriver',
            userId: e.userId
          };
          let temp = incomingMessageList;
          temp.unshift(obj);
          setInMessageList([...temp]);
          setInMessageList2([...temp]);
          document.getElementById('openChatWindow' + index) &&
            document
              .getElementById('openChatWindow' + index)
              .classList.add('selected-chat-highlight');
        }
      } else {
      }
    }
    setLoader(false);
  };

  const createChannelForUser = (userId, userIdentity) => {
    if (client === undefined) {
      createToken(createTokenAndJoinChannel(userId, userIdentity));
    } else {
      createOrJoinGeneralChannel(
        client,
        buildChannelName(userId),
        userIdentity
      );
    }
  };
  const createToken = () => {};

  const createTokenAndJoinChannel = (userId, userIdentity) => {
    setUserName(chat.chatToken.identity);
    Chat.Client.create(chat.chatToken.token).then(function (chatClient) {
      setClient(chatClient);
      createOrJoinGeneralChannel(
        chatClient,
        buildChannelName(userId),
        userIdentity
      );
    });
  };

  const buildChannelName = (userId) => {
    return 'ch_' + getUserIdFromIdentity(userName) + '_' + userId;
  };

  const getUserIdFromIdentity = (identity) => {
    return identity.split('_')[0];
  };

  // const addUserToChannelCallback = () => {
    // setLoader(false);
    // setupChannel(generalChannel);
    // setgeneralChannel(generalChannel);
  // };

  const createOrJoinGeneralChannel = (
    chatClient,
    channelName,
    userIdentity
  ) => {
    chatClient
      .getChannelByUniqueName(channelName)
      .then((channel) => {
        addUsertoChannel({
          data: {
            channelName: channel.uniqueName,
            userId: userIdentity
          },
          apiId: 'addUserToChanelChat',
          // callback: addUserToChannelCallback
        });

        setupChannel(channel);
        setgeneralChannel(channel);
        getRecentMessagesOfChannel(channel);
      })
      .catch((error) => {
        // console.log('Chat_error',error)
        setLoader(false);
        chatClient
          .createChannel({
            uniqueName: channelName,
            friendlyName: channelName,
            isPrivate: true
          })
          .then((channel) => {
            setupChannel(channel);
            setgeneralChannel(channel);
            getRecentMessagesOfChannel(channel);
            addUsertoChannel({
              data: {
                channelName: channel.uniqueName,
                userId: userIdentity
              },
              apiId: 'addUserToChanelChat',
              // callback: addUserToChannelCallback
            });
          })
          .catch((error) => {
            //console.log('Chat_error_', error);
            client
              .getChannelByUniqueName(channelName)
              .then((channel) => {
                setgeneralChannel(channel);
                setupChannel(channel);
                getRecentMessagesOfChannel(channel);
              })
              .catch((error) => {
                //console.log('Chat_error__', error);
              });
          });
      });
  };

  const getDriversList = (e) => {
    if (chat.drivers && chat.drivers.length > 0) {
      var temp = [];
      for (var i = 0; i < chat.drivers.length; i++) {
        var displayName =
          chat.drivers[i].firstName + ' ' + chat.drivers[i].lastName;
        var userId = chat.drivers[i].userId;
        var userIdentity =
          chat.drivers[i].userId +
          '_' +
          chat.drivers[i].firstName +
          ' ' +
          chat.drivers[i].lastName;
        let obj = {
          displayName: displayName,
          userId: userId,
          userIdentity: userIdentity,
          type: 'driver',
          username: displayName,
          label: displayName,
          value: displayName
        };

        temp.push(obj);
      }

      setdrivers([...temp]);
      setdrivers1([...temp]);
    } else {
      setnoDriver('block');
    }
    setLoader(false);
  };

  // Set up channel after it has been found
  const setupChannel = (generalChannel1) => {
    // Join the general channel
    if (generalChannel1.state.status !== 'joined') generalChannel1.join();

    // generalChannel1.join().then(function (channel) {
    isMsgSend = false;
    generalChannel1.on('messageAdded', function (inMessage) {
      let message = inMessage.state;
      if (message.author !== username) {
        if (previousMsg !== message.sid) {
          if (
            generalChannel1.sid !== undefined &&
            message.sid === generalChannel1.sid
          ) {
            printMessageFromHistory(
              message.author,
              message.body,
              message.dateUpdated
            );
          }
        }
        previousMsg = message.sid;
      } else return isMsgSend ? printMessage(message.author, message.body) : '';
      isMsgSend = false;
    });
    // });

    // Listen for new messages sent to the channel
  };

  const getMsgDate = (updateDate) => {
    var msgDay = updateDate.getDate();
    var msgYear = updateDate.getFullYear();
    var msgMonth = month[updateDate.getMonth()].substring(0, 3);
    return msgDay + ' ' + msgMonth + ' ' + msgYear;
  };

  const convertTime = (updateDate) => {
    var currentTime = updateDate,
      hours = currentTime.getHours(),
      minutes = currentTime.getMinutes(),
      currentDate = new Date();
    if (minutes < 10) minutes = '0' + minutes;
    var suffix = 'AM';
    if (hours >= 12) {
      suffix = 'PM';
      hours = hours - 12;
    }
    if (hours === 0) {
      hours = 12;
    }
    var msgDay = weekday[updateDate.getDay()].substring(0, 3);
    var currentDay = weekday[currentDate.getDay()].substring(0, 3);
    if (msgDay !== currentDay)
      return msgDay + ', ' + hours + ':' + minutes + ' ' + suffix;
    else return hours + ':' + minutes + ' ' + suffix;
  };

  const printMessageFromHistory = (fromUser, message, updatedTime) => {
    // setMessageListAll([]);
    // let temp = [];
    if (message && message.length > 0) {
      var timeToDisplay = convertTime(updatedTime);
      var tooltipData =
        getMsgDate(updatedTime) +
        ' at ' +
        (timeToDisplay.indexOf(',') !== -1
          ? timeToDisplay.split(',')[1].trim()
          : timeToDisplay);
      // if(fromUser === username) {
      let obj = {
        msg: message,
        type: fromUser === userName ? 'fromAdmin' : 'fromUser',
        time: timeToDisplay,
        user: getFullNameFromIdentity(fromUser),
        title: tooltipData
      };
      tempArray.push(obj);
      setMessageListAll([...tempArray]);
      // scrollToBottom('chatContainer');
    }
  };

  const getRecentMessagesOfChannel = (channel) => {
    channel.setAllMessagesConsumed();
    channel.getMessages().then(function (messages) {
      const totalMessages = messages.items.length;
      for (var i = 0; i < totalMessages; i++) {
        const message = messages.items[i];
        printMessageFromHistory(
          message.author,
          message.body,
          message.dateUpdated
        );
      }
    });
    setLoader(false);
  };

  useEffect(() => {
    if (messageListAll && messageListAll.length > 0)
      document
        .querySelector('#chatContainer .chatWrapper:last-child')
        .scrollIntoView();
  }, [messageListAll]);

  const sendMessagetoUser = (e) => {
    if (message && message.length > 0) {
      generalChannel.sendMessage(message);
      let obj = {
        msg: message,
        type: 'fromAdmin',
        time: formatAMPM()
      };
      let temp = messageListAll;
      temp.push(obj);
      setMessageListAll([...temp]);
      // scrollToBottom('chatContainer');
      // inputref.current.clearInputField();

      setMessage('');
      isMsgSend = true;
      var ch =
        'ch_' +
        getUserIdFromIdentity(userName) +
        '_' +
        selectedUserforChat.userId;
      var objIndex;
      if (selectedUserforChat.type === 'driver') {
        objIndex = incomingMessageList.findIndex(
          (obj) => obj.dataChannel === ch
        );
      } else {
        objIndex = incomingMessageList.findIndex(
          (obj) => obj.dataChannel === selectedUserforChat.dataChannel
        );
      }
      let a = incomingMessageList.find(
        (element) => element.dataChannel === selectedUserforChat.dataChannel
      );

      if (selectedUserforChat.type !== 'driver') {
        setInMessageList(
          incomingMessageList.map((key, index) => {
            if (key === a) {
              return {
                ...key,
                lastMessage: message,
                msgSenderDisplayName: 'You'
              };
            } else return { ...key };
          })
        );
        setInMessageList2(
          incomingMessageList2.map((key, index) => {
            if (key === a) {
              return {
                ...key,
                lastMessage: message,
                msgSenderDisplayName: 'You'
              };
            } else return { ...key };
          })
        );
      } else {
        setInMessageList(
          incomingMessageList.map((key, index) => {
            if (index === objIndex) {
              return {
                ...key,
                lastMessage: message,
                msgSenderDisplayName: 'You'
              };
            } else return { ...key };
          })
        );
        setInMessageList2(
          incomingMessageList2.map((key, index) => {
            if (index === objIndex) {
              return {
                ...key,
                lastMessage: message,
                msgSenderDisplayName: 'You'
              };
            } else return { ...key };
          })
        );
      }
    }
  };

  useEffect(() => {
    document.querySelector(
      `[data-channel="${selectedUserforChat?.dataChannel}"]`
    ) &&
      document
        .querySelector(`[data-channel="${selectedUserforChat?.dataChannel}"]`)
        .classList.add('selected-chat-highlight');
  }, [selectedUserforChat]);

  // Helper functions to print chat message to the chat window
  const printMessage = (fromUser, message) => {
    if (message && message.length > 0) {
    }
  };

  useEffect(() => {
    var i;
    var divs = document.getElementsByClassName('last-chat-message');
    // for (i = 0; i < divs.length; i++) {
    //   if (divs[i].innerHTML.length > 30) {
    //     divs[i].innerHTML = divs[i].innerHTML.substring(0, 30) + '...';
    //   }
    // }

    var input = document.getElementById('sendMessage');

    input &&
      input.addEventListener('keyup', function (event) {
        if (event.keyCode === 13) {
          // event.preventDefault();
          document.getElementById('sendButton').click();
        }
      });
  });

  const initialFunction = () => {
    setLoader(true);
    incomingMessageList.length ? (incomingMessageList.length = 0) : '';
    incomingMessageList2.length ? (incomingMessageList2.length = 0) : '';
    username = chat.chatToken.identity;
    setUserName(chat.chatToken.identity);
    Chat.Client.create(chat.chatToken.token).then((chatClient) => {
      setClient(chatClient);
      chatClient.getSubscribedChannels().then((channel) => {
        if (channel.items.length !== 0) {
        //  document.getElementById('no_chat_warning').style.display = 'none';
          for (var i = 0; i < channel.items.length; i++) {
            // eslint-disable-next-line no-loop-func
            (function () {
              var subscribedChannel = channel.items[i];
              // (function(){
              channel.items[i].getMembers().then((members) => {
                for (var j = 0; j < members.length; j++) {
                  if (members[j].identity !== username) {
                    listUserAndLastMsg(subscribedChannel, members[j].identity);
                  }
                }
              });
            })();
          }
        }
        // else{
        //   alert('asdaasd')
        //   document.getElementById('no_chat_warning').style.display = 'block';
        // }
      setLoader(false);
      });
    });
  };
  //recent Chats to the channels
  const listUserAndLastMsg = (channel, user) => {
    if (channel !== null && channel.getMessages() !== undefined) {
      channel.getMessages().then((messages) => {
        const totalMessages = messages.items.length;
        if (totalMessages !== undefined || totalMessages !== 0) {
          const message = messages.items[totalMessages - 1];
          if (message) {
            printChatListFromHistory(
              user,
              message.author,
              message.body,
              message.dateUpdated,
              channel
            );
          }
        }
      });
    }
  };

  const printChatListFromHistory = (
    user,
    fromUser,
    message,
    updatedTime,
    channel
  ) => {
    if (message && message.length > 0) {
      var msgSenderDisplayName =
        fromUser === username ? 'You' : getFullNameFromIdentity(fromUser);
      // var timeToDisplay = convertTime(updatedTime);
      // var tooltipData = getMsgDate(updatedTime) + ' at ' + (timeToDisplay.indexOf(',') !== -1 ? timeToDisplay.split(',')[1].trim() : timeToDisplay);
      if (msgSenderDisplayName !== undefined) {
        if (fromUser && fromUser.length > 0) {
          let obj = {
            username: getFullNameFromIdentity(user),
            lastMessage: message,
            dataChannel: channel.uniqueName,
            msgSenderDisplayName: msgSenderDisplayName
          };
          let temp = incomingMessageList;
          temp.push(obj);
          setInMessageList([...temp]);
          setInMessageList2([...temp]);
        } else {
        }
      }

      //  if(selectedUserforChat?.dataChannel) {
      // document.querySelector(`[data-channel="${selectedUserforChat?.dataChannel}"]`) &&
      // document.querySelector(`[data-channel="${selectedUserforChat?.dataChannel}"]`)
      //     .classList.add('selected-chat-highlight');
      //  }
      setLoader(false);
    }
    // var elements = document.getElementsByClassName('chat-tile');
    // for (var i = 0, len = elements.length; i < len; i++) {
    //   elements[i].classList.remove('selected-chat-highlight');
    // }
  };

  const getFullNameFromIdentity = (identity) => {
    var name = identity.split('_')[1];
    return name ? name : identity;
  };

  const formatAMPM = (data) => {
    var date = data == null ? new Date() : new Date(data);
    var year = date.getFullYear();
    var month = ('0' + (date.getMonth() + 1)).slice(-2);
    var day = ('0' + date.getDate()).slice(-2);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime =
      month + '-' + day + '-' + year + ' ' + hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };

  const sendBroadCastMessage = (e) => {
    if (
      broadcastmessageText &&
      broadcastmessageText.length > 0 &&
      broadcastUsers &&
      broadcastUsers.length > 0
    ) {
      sendBroadCastmsg(broadcastmessageText);
    }
  };

  const sendBroadCastmsg = (messageToBroadCast) => {
    setLoader(true);
    // $('.chat-loader').show();
    var array = [];

    for (var i = 0; i < broadcastUsers.length; i++) {
      array.push(broadcastUsers[i].userIdentity);
    }

    var dataToSend = {};
    dataToSend.message = messageToBroadCast;
    dataToSend.userIds = array;

    sendBroadCastMessageApi({
      data: dataToSend,
      apiId: 'sendBroadCastChatApi',
      callback: broadcastMsgCallback
    });
  };

  const broadcastMsgCallback = () => {
    setOpen1('none');
    setOpen2('block');
    setOpen3('none');
    rightWin('none');
    setWidth('400px');
    setMessage('');
    setbroadcastmessageText('');
    setbroadcastUsers('clear');
    incomingMessageList.length = 0;
    incomingMessageList2.length = 0;
    initialFunction();
  };
  const goBack2 = (e) => {
    //Go back from new conv in full width
    setInMessageList([...incomingMessageList2]);
    setremoveData('clear');
    setOpen4('block');
    setOpen5('none');
  };

  return (
    <ChatWrapper
      display={open}
      newConv={newConversation}
      openWin={openWindow}
      broadWin={openBroadcast}
      width={widthAlter}
      openMsgWindow={openMsgWindow}
      openRightWindow={openRightWindow}
      newConv2={newConversation2}
      noDriver={noDriver}
    //   isZendeskChatOpen = {isZendeskChatOpen}
    //   shiftToLeft = {hasEnabledFeature('chatEnabled') &&
    //   !hasRestrictedRole(['DRIVER', 'MECHANIC']) && 
    //   window.$environment.SERVICE_PROVIDER === 'tp' 
    // }
    >
      <div className="chat-popup" id="myForm">
        <div className="chat-close">
          <img
            src={getImagePath(imageUrls.closeWhite)}
            alt="close"
            onClick={() => {
              closeChat();
            }}
            id="chatCloseButton"
          ></img>
        </div>

        <div className="openWindowWrapper">
          <div className="parentWrapper">
            <div className="message-button-wrapper">
              <Button
                label={i18n.t('chat.newConvo')}
                onClick={() => newConversationClick()}
                addPersonIcon
                size={'large'}
                className={'newConversationButton'}
              />
              <Button
                label={i18n.t('chat.broad')}
                onClick={() => broadcastClick()}
                addGroupIcon
                size={'large'}
                className={'broadcastButton'}
              />
            </div>
            <div className="search-wwrapper">
              <Search
                onRemove={removeData}
                onChange={(e) => selectChange(e)}
                fromData
                data={incomingMessageList}
                default={incomingMessageList2}
                username
                type={'initialFunction'}
                // id={'parentSearch'}
                placeHolder={i18n.t('chat.search')}
              >
                {' '}
              </Search>
            </div>
            <div className="chat-details-wrapper">
              <div className="chat-list">
                {incomingMessageList.length > 0 ? (
                  incomingMessageList.map((item, i) => {
                    return (
                      <div
                        className={'chat-tile'}
                        onClick={(e) => openChatWindow(item, i)}
                        key={i}
                        data-channel={item.dataChannel}
                        id={'openChatWindow' + i}
                        type={item.type ? item.type : 'old'}
                      >
                        <div className="chat-person">{item.username}</div>
                        <div className="last-chat-message">
                          {' '}
                          {item.lastMessage
                            ? item.msgSenderDisplayName +
                              ':' +
                              ' ' +
                              item.lastMessage
                            : ''}
                        </div>
                      </div>
                    );
                  })) :  <span className='no_chat_warn' id='no_chat_warning'>No chat history</span>}
              </div>
            </div>
          </div>

          <div className="newConversationWrapper2">
            <div className="headWrapper">
              <div className="go-back" onClick={() => goBack2()}>
                <img src={getImagePath(imageUrls.backButton)} alt="back"></img>
              </div>
              <div className="search-new">
                <Search
                  onRemove={removeData}
                  onChange={(e) => selectChange2(e)}
                  fromData
                  data={drivers}
                  displayName
                  default={drivers1}
                  width={'100%'}
                  type={'getDriversList'}
                  // id={'newSearch2'}
                ></Search>
              </div>
            </div>
            <div className="userWrapper">
              <div className="all-users-list">
                <div className="noDrivers" id="no_drivers">
                  No drivers to list
                </div>
                {drivers.length > 0 &&
                  drivers.map((item, i) => {
                    return (
                      <div
                        className="userNames"
                        onClick={() => openChatWindow(item)}
                        data-id={item.userId}
                        key={i}
                      >
                        {' '}
                        {item.displayName}{' '}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          {/* CHAT WINDOW */}
          <div className="chatWindow">
            <div className="header-wrapper">
              <span> {currentUser} </span>
            </div>
            <div className="chatContainer" id="chatContainer">
              {messageListAll.length > 0 &&
                messageListAll.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        item.type === 'fromAdmin'
                          ? 'chatWrapper right'
                          : 'chatWrapper'
                      }
                    >
                      <div
                        className={
                          item.type === 'fromAdmin'
                            ? 'talk-bubble sb1 rightFloat'
                            : 'talk-bubble tri-right left-in'
                        }
                        title={item.title}
                      >
                        <div className="userName">
                          {' '}
                          {item.type === 'fromAdmin' ? 'You' : item.user}
                        </div>
                        <div className="talktext">{item.msg}</div>
                      </div>
                      <div className="msg-send-time"> {item.time}</div>
                    </div>
                  );
                })}
            </div>
            <div className="chat-send-section">
              <Input
                width={'80%'}
                handleOnChange={(e) => messageText(e)}
                placeholder={i18n.t('chat.sendMessage')}
                ref={inputref}
                id="sendMessage"
                value={message}
              ></Input>
              <div
                className="send-button new-con"
                onClick={() => sendMessagetoUser()}
                id="sendButton"
              >
                <img src={getImagePath(imageUrls.sendMsg)} alt="back"></img>
              </div>
            </div>
          </div>
        </div>

        <div className="newConversationWrapper">
          <div className="headWrapper">
            <div className="go-back" onClick={() => goBack()}>
              <img src={getImagePath(imageUrls.backButton)} alt="back"></img>
            </div>
            <div className="search-new">
              <Search
                onRemove={removeData}
                onChange={selectChange1}
                fromData
                data={drivers}
                displayName
                default={drivers1}
                width={'100%'}
                type={'getDriversList'}
                //  id={'newSearch'}
              ></Search>
            </div>
          </div>
          <div className="userWrapper">
            <div className="all-users-list">
              <div className="noDrivers" id="no_drivers">
                No drivers to list
              </div>
              {drivers.length > 0 &&
                drivers.map((item, i) => {
                  return (
                    <div
                      className="userNames"
                      onClick={() => openChatWindow(item)}
                      data-id={item.userId}
                      key={i}
                    >
                      {' '}
                      {item.displayName}{' '}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>

        <div className="broadcastWrapper">
          <div className="headWrapper">
            <div className="go-back">
              <img
                src={getImagePath(imageUrls.backButton)}
                alt="back"
                onClick={() => goBack()}
              ></img>
            </div>
            <div className="userWrapper2">
              <div className="all-users-list2">
                <Select
                  field={'Select User'}
                  allowSelectAll={drivers.length > 0 ? true : false}
                  // suggestions={lodash.uniq(drivers, 'username')}
                  onChange={(e) => dropdownChange(e)}
                  isMulti={true}
                  placeholder={i18n.t('chat.selectDriver')}
                  value={broadcastUsers}
                  isClearable={true}
                  suggestions={drivers || []}
                  id="selectDriverId"
                  setNullForChat={broadcastUsers}
                ></Select>
              </div>
            </div>
          </div>

          <div className="message-type-wrapper">
            <Input
              width={'80%'}
              handleOnChange={(e) => broadcastmessage(e)}
              placeholder={i18n.t('chat.type')}
              multiLine
              value={broadcastmessageText}
            ></Input>
            <div className="send-button" onClick={() => sendBroadCastMessage()}>
              <img src={getImagePath(imageUrls.sendMsg)} alt="back"></img>
            </div>
          </div>
        </div>
        {isLoaderLoading ? <div className="chat-loader"></div> : ''}
      </div>
    </ChatWrapper>
  );
});

const mapState = (state) => {
  return {
    chat: state.chat
  };
};
const mapDispatch = (dispatch) => {
  return {
    getDrivers: (data) => dispatch.chat.getDrivers(data),
    sendBroadCastMessageApi: (data) => dispatch.chat.sendBroadCastMessage(data),
    createChatToken: (data) => dispatch.chat.createToken(data),
    addUsertoChannel: (data) => dispatch.chat.addUsertoChannel(data)
  };
};

export default connect(mapState, mapDispatch)(Chats);
