import styled from 'styled-components';

export const PaymentWrapper = styled.div`
${({ theme , hidepaymentButton }) => `
.suspended{
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  margin-top: -11px;
}
  background: ${(props) =>
    props.theme.palette.primary
      ? props.theme.palette.primary.main
      : props.theme.colors.PRIMARY_MAIN};
  border-radius: 9px;
  padding: 10px;
  .braintree-sheet__header {
    display: none;
  }
  #dropin-container {
    width: 100%;
    margin-top: -2px;
  }
  .braintree-sheet__content--form .braintree-form__field-group .braintree-form__field .braintree-form__hosted-field {
    border: 0;
    border-bottom: solid 1px #DEE2E5;
  }
  .braintree-sheet__content--form .braintree-form__field-group .braintree-form__label {
    display: none;
  }
  .braintree-placeholder{
    display:  ${hidepaymentButton ? 'none': 'block'};
  }
  .paymentButton {
    display: ${hidepaymentButton ? 'none': 'flex'};
    justify-content: center;
  }
  .captcha-error {
    color: #ff0000
  }
  `}
`;

export const NameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 3%;
  label {
    margin-top: 20px;
    text-transform: none;
  }
  #nameOnCard {
    border-radius 50%;
    font-size: 18px;
    padding-left:25px;
    padding-top:10px;
  }
`;

export const InputWrapper = styled.div`
  flex: 0 0 75%;
  .MuiInputBase-root {
    border-radius: 24px;
  }
  #errorCardHolderName,
  #errorCardHolderMail,
  #errorCardHolderMailRegex {
    color: red;
  }
`;
