import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

import CompanyDataForm from './Form';
import TopActions from './TopActions';

const Index = ({ theme, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  Styled,
  topAction,
  i18n,
  updateCard,
  callback,
  history,
  ...rest
}) => {
  return (
    <Styled.Wrapper>
      {topAction && <TopActions {...{ i18n, updateCard, callback, history, Styled }} />}
      <CompanyDataForm
        {...rest}
        Styled={Styled}
        i18n={i18n}
        updateCard={updateCard}
        callback={callback}
        history={history}
      />
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  Styled: PropTypes.object.isRequired
};
