import React, { lazy, useState } from 'react';

import { Link as LinkDom } from 'react-router-dom';
import { getImagePath } from '@/utils/utils';
import { imageUrls, pageUrls } from '@/config/constants/keys';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const Search = lazy(() => import(`@/components/UI/SimpleSearch/${layout}`));
const Icon = lazy(() => import(`@/components/UI/Icon/${layout}`));
const Export = lazy(() => import(`@/components/UI/Export/${layout}`));
const Import = lazy(() => import(`@/components/UI/Import/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Breadcrumb = lazy(() => import(`../../../components/Breadcrumb`));

const TopActions = ({
  history,
  vehicles,
  callback,
  filterSuccess,
  filterBoth,
  handleExportVehicle,
  handleExportTrailer,
  handleImportVehiclePost,
  handleImportTrailerPost,
  Styled,
  i18n,
  logBook,
  setPagination,
  filters
}) => {
  const beBaseUrl = window?.$environment?.BE_BASE_URL;
  const [openModal, setOpenModal] = useState(false);
  const [openModalRefImport, setModalRefImport] = useState(false);
  const [ModalListObjectImport] = useState({
    header: '',
    content: () => { }
  });
  const [ModalListObjectTemplate] = useState({
    header: '',
    content: () => { }
  });
  const [ModalListObject] = useState({
    header: '',
    content: () => { }
  });
  const [openModalRefTemplate, setModalRefTemplate] = useState(false);

  const exportTrailer = () => {
    handleExportTrailer({
      apiId: 'exportTrailer',
      callback: callback
    });
  }
  const exportVehicle = () => {
    handleExportVehicle({
      apiId: 'exportVehicle',
      callback: callback
    });
  };

  const closeModal = (event) => {
    setOpenModal(false);
  };

  const closeModalImport = (event) => {
    setModalRefImport(false);
  };

  const closeModalTemplate = (event) => {
    setModalRefTemplate(false);
    let dv = document.getElementById('importItems');
    if (dv) {
      if (dv.style.display === 'block') {
        dv.style.display = 'none';
      } else {
        dv.style.display = 'block';
      }
    }
  };

  const selectChange = (e) => {
    filterBoth(e);
    setPagination(true);
  };
  const importVehicles = (file) => {
    handleImportVehiclePost({
      data: file,
      apiId: 'importVehiclePost',
      callback: callback
    });
    closeModalImport();
    if (document.getElementById('fileSelectimportVeh'))
      document.getElementById('fileSelectimportVeh').value = '';
  };
  const importTrailers = (file) => {
    handleImportTrailerPost({
      data: file,
      apiId: 'importTrailerPost',
      callback: callback
    });
    closeModalImport();
    if (document.getElementById('fileSelectimportTra'))
      document.getElementById('fileSelectimportTra').value = '';
  };
  return (
    <Styled.TopWrapper>
      <Styled.SearchWrapper>
        <Search
          onChange={(e) => selectChange(e)}
          default={{ vehicleFilteredList: vehicles.vehicleList, trailerFilteredList: vehicles?.trailerList }}
          fromData
          placeHolder={i18n.t('vehicles.searchVehicleTrailer')}
          query={['unit_id', 'vin', 'driverNames', 'manufact']}
          id='searchVehicle'
        ></Search>
      </Styled.SearchWrapper>
      <Styled.ImpExpWrapper>
        <div className="addNew">
          <Button
            component={LinkDom}
            variant="body2"
            className="linkStyle"
            to={pageUrls.addVehicle}
            onClick={() => {
              history.push(pageUrls.addVehicle);
              closeModal();
            }}
            label={i18n.t('vehicles.addVehicle')}
          ></Button>
          <div className="importWrap">
            <div className="columnSection">
              <div className="importsection">
                <Import
                  onClick={(e) => importVehicles(e)}
                  asBinary
                  importVehicle={i18n.t('vehicles.importVeh')}
                  id="importVeh"
                />
              </div>
              <Link
                variant="body2"
                onClick={() => {
                  let url =
                    beBaseUrl +
                    '/vehicle/csvtemplate' +
                    '?token=' +
                    logBook?.documentToken;

                  document.getElementById(
                    'importVehicleTemplateFrame'
                  ).src = url;
                  closeModalTemplate();
                }}
              >
                <Icon
                  src={getImagePath(imageUrls.importExcel)}
                  tooltip={i18n.t('vehicles.importVehicleTemplate')}
                />
              </Link>
            </div>
            <Export
              src={imageUrls.exportIcon}
              filename={i18n.t('vehicles.exportVehicle')}
              toolTip={i18n.t('vehicles.exportVehicle')}
              buttonName={i18n.t('vehicles.exportVehicle')}
              className="exportContainer"
              onClick={() => exportVehicle()}
            />
            <Export
              src={imageUrls.exportIcon}
              filename={i18n.t('vehicles.exportTrailer')}
              toolTip={i18n.t('vehicles.exportTrailer')}
              buttonName={i18n.t('vehicles.exportTrailer')}
              className="exportContainer"
              onClick={() => exportTrailer()}
            />
          </div>
        </div>
        <div className="importNew">
          <Button
            component={LinkDom}
            variant="body2"
            to={pageUrls.addTrailer}
            className="linkStyle"
            onClick={() => {
              history.push(pageUrls.addTrailer);
              closeModal();
            }}
            label={i18n.t('vehicles.addTrailer')}
          ></Button>
          <div className="importWrap">
            <div className="importsection">
              <Import
                onClick={(e) => importTrailers(e)}
                asBinary
                importVehicle={i18n.t('vehicles.importTra')}
                id="importTra"
              />
            </div>
            <Link
              variant="body2"
              onClick={() => {
                let url =
                  beBaseUrl +
                  '/trailer/csvtemplate' +
                  '?token=' +
                  logBook?.documentToken;

                document.getElementById('importVehicleTemplateFrame').src = url;
                closeModalTemplate();
              }}
            >
              <Icon
                src={getImagePath(imageUrls.importExcel)}
                tooltip={i18n.t('vehicles.importTrailerTemplate')}
              />
            </Link>
          </div>
        </div>
      </Styled.ImpExpWrapper>
      <div className="breadcrumbClass">
        <Breadcrumb filters={filters} />
      </div>
      <Modal
        open={openModalRefImport}
        setOpen={setModalRefImport}
        data={ModalListObjectImport}
        isUserProfile
        optionListPopup
        right={7}
      />
      <Modal
        open={openModalRefTemplate}
        setOpen={setModalRefTemplate}
        data={ModalListObjectTemplate}
        isUserProfile
        optionListPopup
        width={'200px'}
        right={10}
      />

      <Modal
        open={openModal}
        setOpen={setOpenModal}
        data={ModalListObject}
        isUserProfile
        optionListPopup
        top={166}
        right={13}
      />
      <iframe
        id="importVehicleTemplateFrame"
        style={{ display: 'none' }}
      ></iframe>
    </Styled.TopWrapper>
  );
};
export default TopActions;
