import React from 'react';

const PercentageBarGraph = ({ Styled, label, percentage, count, onClick }) => {
  return (
    <>
      <span>{label}</span>
      <Styled.Wrapper onClick={onClick}>
        <Styled.Graph percentage={percentage} />
      </Styled.Wrapper>
      <span>{count}</span>
    </>
  );
};

export default PercentageBarGraph;
