import React, { useState, lazy } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const Payment = lazy(() => import(`@/components/Payment/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Component = ({ Styled, i18n, updateSuspendedCard, callback }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const clickedModal = (e) => {
    setOpen(true);
  };
  const closeModal = (event) => {
    setOpen(false);
  };
  const [ModalListObject, setModalListObject] = useState({
    header: '',
    content: () => {
      return null;
    }
  });
  const [startDate, setStartDate] = useState(
    history?.location?.state?.contractStartDate
  );
  const [endDate, setEndDate] = useState(
    history?.location?.state?.contractFinishDate
  );
  const cardDetails = (data) => {
    let obj = {
      cardHolderName: data.cardHolderName,
      stripeToken: data.stripeToken
    };
    let apiId = 'updateCardDetails';
    updateSuspendedCard({
      apiId,
      data: obj,
      callback: callback,
      history: history,
      i18n: i18n
    });
    closeModal();
  };
  return (
    <Styled.Wrapper>
      <p>
      {i18n.t('suspended.description')} <span className="date">{moment(startDate).format("MM/DD/YYYY")}</span> {i18n.t('suspended.to')} <span className="date">{moment(endDate).format("MM/DD/YYYY")}</span>
        {/* You have active contract from  to  */}
      </p>
      <FormStyle.ButtonsWrapper className="payCard">
        <Button
          type="submit"
          className="buttonStyle"
          label={i18n.t('suspended.buttonText')}
          onClick={() => {
            clickedModal();
            setModalListObject({
              header: '',
              content: () => {
                return (
                  <div className='suspendedCompanyPay'>
                    <Payment
                      suspended
                      // ref={customProps.paymentRef}
                      //  theme={customProps.theme}
                      buttonLabel={i18n.t('suspended.buttonText')}
                      // validationMessage={i18n.t(errors.cardHolderName)}
                      // updateCard={(data) => cardDetails(data)}
                      handleOnChange={(event) => {
                        //   handleEdit(event, {
                        //     field: 'cardHolderName',
                        //     type: 'text'
                        //   });
                      }}
                      updateCard={(data) => cardDetails(data)}
                      validationMessage={i18n.t('common.fieldRequiredMessage')}
                    />
                    <div className='amountCompanyPay'>
                    Amount: ${history?.location?.state?.amount ? history?.location?.state?.amount/100 : 0.00}
                    </div>
                  </div>
                );
              }
            });
          }}
        />
      </FormStyle.ButtonsWrapper>
      <Modal
        open={open}
        setOpen={setOpen}
        data={ModalListObject}
        onCloseModal={closeModal}
        withCloseIcon
        companyData
        alignItemsLeft
      />
    </Styled.Wrapper>
  );
};

export default Component;
