import React from 'react';
import Logo from '../Base';
import { PaymentWrapper, NameWrapper, InputWrapper } from './style';

const Index = (props) => {
  return (
    <Logo
      {...props}
      PaymentWrapper={PaymentWrapper}
      NameWrapper={NameWrapper}
      InputWrapper={InputWrapper}
    />
  );
};

export default Index;
