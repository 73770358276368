import styled from 'styled-components';
import Parent from '../Base/style';
const CheckboxWrapper = styled(Parent)`
${({ theme }) => `
.normalCheckbox {
    label {
      font-size: 14px;
      color: ${theme.colors.FORM_COLOR} !important;
      cursor: pointer;
    }
    svg {
      rect {
        stroke: ${theme.colors.CHECKBOX_BORDER};
        stroke-width: 1px;
        rx: 2;
        ry: 2;
      }
      path {
        stroke: ${theme.colors.CHECKBOX_TICK};
      }
    }
    .unchecked.disabledCheck .square {
      fill:  #889ccc;
    }
    .unchecked.disabledCheck + label {
      color: #889ccc !important;
    }
  }
  .normalCheckbox .Checkbox.unchecked .square {
    ${window?.$environment?.clutchNewUI &&`
    fill-opacity: 1;
    fill: #f0f2f2;
    `}
    
  }
  .normalCheckbox .Checkbox.checked .square {
    ${window?.$environment?.clutchNewUI &&`
    fill-opacity: 1;
    fill: #bdd731;`
  }
  }
  .normalCheckbox svg path {
    ${window?.$environment?.clutchNewUI &&`
    stroke: #fff;`
    }
  }
  `}
`;

export default CheckboxWrapper;
