import React, { lazy, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hasValue } from '@/utils/utils';

import Box from '@material-ui/core/Box';
import TopActions from './TopActions';
const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Table = lazy(() => import(`@/components/UI/Table/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));

const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));

const Index = ({ theme, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;
const Component = ({
  apiStatus,
  Styled,
  paymentInformation,
  fetchPaymentInformation,
  downloadReceipt,
  receipt,
  i18n,
  topAction,
  clearState
}) => {
  // const [pageination, setPageination] = useState(false);
  useEffect(() => {
    return () => {
      clearState();
    };
  }, []);
  useEffect(() => {
    let url = receipt ? receipt : '';
    document.getElementById(`downloadDocument`).src = url;
  }, [receipt]);

  const hasApiStatus = hasValue(
    apiStatus.status,
    'api',
    fetchPaymentInformation
  );
  const information =
    hasApiStatus && hasApiStatus.errorMessage ? (
      <Message type="error" s message={i18n.t(hasApiStatus.errorMessage)} />
    ) : (
      i18n.t('common.tableEmptyMessage')
    );
  const columns = [
    {
      id: 'paymentId',
      numeric: true,
      disablePadding: false,
      label: i18n.t('registration.subscriptionTier'),
      needSorting: true
    },
    {
      id: 'createdDate',
      numeric: false,
      disablePadding: false,
      label: i18n.t('paymentInformation.createdDate'),
      needSorting: true
    },
    {
      id: 'paymentDate',
      numeric: false,
      disablePadding: false,
      label: i18n.t('paymentInformation.paidDate'),
      needSorting: true
    },
    {
      id: 'description',
      numeric: false,
      disablePadding: false,
      label: i18n.t('Description'),
      needSorting: true
    },

    {
      id: 'truckCount',
      numeric: true,
      disablePadding: false,
      label: i18n.t('paymentInformation.unitCount'),
      needSorting: true
    },
    {
      id: 'costPerTruck',
      numeric: true,
      disablePadding: false,
      label: i18n.t('paymentInformation.cost'),
      needSorting: true
    },
    {
      id: 'amount',
      numeric: true,
      disablePadding: false,
      label: i18n.t('common.total'),
      needSorting: true
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: i18n.t('paymentInformation.status'),
      needSorting: true
    },
    {
      id: 'receipt',
      numeric: false,
      disablePadding: false,
      label: i18n.t('paymentInformation.receipt'),
      needSorting: true
    }
  ];

  const generateRows = () => {
    const updatedList = paymentInformation?.paymentHistoryDtos?.map((item) => {
      var result = { ...item };
      result.receipt = item?.receiptId ? (
        <Link
          onClick={() =>
            downloadReceipt({ apiId: 'downloadReceipt', data: item?.receiptId })
          }
        >
          Download
        </Link>
      ) : (
        ''
      );
      return result;
    });
    return updatedList || [];
  };
  return (
    <Styled.Wrapper>
      {topAction && <TopActions {...{ i18n, paymentInformation, Styled }} />}
      <SubHeader
        variant="h2"
        text={`${i18n.t('paymentInformation.companyInfo')} ${
          paymentInformation?.companyName
        }`}
      />
      <div className="paymentInfo">
        <FormStyle.FieldsWrapper className="fieldSection">
          <Label variant="body1">{i18n.t('paymentInformation.company')}</Label>
          <FormStyle.TextWrapper>
            <Input value={paymentInformation?.companyName} disabled />
          </FormStyle.TextWrapper>
        </FormStyle.FieldsWrapper>
        <FormStyle.FieldsWrapper className="fieldSection">
          <Label variant="body1">
            {i18n.t('paymentInformation.nextBilling')}
          </Label>
          <FormStyle.TextWrapper>
            <Input value={paymentInformation?.nextBillingDate} disabled />
          </FormStyle.TextWrapper>
        </FormStyle.FieldsWrapper>
        <FormStyle.FieldsWrapper className="fieldSection">
          <Label variant="body1">
            {i18n.t('paymentInformation.startDate')}
          </Label>
          <FormStyle.TextWrapper>
            <Input value={paymentInformation?.contractStartDate} disabled />
          </FormStyle.TextWrapper>
        </FormStyle.FieldsWrapper>
        <FormStyle.FieldsWrapper className="fieldSection">
          <Label variant="body1">
            {i18n.t('registration.subscriptionTier')}
          </Label>
          <FormStyle.TextWrapper>
            <Input value={paymentInformation?.subscriptionTier} disabled />
          </FormStyle.TextWrapper>
        </FormStyle.FieldsWrapper>
        <FormStyle.FieldsWrapper className="fieldSection">
          <Label variant="body1">
            {i18n.t('paymentInformation.contractLength')}
          </Label>
          <FormStyle.TextWrapper>
            <Input value={paymentInformation?.contractLength} disabled />
          </FormStyle.TextWrapper>
        </FormStyle.FieldsWrapper>
      </div>
      <SubHeader
        variant="h2"
        text={`${i18n.t('paymentInformation.paymentHistory')} ${
          paymentInformation?.companyName
        }`}
      />
      <Styled.TableWrapper>
        <Table
          type="client"
          defaultSortBy={{ sortBy: 'paymentId', order: 'asc' }}
          title=""
          columns={[...columns]}
          data={generateRows()}
          emptyMessage={information}
          itemsPerPage={5}
          i18n={i18n}
          actions={[]}
        />
      </Styled.TableWrapper>

      <iframe id="downloadDocument" style={{ display: 'none' }}></iframe>
    </Styled.Wrapper>
  );
};

Component.propTypes = {
  history: PropTypes.object.isRequired,
  apiStatus: PropTypes.object.isRequired,
  callback: PropTypes.func.isRequired
};
