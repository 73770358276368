import React, {
  useEffect,
  useState,
  lazy,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";

var dropin = require('braintree-web-drop-in');
const layout = window?.$environment?.CURRENT_LAYOUT;
const TextInput = lazy(() => import(`../../UI/Input/${layout}`));
const Button = lazy(() => import(`../../UI/Button/${layout}`));
const Label = lazy(() => import(`../../UI/Label/${layout}`));
const Message = lazy(() => import(`../../UI/Message/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Payment = forwardRef(
  (
    {
      buttonLabel,
      updateCard,
      validationMessage,
      handleOnChange,
      PaymentWrapper,
      NameWrapper,
      InputWrapper,
      suspended,
      getEmail,
      validationMessageMail,
      hidepaymentButton,
      getCaptcha,
      captchaValidation,
      ...rest
    },
    ref
  ) => {
    const { i18n } = useTranslation();
    const [captcha, setCaptcha] = useState();
    useImperativeHandle(ref, () => ({
      handleSubmit(callback) {
        buttonClickHandler('', callback);
      }
    }));
    useEffect(() => {
      window.location.href.includes('registration') && getCaptcha(captcha)
    }, [captcha])
    useEffect(() => {
      dropin.create(
        {
          authorization: window.$environment.BRAINTREE_KEY,
          selector: '#dropin-container',
          card: {
            overrides: {
              fields: {
                number: {
                  placeholder: 'Card Number'
                },
                expirationDate: {
                  placeholder: 'Expiration Date (MM/YY)'
                },
                cvv: {
                  placeholder: 'CVV'
                }
              }
            }
          }
        },
        function (err, dropinInstance) {
          if (err) {
            console.error(err);
            return;
          } else {
            setDropIn(dropinInstance);
          }
        }
      );
      document.getElementById('errorCardHolderName').style.display = 'none';
      if (document.getElementById('errorCardHolderMail')) {
        document.getElementById('errorCardHolderMail').style.display = 'none';
      }
      if (document.getElementById('errorCardHolderMailRegex')) {
        document.getElementById('errorCardHolderMailRegex').style.display =
          'none';
      }
    }, []);
    const [dropIn, setDropIn] = useState(null);
    const [nameOnCard, setnameOnCard] = useState('');
    const [mailOnCard, setmailOnCard] = useState('');
    const regex = /^([a-zA-Z0-9_\-\+\.]+)@([a-zA-Z0-9_\-\+\.]+)\.([a-zA-Z]{2,5})$/;
    const buttonClickHandler = (e, callback) => {
      window.location.href.includes('registration') ? captcha && dropIn.requestPaymentMethod(function (err, payload) {

        if (err) {
        }
        if (!nameOnCard)
          document.getElementById('errorCardHolderName').style.display =
            'block';
        else
          document.getElementById('errorCardHolderName').style.display = 'none';
        if (suspended || getEmail) {
          if (!mailOnCard)
            document.getElementById('errorCardHolderMail').style.display =
              'block';
          else if (mailOnCard && !regex.test(mailOnCard)) {
            document.getElementById('errorCardHolderMail').style.display =
              'none';
            document.getElementById('errorCardHolderMailRegex').style.display =
              'block';
          } else if (mailOnCard && regex.test(mailOnCard)) {
            document.getElementById('errorCardHolderMail').style.display =
              'none';
            document.getElementById('errorCardHolderMailRegex').style.display =
              'none';
          }
        }
        if (!getEmail && nameOnCard && payload.nonce) {
          var data = {
            stripeToken: payload.nonce,
            cardHolderName: nameOnCard,
            email: mailOnCard
          };
          updateCard(data);
          // window.grecaptcha.reset();
          callback && callback();
        } else if (
          getEmail &&
          nameOnCard &&
          payload.nonce &&
          mailOnCard &&
          regex.test(mailOnCard)
        ) {
          var data = {
            stripeToken: payload.nonce,
            cardHolderName: nameOnCard,
            email: mailOnCard
          };
          updateCard(data);
          // window.grecaptcha.reset();
          callback && callback();
        }
      }) : dropIn.requestPaymentMethod(function (err, payload) {

        if (err) {
        }
        if (!nameOnCard)
          document.getElementById('errorCardHolderName').style.display =
            'block';
        else
          document.getElementById('errorCardHolderName').style.display = 'none';
        if (suspended || getEmail) {
          if (!mailOnCard)
            document.getElementById('errorCardHolderMail').style.display =
              'block';
          else if (mailOnCard && !regex.test(mailOnCard)) {
            document.getElementById('errorCardHolderMail').style.display =
              'none';
            document.getElementById('errorCardHolderMailRegex').style.display =
              'block';
          } else if (mailOnCard && regex.test(mailOnCard)) {
            document.getElementById('errorCardHolderMail').style.display =
              'none';
            document.getElementById('errorCardHolderMailRegex').style.display =
              'none';
          }
        }
        if (!getEmail && nameOnCard && payload.nonce) {
          var data = {
            stripeToken: payload.nonce,
            cardHolderName: nameOnCard,
            email: mailOnCard
          };
          updateCard(data);
          // window.grecaptcha.reset();
          callback && callback();
        } else if (
          getEmail &&
          nameOnCard &&
          payload.nonce &&
          mailOnCard &&
          regex.test(mailOnCard)
        ) {
          var data = {
            stripeToken: payload.nonce,
            cardHolderName: nameOnCard,
            email: mailOnCard
          };
          updateCard(data);
          // window.grecaptcha.reset();
          callback && callback();
        }
      });
    };
    const captchaChange = (value) => {
      setCaptcha(value);
    }
    const nameChange = (e) => {
      setnameOnCard(e);
      handleOnChange && handleOnChange(e, 'name');
    };

    const emailChange = (e) => {
      setmailOnCard(e);
      handleOnChange && handleOnChange(e, 'mail');
    };
    return (
      <PaymentWrapper hidepaymentButton={hidepaymentButton}>
        {suspended && (
          <SubHeader
            text="PAYMENT DETAILS"
            className="subheaderSection suspended"
            variant="h2"
          />
        )}
        <NameWrapper>
          <Label htmlFor="nameOnCard" className="paymentName">
            {i18n.t('common.paymentName')}
          </Label>
          <InputWrapper>
            <TextInput
              type={'text'}
              style={{ color: 'white' }}
              id="nameOnCard"
              placeholder={i18n.t('common.paymentplaceholder')}
              value={nameOnCard}
              handleOnChange={(e) => nameChange(e)}
            />
            <div id="errorCardHolderName">{validationMessage}</div>
          </InputWrapper>
        </NameWrapper>
        {(suspended || getEmail) && (
          <NameWrapper>
            <Label htmlFor="email">
              {i18n.t('common.email')}
            </Label>
            <InputWrapper>
              <TextInput
                type={'text'}
                style={{ color: 'white' }}
                id="nameOnCard"
                value={mailOnCard}
                handleOnChange={(e) => emailChange(e)}
              />
              <div id="errorCardHolderMail">{validationMessage}</div>
              <div id="errorCardHolderMailRegex">{validationMessageMail}</div>
            </InputWrapper>
          </NameWrapper>
        )}
        <div id="dropin-container"></div>
        { window.location.href.includes('registration') && <> <p className="captcha-error">{captchaValidation}</p>
          <ReCAPTCHA
            sitekey={window.$environment.SITE_KEY}
            onChange={captchaChange}
            grecaptcha={window.grecaptcha}
          /> </>}
        <div className="paymentButton">
          <Button
            id="submit-button"
            label={buttonLabel ? buttonLabel : 'Next Step'}
            onClick={buttonClickHandler}
          ></Button>
        </div>
      </PaymentWrapper>
    );
  }
);

export default Payment;
