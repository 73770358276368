import React, { useEffect, lazy, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RenderComponent from '@/components/modules/DynamicRenderPage';

import ParentStyled from './style';
import { loadStyle, loadPageConfig, getDotNumber, getCompanyId  } from '@/utils/utils';
let Styled = loadStyle(ParentStyled, 'pages/web/Camera');

const config = loadPageConfig();
const Title = lazy(() => import(`@/${config.cameraList[0].name}`));
const Content = lazy(() => import(`@/${config.cameraList[1].name}`));

const Vehicles = (parentProps) => {
  const {
    getFilters,
    history,
    i18n,
    theme,
    getCameraEvents,
    getDataUsage
  } = parentProps;
  const commonParentProps = { history, i18n, Styled, theme };
  const [eventType, setEventType] = useState('')
  const [dataUsageDates, setDataUsageDates] = useState({
    startDate: '',
    endDate: ''
  })
  const companyId = getCompanyId();
  const basicRequest = {
    displayStart: 1,
    displayLength: 10,
  };
  const [request, setRequest] = useState({
    ...basicRequest
  });
  const [dataUsageRequest, setDataUsageRequest] = useState({
    ...basicRequest
  });
  const sortColumnMapping = {
    unitId: "vehicleName",
    work_order_id: "idPub",
    type: "unitType",
    reported_date: "createDate",
    scheduled_date: "beginDate",
    due_date: "endDate",
    workOrderType: "type",
    status: "state",

    vehicle: "vehicleName", 
    camera_serial_no: "cameraSN", 
    event_type: "eventType", 
    event_level: "eventLevel", 
    time: "uploadTime",

    data_usage: "dataUsageInKB", 
    cycle: "cycle"
  }
  const removeEmptyValues = (obj) => {
    const newObj = {};
    for (let key in obj) {
      if (obj[key] !== '' && obj[key] !== null) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }
  const changeRequest = (fields, dataUsageFlg) => {
    if (fields?.sortCol) {
      const type = fields.sortCol
      fields.sortCol = sortColumnMapping[type] ? sortColumnMapping[type] : fields.sortCol
    }
    if(dataUsageFlg) setDataUsageRequest({ ...basicRequest, ...fields });
    else setRequest({ ...basicRequest, ...fields });
  };

  useEffect(() => {
    let page = Math.round((request.displayStart / 10) + 1)
    const data = removeEmptyValues({
      companyId: companyId,
      eventType: eventType,
      pageNumber: page - 1,
      pageSize: request?.displayLength,
      sortCol: request?.sortCol,
      sortDir: request?.sortDir
    })
    getCameraEvents({
      apiId: 'events', data 
    })
  }, [request]);
  useEffect(() => {
    let page = Math.round((dataUsageRequest.displayStart / 10) + 1)
    const data = removeEmptyValues({
      companyId: companyId,
      startCycle: (dataUsageDates?.startDate)?.slice(0, 7),
      endCycle: (dataUsageDates?.endDate)?.slice(0, 7),
      pageNumber: page - 1,
      pageSize: dataUsageRequest?.displayLength,
      sortCol: dataUsageRequest?.sortCol,
      sortDir: dataUsageRequest?.sortDir
    })
    getDataUsage({
      apiId: 'dataUsage', data
    })
  }, [dataUsageRequest]);

  useEffect(() => {
    getFilters({ apiId: 'getFilters' });
    getCameraEvents({
      apiId: 'events', data: {
        companyId: companyId,
        // eventType: '',
        pageNumber: basicRequest?.displayStart - 1,
        pageSize: basicRequest?.displayLength,
      }
    })
    getDataUsage({
      apiId: 'dataUsage', data: {
        companyId: companyId,
        startCycle: '',
        endCycle: '',
        pageNumber: basicRequest?.displayStart - 1, 
        pageSize: basicRequest?.displayLength,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeEventType = (data) => {
    setPagination(true)
    setEventType(data.eventType)
    getCameraEvents({
      apiId: 'events', data: removeEmptyValues({
        companyId: companyId,
        eventType: data.eventType,
        pageNumber: basicRequest?.displayStart - 1,
        pageSize: basicRequest?.displayLength,
      })
    })
  }
  const changeDataUsageFilter = (data) => {
    setPagination(true)
    setDataUsageDates(data)
    const payloadData = removeEmptyValues({
      companyId: companyId,
      eventType: eventType,
      startCycle: (data?.startDate)?.slice(0, 7),
      endCycle: (data?.endDate)?.slice(0, 7),
      pageNumber: basicRequest?.displayStart - 1,
      pageSize: basicRequest?.displayLength,
    })
    getDataUsage({
      apiId: 'dataUsage', data: payloadData
    })
  }

  const [pagination, setPagination] = useState(false);


  return (
    <>
      <RenderComponent
        {...config.cameraList[0]}
        parentProps={{
          ...parentProps,
          pagination,
          setPagination
        }}
        commonParentProps={commonParentProps}
        component={Title}
      />
      <RenderComponent
        {...config.cameraList[1]}
        parentProps={{
          ...parentProps,
          changeEventType,
          changeRequest,
          request,
          changeDataUsageFilter,
          changeDataUsageRequest: (fields) => { changeRequest(fields, true) },
          dataUsageRequest
        }}
        commonParentProps={commonParentProps}
        component={Content}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

const mapState = (state) => {
  return {
    apiStatus: state.asyncRequests,
    filters: state.filters,
    eventsData: state.camera.events,
    dataUsageList: state.camera.dataUsageList
  };
};
const mapDispatch = (dispatch) => {
  return {
    getFilters: (data) => dispatch.filters.fetchData(data),
    getCameraEvents: (data) => dispatch.camera.fetchData(data),
    getDataUsage: (data) => dispatch.camera.fetchDataUsage(data),
    fetchVideoUrl: (data) => dispatch.camera.fetchVideoUrl(data),
  };
};

export default connect(mapState, mapDispatch)(Vehicles);

Vehicles.propTypes = {
  callback: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  getDataUsage: PropTypes.func.isRequired,
  getFilters: PropTypes.func.isRequired,
  getCameraEvents: PropTypes.func.isRequired,
  fetchVideoUrl: PropTypes.func.isRequired,
};
