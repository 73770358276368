import styled from 'styled-components';
const Wrapper = styled.div`
  flex: 0 0 75%;
  cursor: pointer;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  height: 20px;
  margin-left: 1%;
  margin-right: 1%;
`;
const Graph = styled.div`
  ${({ percentage }) => `
@keyframes progressBar {
  0% { width: 0; }
  100% { width: ${percentage}; }
}
  background-color: #007ac7;
  opacity: 1;
  border-radius: 15px;
  height: inherit;
  width: ${percentage};
  // animation: progressBar 5s ease-in-out;
  // animation-fill-mode: both;
`}
`;

const Styled = {
  Wrapper,
  Graph
};
export default Styled;
