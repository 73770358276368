import React, { useState, useEffect, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import ReactDatePicker from 'react-datepicker';
import { string, array } from 'yup';
import moment from 'moment';
import Policies from './Policies';
import underscore from 'underscore';

import {
  UI_DATE_LOWER_FORMAT,
  UI_DATE_FORMAT
} from '@/config/constants/static';
import {
  phoneNumberValidation,
  hasEnabledFeature,
  hasRestrictedRole,
  isdvirOnly, getDateLocale, getFilterLocaleValues, isdvirOnlyUser
} from '@/utils/utils';
import { emailRegExp, phoneRegExp } from '@/utils/constants';
import { getCookie } from '@/services/cookie';
import { COOKIE_AUTH_DETAILS, imageUrls, ROLES } from '@/config/constants/keys';
// import Styled from './style';

const layout = window?.$environment?.CURRENT_LAYOUT;
const serviceProvider = window?.$environment?.SERVICE_PROVIDER;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const CheckBox = lazy(() => import(`@/components/UI/Toggle/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const Department = lazy(() => import(`@/components/modules/Department`));
const UploadList = lazy(() =>
  import(`@/components/modules/DocumentUploadList`)
);
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`);
const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps,
  submitAction,
  cancelAction
}) => {
  const token = getCookie(COOKIE_AUTH_DETAILS);
  const { i18n } = useTranslation();
  const {
    type,
    allCycleRules,
    allCycleExceptions,
    allVehicleTypes,
    terminals,
    notificationList,
    departments,
    subDepartments,
    disabledCriteria,
    regions,
    Styled,
    insuracePortalUserRole,
    onFleetChange,
    fleetVehicleList,
    user
  } = customProps;

  const isDVIRFlagEnabled = isdvirOnly()
  const isDVIRUserFlagEnabled = isdvirOnlyUser()
  const isWebfleet = window?.$environment?.SERVICE_PROVIDER === 'webfleet';
  const RTFMuser = window?.$environment?.SERVICE_PROVIDER === 'riskTheory' && hasRestrictedRole(['FLMANAGER'])
  const vehicleNames = values?.idsPubVehiclesOfMechanic ? values.idsPubVehiclesOfMechanic.map(o => o.label) : []
  useEffect(() => {
    if (values?.documentsList) {
      let driverDocs = [];
      let mechanicDocs = [];
      let commonDocs = [];
      for (let i = 0; i < values.documentsList.length; i++) {
        const item = values.documentsList[i];
        driverDocs.push(item);
        if (
          item.doctype === 'COMMON' ||
          item.doctype === 'OTHER' ||
          item.doctype === 'POLICY'
        ) {
          commonDocs.push(item);
        } else if (
          item.doctype !== 'DRIVER' &&
          item.doctype.includes('MECHANIC') > -1
        ) {
          mechanicDocs.push(item);
        }
      }
      if (values.driverRole) {
        setUploadList({
          driverDocs,
          mechanicDocs,
          commonDocs,
          currentDocs: [...driverDocs]
        });
      } else if (!values.driverRole && values.mechanicRole) {
        setUploadList({
          driverDocs,
          mechanicDocs,
          commonDocs,
          currentDocs: [
            ...commonDocs.slice(0, 1),
            ...mechanicDocs,
            ...commonDocs.slice(1)
          ]
        });
      } else {
        setUploadList({
          driverDocs,
          mechanicDocs,
          commonDocs,
          currentDocs: [...commonDocs]
        });
      }
      const noDocuments = uploadList.currentDocs.some(o => o.id)
      setIsDocumentAvailable(noDocuments)
    }
  }, [values.driverRole, values.mechanicRole, values.documentsList]);

  useEffect(() => {
    if (
      !values.fleetManagerRole &&
      !values.driverRole &&
      !values.mechanicRole &&
      !values.adminRole &&
      !values.serviceManagerRole &&
      !values.underWriterRole &&
      !values.lossControlRole &&
      !values.claimAdjusterRole
    ) {
      customProps.setInitialValues({
        ...values,
        roles: ''
      });
    } else {
      customProps.setInitialValues({
        ...values,
        roles: 'event'
      });
    }
  }, [
    values.fleetManagerRole,
    values.driverRole,
    values.mechanicRole,
    values.adminRole,
    values.serviceManagerRole,
    values.underWriterRole,
    values.lossControlRole,
    values.claimAdjusterRole,

  ]);
  useEffect(() => {
    if (values.regionId)
      customProps.setInitialValues({
        ...values,
        regionId: values.regionId
      });
    else if (values.regionId === null)
      customProps.setInitialValues({
        ...values,
        regionId: ''
      });

    if (values.terminalId)
      customProps.setInitialValues({
        ...values,
        terminalId: values.terminalId
      });
    else if (values.terminalId === null)
      customProps.setInitialValues({
        ...values,
        terminalId: ''
      });

    if (values.departmentId)
      customProps.setInitialValues({
        ...values,
        departmentId: values.departmentId
      });
    else if (values.departmentId === null)
      customProps.setInitialValues({
        ...values,
        departmentId: ''
      });

    if (values.subDepartmentId)
      customProps.setInitialValues({
        ...values,
        subDepartmentId: values.subDepartmentId
      });
    else if (values.subDepartmentId === null)
      customProps.setInitialValues({
        ...values,
        subDepartmentId: ''
      });
  }, [
    values.regionId,
    values.terminalId,
    values.departmentId,
    values.subDepartmentId
  ]);
  useEffect(() => {
    if (uploadList?.currentDocs?.length) {
      if (values.driverRole) {
        customProps.setValidationlist({
          ...commonFields,
          ...driverFields
        });
        setUploadList({
          ...uploadList,
          currentDocs: [...uploadList.driverDocs]
        });
      } else if (!values.driverRole && values.mechanicRole) {
        setUploadList({
          ...uploadList,
          currentDocs: [
            ...uploadList.commonDocs.slice(0, 1),
            ...uploadList.mechanicDocs,
            ...uploadList.commonDocs.slice(1)
          ]
        });
        customProps.setValidationlist({
          ...commonFields
        });
      } else if (!values.driverRole && !values.mechanicRole) {
        setUploadList({
          ...uploadList,
          currentDocs: [...uploadList.commonDocs]
        });
        customProps.setValidationlist({
          ...commonFields
        });
      }
    }
  }, [values.driverRole, values.mechanicRole]);

  useEffect(() => {
    const noDocuments = uploadList.currentDocs.some(o => o.id)
    setIsDocumentAvailable(noDocuments)
  }, [uploadList])
  useEffect(() => {
    if (!values.fleetManagerRole) {
      let btn = document.getElementById('checkbox_Fleet Manager (Default)');
      btn && btn.parentNode.classList.remove('checked');
      btn && btn.parentNode.classList.add('unchecked');
      handleEdit(false, {
        field: 'fleetManagerRoleDefault',
        type: 'checkbox'
      });
    } else if (values.fleetManagerRole && !values.fleetManagerRoleDefault) {
      let btn = document.getElementById('checkbox_Fleet Manager (Default)');
      btn && btn.parentNode.classList.remove('checked');
      btn && btn.parentNode.classList.add('unchecked');
    }
  }, [values.fleetManagerRole]);

  const [uploadList, setUploadList] = useState({ currentDocs: [] });
  const [isDocumentAvailable, setIsDocumentAvailable] = useState(true);
  const driverFields = customProps.viewOnly || isWebfleet ? {} : {
    licenseNumber: string().required(i18n.t('common.fieldRequiredMessage'))
      .matches(/^[a-zA-Z0-9]*$/, i18n.t('common.validLicenseNumber')),
    licenseState: string().required(i18n.t('common.fieldRequiredMessage')),
    empId:
      type === 'add'
        ? string().required(i18n.t('common.fieldRequiredMessage'))
        : ''
  };
  const annotationEld = {
    annotationEld: string().required(i18n.t('common.fieldRequiredMessage'))
  };

  const notificationListValiation = {
    notificationId: array().required(i18n.t('common.fieldRequiredMessage'))
  }

  useEffect(() => {
    if (values.hideNotification) {
      customProps.setValidationlist({
        ...customProps.validationlist,
        ...notificationListValiation
      });
    } else {
      customProps.setValidationlist({
        ...commonFields
      });
    }
  }, [values.hideNotification])

  const commonFields = customProps.viewOnly || isWebfleet ? {}
    : {
      firstName: string()
        .test(
          'len',
          i18n.t('common.atleast2Chars'),
          (val) => val && val.length > 1
        )
        .required(i18n.t('common.fieldRequiredMessage')),
      lastName: string()
        .test(
          'len',
          i18n.t('common.atleast2Chars'),
          (val) => val && val.length > 1
        )
        .required(i18n.t('common.fieldRequiredMessage')),
      phone: string()
        .matches(phoneRegExp, i18n.t('common.validNumFormat'))
        .required(i18n.t('common.fieldRequiredMessage')),
      company: values.driverRole && insuracePortalUserRole ? string().required('common.fieldRequiredMessage') : string(),
      email: values.driverRole && window?.$environment?.SERVICE_PROVIDER === 'edvir' ? string().matches(emailRegExp, i18n.t('common.emailValidationMessage')) : string()
        .matches(emailRegExp, i18n.t('common.emailValidationMessage'))
        .required(i18n.t('common.fieldRequiredMessage')),
      hireDate: string().when("email", {
        is: () => window?.$environment?.SERVICE_PROVIDER !== 'webfleet',
        then: string().required('common.fieldRequiredMessage'),
        otherwise: string()
      }),
      roles: string().required(i18n.t('common.fieldRequiredMessage'))
    };
  const changeRole = (currentRole, callback, event) => {
    switch (currentRole.field) {
      case 'driverRole':
        callback(false, { field: 'fleetManagerRole' });
        callback(false, { field: 'adminRole' });
        callback(event, currentRole);
        callback(false, { field: 'fleetManagerRoleDefault' });
        if (!event) {
          callback(false, { field: 'exemptFromEld' });
          customProps.setInitialValues({
            ...values,
            exemptFromEld: false
          });
        }
        break;
      case 'adminRole':
        callback(false, { field: 'driverRole' });
        callback(event, currentRole);

        if (values.mechanicRole)
          setUploadList({
            ...uploadList,
            currentDocs: [
              ...uploadList.commonDocs.slice(0, 1),
              ...uploadList.mechanicDocs,
              ...uploadList.commonDocs.slice(1)
            ]
          });
        else
          setUploadList({
            ...uploadList,
            currentDocs: [...uploadList.commonDocs]
          });

        customProps.setValidationlist({
          ...commonFields
        });
        break;
      case 'fleetManagerRole':
        callback(false, { field: 'driverRole' });
        callback(event, currentRole);
        if (!event) {
          callback(false, { field: 'fleetManagerRoleDefault' });
        }

        if (values.mechanicRole)
          setUploadList({
            ...uploadList,
            currentDocs: [
              ...uploadList.commonDocs.slice(0, 1),
              ...uploadList.mechanicDocs,
              ...uploadList.commonDocs.slice(1)
            ]
          });
        else
          setUploadList({ ...uploadList, currentDocs: uploadList.commonDocs });
        customProps.setValidationlist({
          ...commonFields
        });
        break;
      case 'fleetManagerRoleDefault':
        callback(false, { field: 'driverRole' });
        callback(event, currentRole);
        setUploadList({ ...uploadList, currentDocs: uploadList.commonDocs });
        customProps.setValidationlist({
          ...commonFields
        });
        break;
      case 'mechanicRole':
        callback(event, currentRole);
        break;
      case 'serviceManagerRole':
        callback(event, currentRole);
        break;
      case 'underWriterRole':
        callback(event, currentRole);
        break;
      case 'lossControlRole':
        callback(event, currentRole);
        break;
      case 'claimAdjuster':
        callback(event, currentRole);
        break;
      default:
        break;
    }
  };
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  const checkCanadaCompliance = () => {
    if (type == 'edit' && values.initialUserDriver && hasEnabledFeature('canadaComplianceEnabled')) return true
    else return false
  }

  const checkIsDefaultFM = () => {
    return !hasRestrictedRole(['OWNER']) && values.fleetManagerRoleDefault
  }

  return (
    <>
      <Styled.Wrapper className="vehicleWrap">
        <Styled.LeftWrapper>
          <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
            <SubHeader
              className="subheaderSection spireonDriver notspireonNew userHeading"
              text={i18n.t('common.userInformation')}
              variant="h2"
            />
            <div className="formWrap notspireonNew">
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.firstName')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="firstName"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'firstName' });
                      // setNewValues('firstName', event)
                      customProps.setInitialValues({
                        ...values,
                        firstName: event
                      });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.firstName')}
                    value={values['firstName']}
                    type="text"
                    disabled={customProps.viewOnly || isWebfleet || checkIsDefaultFM() || (RTFMuser && values.driverRole) || false}
                  />
                  {showError(
                    'firstName',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.lastName')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="lastName"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'lastName' });
                      // setNewValues('lastName', event)
                      customProps.setInitialValues({
                        ...values,
                        lastName: event
                      });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.lastName')}
                    value={values['lastName']}
                    type="text"
                    disabled={customProps.viewOnly || isWebfleet || checkIsDefaultFM() || (RTFMuser && values.driverRole) || false}
                  />
                  {showError(
                    'lastName',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.cellPhone')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="cellPhone"
                    handleOnChange={(event) => {
                      handleEdit(event, {
                        field: 'phone'
                      });
                      // setNewValues('phone', event)
                      customProps.setInitialValues({
                        ...values,
                        phone: event
                      });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.cellPhone')}
                    value={values['phone']}
                    updateValue={phoneNumberValidation}
                    type="text"
                    disabled={customProps.viewOnly || isWebfleet || checkIsDefaultFM() || false}
                  />
                  {showError(
                    'phone',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.email')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="email"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'email' });
                      // setNewValues('email', event)
                      customProps.setInitialValues({
                        ...values,
                        email: event
                      });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.email')}
                    value={values['email']}
                    type="text"
                    disabled={customProps.viewOnly || isWebfleet || checkIsDefaultFM() || false}
                  />
                  {showError(
                    'email',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>

              {window?.$environment?.SERVICE_PROVIDER !== 'webfleet' &&
                (<FormStyle.default.FieldsWrapper width="100%">
                  <Label variant="body1">{i18n.t('common.hireDate')}</Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="hireDate"
                      onChange={(event) => {
                        handleEdit(event, { field: 'hireDate', type: 'date' });
                        // setNewValues('hireDate', event)
                        customProps.setInitialValues({
                          ...values,
                          hireDate: moment(event).format(UI_DATE_FORMAT)
                        });
                      }}
                      locale={getDateLocale()}
                      as={ReactDatePicker}
                      placeholderText={UI_DATE_LOWER_FORMAT}
                      value={values['hireDate']}
                      type="date"
                      showPopperArrow={false}
                      showTimeSelect={false}
                      showDisabledMonthNavigation
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      isClearable={true}
                      disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                    // selected={values['hireDate']}
                    />
                    {showError(
                      'hireDate',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>)}
              {values.driverRole ? (
                <>
                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="licenceNumber"
                  >
                    <Label variant="body1">
                      {i18n.t('common.licenceNumber')}
                    </Label>
                    <FormStyle.default.TextWrapper className="userForm">
                      <Field
                        name="licenceNumber"
                        handleOnChange={(event) => {
                          handleEdit(event, { field: 'licenseNumber' });
                          // setNewValues('licenseNumber', event)
                          customProps.setInitialValues({
                            ...values,
                            licenseNumber: event
                          });
                        }}
                        as={Input}
                        placeholderText={i18n.t('common.licenceNumber')}
                        value={values['licenseNumber']}
                        type="text"
                        disabled={customProps.viewOnly || isWebfleet || checkIsDefaultFM() || (RTFMuser && values.driverRole) || false}
                        updateValue={(value) => value.replace(/[^a-zA-Z0-9]/g, '')}
                      />
                      {showError(
                        'licenseNumber',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="stateCode"
                  >
                    <Label variant="body1">{i18n.t('common.state')}</Label>
                    <FormStyle.default.TextWrapper className="userForm">
                      <Field
                        name="licenseState"
                        onChange={(event) => {
                          // setNewValues('licenseState', event)
                          handleEdit(event, { field: 'licenseState' });
                          customProps.setInitialValues({
                            ...values,
                            licenseState: event
                          });
                        }}
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values['licenseState']}
                        suggestions={customProps.states}
                        disabled={customProps.viewOnly || isWebfleet || checkIsDefaultFM() || (RTFMuser && values.driverRole) || false}
                      />
                      {showError(
                        'licenseState',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                </>
              ) : null}
              {customProps.type === 'edit' && (
                <FormStyle.default.FieldsWrapper width="100%">
                  <Label variant="body1">{i18n.t('common.otherContact')}</Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="otherContact"
                      handleOnChange={(event) => {
                        // setNewValues('otherContact', event)
                        handleEdit(event, { field: 'otherContact' });
                        customProps.setInitialValues({
                          ...values,
                          otherContact: event
                        });
                      }}
                      as={Input}
                      placeholderText={i18n.t('common.otherContact')}
                      value={values['otherContact']}
                      type="text"
                      disabled={customProps.viewOnly || isWebfleet || checkIsDefaultFM() || false}
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
              <FormStyle.default.FieldsWrapper
                width="100%"
                className="roleSection"
              >
                <Label variant="body1">{i18n.t('common.role')}</Label>
                <div className="roles">
                  <FormStyle.default.CheckBoxWrapper
                    direction="row"
                    className="roleWrapper"
                  >
                    {hasRestrictedRole(['OWNER']) && !hasRestrictedRole([ROLES.SERVICE_MANAGER]) && !insuracePortalUserRole && (
                      <CheckBox
                        type="NormalGrey"
                        checked={
                          values.fleetManagerRoleDefault &&
                          values.fleetManagerRole
                        }
                        disabled={values.driverRole || !values.fleetManagerRole || isWebfleet || checkCanadaCompliance()}
                        label={i18n.t('common.fleetManagerDefault')}
                        onClick={(event) => {
                          changeRole(
                            {
                              field: 'fleetManagerRoleDefault',
                              type: 'checkbox'
                            },
                            handleEdit,
                            event
                          );
                          customProps.setInitialValues({
                            ...values,
                            fleetManagerRoleDefault: event
                          });
                        }}
                      />
                    )}

                    {!hasRestrictedRole([ROLES.SERVICE_MANAGER]) && !insuracePortalUserRole && (<CheckBox
                      type="NormalGrey"
                      checked={values.fleetManagerRole}
                      disabled={checkIsDefaultFM() ||
                        (hasRestrictedRole(['OWNER', 'FLMANAGER'])
                          ? values.driverRole
                          : true) || isWebfleet || checkCanadaCompliance()
                      }
                      label={i18n.t('common.fleetManager')}
                      onClick={(event) => {
                        changeRole(
                          { field: 'fleetManagerRole', type: 'checkbox' },
                          handleEdit,
                          event
                        );
                        // setNewValues('fleetManagerRole', event)
                        if (event)
                          customProps.setInitialValues({
                            ...values,
                            fleetManagerRole: event
                          });
                        else
                          customProps.setInitialValues({
                            ...values,
                            fleetManagerRole: event,
                            fleetManagerRoleDefault: event
                          });
                      }}
                    />)}

                    {!hasRestrictedRole([ROLES.SERVICE_MANAGER]) && !(window.$environment.SERVICE_PROVIDER == 'riskTheory' && hasRestrictedRole(['FLMANAGER'])) && (
                      <CheckBox
                        type="NormalGrey"
                        checked={values.driverRole}
                        disabled={
                          checkIsDefaultFM() ||
                          values.adminRole ||
                          values.fleetManagerRole ||
                          values.fleetManagerRoleDefault ||
                          isWebfleet ||
                          values.claimAdjusterRole ||
                          values.underWriterRole ||
                          values.lossControlRole ||
                          checkCanadaCompliance()
                        }
                        label={i18n.t('users.drivers')}
                        onClick={(event) => {
                          // handleEdit(event, { field: 'driverRole'});
                          changeRole(
                            { field: 'driverRole', type: 'checkbox' },
                            handleEdit,
                            event
                          );
                          // setNewValues('driverRole', event)
                          customProps.setInitialValues({
                            ...values,
                            driverRole: event
                          });
                        }}
                      />)}
                    {(isDVIRFlagEnabled && hasRestrictedRole([ROLES.SERVICE_MANAGER])) || window.$environment?.SERVICE_PROVIDER == 'simplex' || window.$environment?.SERVICE_PROVIDER == 'cyntrx' || window.$environment?.SERVICE_PROVIDER == 'trackit' || window.$environment.SERVICE_PROVIDER == 'ft' || window.$environment.SERVICE_PROVIDER == 'hcss' || window.$environment.SERVICE_PROVIDER == 'patriot'
                      //  || serviceProvider === 'clutch' 
                      || layout !== 'Clutch' || (RTFMuser) ? (
                      <CheckBox
                        type="NormalGrey"
                        checked={values.mechanicRole}
                        label={i18n.t('common.mechanic')}
                        disabled={checkCanadaCompliance() || checkIsDefaultFM()}
                        onClick={(event) => {
                          changeRole(
                            { field: 'mechanicRole', type: 'checkbox' },
                            handleEdit,
                            event
                          );
                          // setNewValues('mechanicRole', event)
                          customProps.setInitialValues({
                            ...values,
                            mechanicRole: event
                          });
                        }}
                        className="CheckboxNeeded"
                      />
                    ) : (
                      ''
                    )}
                    {!hasRestrictedRole([ROLES.SERVICE_MANAGER]) && !insuracePortalUserRole && (
                      <CheckBox
                        type="NormalGrey"
                        checked={values.adminRole}
                        disabled={checkIsDefaultFM() || values.driverRole || isWebfleet || checkCanadaCompliance()}
                        label={i18n.t('common.admin')}
                        onClick={(event) => {
                          changeRole(
                            { field: 'adminRole', type: 'checkbox' },
                            handleEdit,
                            event
                          );
                          // setNewValues('adminRole', event)
                          customProps.setInitialValues({
                            ...values,
                            adminRole: event
                          });
                        }}
                      />)}
                    {insuracePortalUserRole && (
                      <CheckBox
                        type="NormalGrey"
                        checked={values.underWriterRole}
                        label={i18n.t('common.underWriter')}
                        disabled={values.driverRole}
                        onClick={(event) => {
                          changeRole(
                            { field: 'underWriterRole', type: 'checkbox' },
                            handleEdit,
                            event
                          );
                          customProps.setInitialValues({
                            ...values,
                            underWriterRole: event
                          });
                        }}
                      />
                    )}
                    {insuracePortalUserRole && (
                      <CheckBox
                        type="NormalGrey"
                        checked={values.lossControlRole}
                        disabled={values.driverRole}
                        label={i18n.t('common.lossControl')}
                        onClick={(event) => {
                          changeRole(
                            { field: 'lossControlRole', type: 'checkbox' },
                            handleEdit,
                            event
                          );
                          customProps.setInitialValues({
                            ...values,
                            lossControlRole: event
                          });
                        }}
                      />
                    )}
                    {insuracePortalUserRole && (
                      <CheckBox
                        type="NormalGrey"
                        disabled={values.driverRole}
                        checked={values.claimAdjusterRole}
                        label={i18n.t('common.claimAdjuster')}
                        onClick={(event) => {
                          changeRole(
                            { field: 'claimAdjusterRole', type: 'checkbox' },
                            handleEdit,
                            event
                          );
                          customProps.setInitialValues({
                            ...values,
                            claimAdjusterRole: event
                          });
                        }}
                      />
                    )}
                    {hasRestrictedRole([ROLES.SERVICE_MANAGER]) && (
                      <CheckBox
                        type="NormalGrey"
                        checked={values.serviceManagerRole}
                        label={i18n.t('common.serviceManager')}
                        disabled={checkIsDefaultFM()}
                        onClick={(event) => {
                          changeRole(
                            { field: 'serviceManagerRole', type: 'checkbox' },
                            handleEdit,
                            event
                          );
                          // setNewValues('adminRole', event)
                          customProps.setInitialValues({
                            ...values,
                            serviceManagerRole: event
                          });
                        }}
                      />)}
                  </FormStyle.default.CheckBoxWrapper>
                  {showError(
                    'roles',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </div>
              </FormStyle.default.FieldsWrapper>
              {insuracePortalUserRole && values.driverRole ?
                <FormStyle.default.FieldsWrapper
                  width="100%"
                >
                  <Label variant="body1">{i18n.t('policyHolder')}</Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="company"
                      onChange={(event) => {
                        handleEdit(event, { field: 'company' });
                        customProps.setInitialValues({
                          ...values,
                          company: event
                        });
                        onFleetChange(event.value)
                      }}
                      as={Select}
                      disabled={type === 'edit'}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={values['company']}
                      suggestions={customProps.companies}
                    />
                    {showError(
                      'company',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
                : null
              }

              {customProps.type === 'edit' && (
                <FormStyle.default.FieldsWrapper width="100%">
                  <Label variant="body1">{i18n.t('common.timzone')}</Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="timzoneForDailylog"
                      handleOnChange={(event) => {
                        // setNewValues('timzoneForDailylog', event)
                        handleEdit(event, { field: 'timzoneForDailylog' });
                        customProps.setInitialValues({
                          ...values,
                          timzoneForDailylog: event
                        });
                      }}
                      as={Input}
                      placeholderText={i18n.t('common.timezone')}
                      value={values['timzoneForDailylog']}
                      type="text"
                      disabled={customProps.viewOnly || isWebfleet || checkIsDefaultFM() || false}
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
              {customProps.type == 'edit' && values.fleetManagerRole && hasRestrictedRole(['OWNER']) ?
                <div>
                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="roleSection"
                  >
                    <Label variant="body1">{i18n.t('common.hideNotification')}</Label>
                    <div className="roles">
                      <FormStyle.default.CheckBoxWrapper
                        direction="row"
                        className="roleWrapper"
                      >
                        <CheckBox
                          type="NormalGrey"
                          checked={
                            values.hideNotification
                          }
                          disabled={customProps.viewOnly || false}
                          label={''}
                          onClick={(event) => {

                            handleEdit(event, {
                              field: 'hideNotification'
                            });
                            customProps.setInitialValues({
                              ...values,
                              hideNotification: event
                            });
                          }}
                        />
                      </FormStyle.default.CheckBoxWrapper>
                      {showError(
                        'hideNotification',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </div>
                  </FormStyle.default.FieldsWrapper>
                  <FormStyle.default.FieldsWrapper width="100%" className="filterFields">
                    <Label variant="body1" className="filterLabel">
                      {i18n.t('common.notificationList')}
                    </Label>
                    <FormStyle.default.TextWrapper className="filterField">
                      <Field
                        name="notification"
                        onChange={(event) => {
                          handleEdit(event, {
                            field: 'notificationId'
                          });
                          customProps.setInitialValues({
                            ...values,
                            notificationId: event || []
                          });
                        }}
                        as={Select}
                        isDisabled={false}
                        isMulti
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values.notificationId}
                        suggestions={notificationList}
                        isClearable={true}
                        disabled={customProps.viewOnly || !values.hideNotification || false}
                      />
                      {errors?.notificationId && !isNotValid && (
                        <Message type="error" message={errors.notificationId} />
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>

                </div>
                : null}
            </div>
          </div>
          {window?.$environment?.SERVICE_PROVIDER === 'edvir' && hasRestrictedRole(['FLMANAGER']) && values.driverRole &&
            customProps.type === 'edit' ? (
            <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
              <SubHeader
                text={i18n.t('common.userCredentials')}
                className="subheaderSection spireonDriver"
                variant="h2"
              />
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.employeeId')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="empId"
                    handleOnChange={(event) => {
                      // setNewValues('empId', event)
                      handleEdit(event, { field: 'empId' });
                      customProps.setInitialValues({
                        ...values,
                        empId: event
                      });
                    }}
                    as={Input}
                    disabled
                    placeholder={i18n.t('common.employeeId')}
                    value={values['empId']}
                    type="text"
                  />
                  {showError(
                    'empId',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.password')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="password"
                    handleOnChange={(event) => {
                      // setNewValues('empPassword', event)
                      handleEdit(event, { field: 'empPassword' });
                      customProps.setInitialValues({
                        ...values,
                        empPassword: event
                      });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.password')}
                    value={values['empPassword']}
                    type="password"
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
            </div>
          ) : null}
          {(values.mechanicRole || values.driverRole || values.serviceManagerRole) && !customProps?.isAddMechanic &&
            customProps.type === 'add' ? (
            <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
              <SubHeader
                text={i18n.t('common.userCredentials')}
                className="subheaderSection spireonDriver"
                variant="h2"
              />
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.employeeId')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="empId"
                    handleOnChange={(event) => {
                      // setNewValues('empId', event)
                      handleEdit(event, { field: 'empId' });
                      customProps.setInitialValues({
                        ...values,
                        empId: event
                      });
                    }}
                    as={Input}
                    placeholder={i18n.t('common.employeeId')}
                    value={values['empId']}
                    type="text"
                  />
                  {showError(
                    'empId',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%">
                <Label variant="body1">{i18n.t('common.password')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="password"
                    handleOnChange={(event) => {
                      // setNewValues('empPassword', event)
                      handleEdit(event, { field: 'empPassword' });
                      customProps.setInitialValues({
                        ...values,
                        empPassword: event
                      });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.password')}
                    value={values['empPassword']}
                    type="password"
                  />
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
            </div>
          ) : null}
          {values.driverRole &&
            <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : 'notspireonNew'}>
              {values.driverRole ? (
                <>
                  <SubHeader
                    className="subheaderSection spireonDriver spireonVehicle"
                    text={i18n.t('users.vehicleForDriver')}
                    variant="h2"
                  />

                  <FormStyle.default.FieldsWrapper width="100%">
                    <Label variant="body1">{i18n.t('common.vehicleId')}</Label>
                    <FormStyle.default.TextWrapper className="userForm">
                      <Field
                        name="driverVehicleId"
                        onChange={(event) => {
                          handleEdit(event, { field: 'idPubVehicleOfDriver' });
                          // setNewValues('idPubVehicleOfDriver', event)
                          customProps.setInitialValues({
                            ...values,
                            idPubVehicleOfDriver: event
                          });
                        }}
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values.idPubVehicleOfDriver}
                        suggestions={insuracePortalUserRole && type == 'add' ? fleetVehicleList : customProps.vehicleDriverList}
                        isClearable
                        disabled={customProps.viewOnly || isWebfleet || checkIsDefaultFM() || false}
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                  <FormStyle.default.FieldsWrapper width="100%">
                    <FormStyle.default.CheckBoxWrapper
                      direction="column"
                      className="driverCheckbox"
                    >
                      {isDVIRUserFlagEnabled && window.$environment.SERVICE_PROVIDER !== 'edvir' && (
                        <CheckBox
                          type="NormalGrey"
                          checked={values.isDvirOnly}
                          label={i18n.t('users.DVIROnly')}
                          onClick={(event) => {
                            handleEdit(event, { field: 'isDvirOnly' });
                            customProps.setInitialValues({
                              ...values,
                              isDvirOnly: event
                            });
                          }}
                          disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                        />
                      )}

                      {(!isDVIRFlagEnabled && <>
                        <CheckBox
                          type="NormalGrey"
                          checked={values.enableYardMoves}
                          label={i18n.t('users.enableYardMoves')}
                          onClick={(event) => {
                            // setNewValues('enableYardMoves', event)
                            handleEdit(event, { field: 'enableYardMoves' });
                            customProps.setInitialValues({
                              ...values,
                              enableYardMoves: event
                            });
                          }}
                          disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                        />
                        {(hasEnabledFeature('dotTripEnabled') || values.enabledNonDotTripForCompany || user?.featureFlags?.dotTripEnabled) && (
                          <CheckBox
                            type="NormalGrey"
                            checked={values.nonDotTrip}
                            label={i18n.t('users.enableNonDOTTrip')}
                            onClick={(event) => {
                              // setNewValues('nonDotTrip', event)
                              handleEdit(event, { field: 'nonDotTrip' });
                              customProps.setInitialValues({
                                ...values,
                                nonDotTrip: event
                              });
                            }}
                            disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                          />
                        )}
                        <CheckBox
                          type="NormalGrey"
                          checked={values.enablePersonalConveyance}
                          label={i18n.t('users.enablePersonalConveyance')}
                          onClick={(event) => {
                            // setNewValues('enablePersonalConveyance', event)
                            handleEdit(event, {
                              field: 'enablePersonalConveyance'
                            });
                            customProps.setInitialValues({
                              ...values,
                              enablePersonalConveyance: event
                            });
                          }}
                          disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                        />
                        <CheckBox
                          type="NormalGrey"
                          checked={values.agExemption}
                          label={i18n.t('users.aGExemption')}
                          onClick={(event) => {
                            // setNewValues('agExemption', event)
                            handleEdit(event, { field: 'agExemption' });
                            customProps.setInitialValues({
                              ...values,
                              agExemption: event
                            });
                          }}
                          disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                        />
                        <CheckBox
                          type="NormalGrey"
                          checked={values.bigDayShortHaul}
                          label={i18n.t('users.bigDayShortHaul')}
                          onClick={(event) => {
                            // setNewValues('bigDayShortHaul', event)
                            handleEdit(event, { field: 'bigDayShortHaul' });
                            customProps.setInitialValues({
                              ...values,
                              bigDayShortHaul: event
                            });
                          }}
                          disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                        />
                        {window.$environment.SERVICE_PROVIDER !== 'clutch' && (
                          <CheckBox
                            type="NormalGrey"
                            checked={values.exemptFromEld}
                            label={i18n.t('users.exemptFromUsingELD')}
                            onClick={(event) => {
                              event &&
                                customProps.setValidationlist({
                                  ...customProps.validationlist,
                                  ...annotationEld
                                });
                              handleEdit(event, { field: 'exemptFromEld' });
                              // setNewValues('exemptFromEld', event)
                              customProps.setInitialValues({
                                ...values,
                                exemptFromEld: event
                              });
                            }}
                            disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                          />
                        )}
                      </>)}
                    </FormStyle.default.CheckBoxWrapper>
                  </FormStyle.default.FieldsWrapper>
                </>
              ) : null}
              {values.exemptFromEld && (
                <FormStyle.default.FieldsWrapper width="100%">
                  <Label variant="body1">{i18n.t('common.annotation')}</Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="annotationEld"
                      handleOnChange={(event) => {
                        // setNewValues('annotationEld', event)
                        handleEdit(event, { field: 'annotationEld' });
                        customProps.setInitialValues({
                          ...values,
                          annotationEld: event
                        });
                      }}
                      as={Input}
                      placeholderText={i18n.t('common.annotation')}
                      value={values['annotationEld']}
                      type="text"
                      disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                    />
                    {showError(
                      'annotationEld',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
            </div>}
          <div className="formWrap spireonNewonly">
            <div className="userdetailssp">
              <SubHeader
                className="subheaderSection spireonDriver"
                text={i18n.t('common.userInformation')}
                variant="h2"
              />
              <FormStyle.default.FieldsWrapper width="100%" className="spNew">
                <Label variant="body1">{i18n.t('common.firstName')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="firstName"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'firstName' });
                      // setNewValues('firstName', event)
                      customProps.setInitialValues({
                        ...values,
                        firstName: event
                      });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.firstName')}
                    value={values['firstName']}
                    type="text"
                    disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                  />
                  {showError(
                    'firstName',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%" className="spNew">
                <Label variant="body1">{i18n.t('common.lastName')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="lastName"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'lastName' });
                      // setNewValues('lastName', event)
                      customProps.setInitialValues({
                        ...values,
                        lastName: event
                      });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.lastName')}
                    value={values['lastName']}
                    type="text"
                    disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                  />
                  {showError(
                    'lastName',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%" className="spNew">
                <Label variant="body1">{i18n.t('common.cellPhone')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="cellPhone"
                    handleOnChange={(event) => {
                      handleEdit(event, {
                        field: 'phone'
                      });
                      // setNewValues('phone', event)
                      customProps.setInitialValues({
                        ...values,
                        phone: event
                      });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.cellPhone')}
                    value={values['phone']}
                    updateValue={phoneNumberValidation}
                    type="text"
                    disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                  />
                  {showError(
                    'phone',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              <FormStyle.default.FieldsWrapper width="100%" className="spNew">
                <Label variant="body1">{i18n.t('common.email')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="email"
                    handleOnChange={(event) => {
                      handleEdit(event, { field: 'email' });
                      // setNewValues('email', event)
                      customProps.setInitialValues({
                        ...values,
                        email: event
                      });
                    }}
                    as={Input}
                    placeholderText={i18n.t('common.email')}
                    value={values['email']}
                    type="text"
                    disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                  />
                  {showError(
                    'email',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>

              <FormStyle.default.FieldsWrapper width="100%" className="spNew">
                <Label variant="body1">{i18n.t('common.hireDate')}</Label>
                <FormStyle.default.TextWrapper className="userForm">
                  <Field
                    name="hireDate"
                    onChange={(event) => {
                      handleEdit(event, { field: 'hireDate', type: 'date' });
                      // setNewValues('hireDate', event)
                      customProps.setInitialValues({
                        ...values,
                        hireDate: moment(event).format(UI_DATE_FORMAT)
                      });
                    }}
                    locale={getDateLocale()}
                    as={ReactDatePicker}
                    placeholderText={UI_DATE_LOWER_FORMAT}
                    value={values['hireDate']}
                    type="date"
                    showPopperArrow={false}
                    showTimeSelect={false}
                    showDisabledMonthNavigation
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isClearable={true}
                    // selected={values['hireDate']}
                    disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                  />
                  {showError(
                    'hireDate',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </FormStyle.default.TextWrapper>
              </FormStyle.default.FieldsWrapper>
              {values.driverRole ? (
                <>
                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="licenceNumber spNew"
                  >
                    <Label variant="body1">
                      {i18n.t('common.licenceNumber')}
                    </Label>
                    <FormStyle.default.TextWrapper className="userForm">
                      <Field
                        name="licenceNumber"
                        handleOnChange={(event) => {
                          handleEdit(event, { field: 'licenseNumber' });
                          // setNewValues('licenseNumber', event)
                          customProps.setInitialValues({
                            ...values,
                            licenseNumber: event
                          });
                        }}
                        as={Input}
                        placeholderText={i18n.t('common.licenceNumber')}
                        value={values['licenseNumber']}
                        type="text"
                        disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                      />
                      {showError(
                        'licenseNumber',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="stateCode spNew"
                  >
                    <Label variant="body1">{i18n.t('common.state')}</Label>
                    <FormStyle.default.TextWrapper className="userForm">
                      <Field
                        name="licenseState"
                        onChange={(event) => {
                          // setNewValues('licenseState', event)
                          handleEdit(event, { field: 'licenseState' });
                          customProps.setInitialValues({
                            ...values,
                            licenseState: event
                          });
                        }}
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values['licenseState']}
                        suggestions={customProps.states}
                        disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                      />
                      {showError(
                        'licenseState',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                </>
              ) : null}
              {customProps.type === 'edit' && (
                <FormStyle.default.FieldsWrapper width="100%" className="spNew">
                  <Label variant="body1">{i18n.t('common.otherContact')}</Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="otherContact"
                      handleOnChange={(event) => {
                        // setNewValues('otherContact', event)
                        handleEdit(event, { field: 'otherContact' });
                        customProps.setInitialValues({
                          ...values,
                          otherContact: event
                        });
                      }}
                      as={Input}
                      placeholderText={i18n.t('common.otherContact')}
                      value={values['otherContact']}
                      type="text"
                      disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
              <FormStyle.default.FieldsWrapper
                width="100%"
                className="roleSection"
              >
                <Label variant="body1">{i18n.t('common.role')}</Label>
                <div className="roles">
                  <FormStyle.default.CheckBoxWrapper
                    direction="row"
                    className="roleWrapper"
                  >
                    {hasRestrictedRole(['OWNER']) && (
                      <CheckBox
                        type="NormalGrey"
                        checked={
                          values.fleetManagerRoleDefault &&
                          values.fleetManagerRole
                        }
                        disabled={values.driverRole || !values.fleetManagerRole || checkIsDefaultFM()}
                        label={i18n.t('common.fleetManagerDefault')}
                        onClick={(event) => {
                          changeRole(
                            {
                              field: 'fleetManagerRoleDefault',
                              type: 'checkbox'
                            },
                            handleEdit,
                            event
                          );
                          customProps.setInitialValues({
                            ...values,
                            fleetManagerRoleDefault: event
                          });
                        }}
                      />
                    )}

                    <CheckBox
                      type="NormalGrey"
                      checked={values.fleetManagerRole}
                      disabled={checkIsDefaultFM() ||
                        hasRestrictedRole(['OWNER', 'FLMANAGER'])
                        ? values.driverRole
                        : true
                      }
                      label={i18n.t('common.fleetManager')}
                      onClick={(event) => {
                        changeRole(
                          { field: 'fleetManagerRole', type: 'checkbox' },
                          handleEdit,
                          event
                        );
                        // setNewValues('fleetManagerRole', event)
                        if (event)
                          customProps.setInitialValues({
                            ...values,
                            fleetManagerRole: event
                          });
                        else
                          customProps.setInitialValues({
                            ...values,
                            fleetManagerRole: event,
                            fleetManagerRoleDefault: event
                          });
                      }}
                    />

                    <CheckBox
                      type="NormalGrey"
                      checked={values.driverRole}
                      disabled={
                        checkIsDefaultFM() ||
                        values.adminRole ||
                        values.fleetManagerRole ||
                        values.fleetManagerRoleDefault
                      }
                      label={i18n.t('common.drivers')}
                      onClick={(event) => {
                        // handleEdit(event, { field: 'driverRole'});
                        changeRole(
                          { field: 'driverRole', type: 'checkbox' },
                          handleEdit,
                          event
                        );
                        // setNewValues('driverRole', event)
                        customProps.setInitialValues({
                          ...values,
                          driverRole: event
                        });
                      }}
                    />
                    {!isDVIRFlagEnabled || layout !== 'Clutch' || hasRestrictedRole([ROLES.SERVICE_MANAGER]) ? (
                      <CheckBox
                        type="NormalGrey"
                        checked={values.mechanicRole}
                        disabled={!hasRestrictedRole(['OWNER']) && values.fleetManagerRoleDefault}
                        label={i18n.t('common.mechanic')}
                        onClick={(event) => {
                          changeRole(
                            { field: 'mechanicRole', type: 'checkbox' },
                            handleEdit,
                            event
                          );
                          // setNewValues('mechanicRole', event)
                          customProps.setInitialValues({
                            ...values,
                            mechanicRole: event
                          });
                        }}
                        className="CheckboxNeeded"
                      />
                    ) : (
                      ''
                    )}

                    <CheckBox
                      type="NormalGrey"
                      checked={values.adminRole}
                      disabled={checkIsDefaultFM() || values.driverRole}
                      label={i18n.t('common.admin')}
                      onClick={(event) => {
                        changeRole(
                          { field: 'adminRole', type: 'checkbox' },
                          handleEdit,
                          event
                        );
                        // setNewValues('adminRole', event)
                        customProps.setInitialValues({
                          ...values,
                          adminRole: event
                        });
                      }}
                    />
                  </FormStyle.default.CheckBoxWrapper>
                  {showError(
                    'roles',
                    errors,
                    isNotValid,
                    customProps.hasApiStatus?.errorMessage
                  )}
                </div>
              </FormStyle.default.FieldsWrapper>

              {customProps.type === 'edit' && (
                <FormStyle.default.FieldsWrapper width="100%" className="spNew">
                  <Label variant="body1">{i18n.t('common.timzone')}</Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="timzoneForDailylog"
                      handleOnChange={(event) => {
                        // setNewValues('timzoneForDailylog', event)
                        handleEdit(event, { field: 'timzoneForDailylog' });
                        customProps.setInitialValues({
                          ...values,
                          timzoneForDailylog: event
                        });
                      }}
                      as={Input}
                      disabled
                      placeholderText={i18n.t('common.timezone')}
                      value={values['timzoneForDailylog']}
                      type="text"
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
              {(values.mechanicRole || values.driverRole || values.serviceManagerRole) && !customProps?.isAddMechanic &&
                customProps.type === 'add' ? (
                <div>
                  <SubHeader
                    text={i18n.t('common.userCredentials')}
                    className="subheaderSection spireonDriver"
                    variant="h2"
                  />
                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="spNew"
                  >
                    <Label variant="body1">{i18n.t('common.employeeId')}</Label>
                    <FormStyle.default.TextWrapper className="userForm">
                      <Field
                        name="empId"
                        handleOnChange={(event) => {
                          // setNewValues('empId', event)
                          handleEdit(event, { field: 'empId' });
                          customProps.setInitialValues({
                            ...values,
                            empId: event
                          });
                        }}
                        as={Input}
                        placeholder={i18n.t('common.employeeId')}
                        value={values['empId']}
                        type="text"
                      />
                      {showError(
                        'empId',
                        errors,
                        isNotValid,
                        customProps.hasApiStatus?.errorMessage
                      )}
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="spNew"
                  >
                    <Label variant="body1">{i18n.t('common.password')}</Label>
                    <FormStyle.default.TextWrapper className="userForm">
                      <Field
                        name="password"
                        handleOnChange={(event) => {
                          // setNewValues('empPassword', event)
                          handleEdit(event, { field: 'empPassword' });
                          customProps.setInitialValues({
                            ...values,
                            empPassword: event
                          });
                        }}
                        as={Input}
                        placeholderText={i18n.t('common.password')}
                        value={values['empPassword']}
                        type="password"
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                </div>
              ) : null}
              {values.mechanicRole ? (

                <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
                  <SubHeader
                    className="subheaderSection spireonDriver notspireonNew"
                    text={i18n.t('users.vehicleForMechanic')}
                    variant="h2"
                  />
                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="spNew notspireonNew"
                  >
                    <Label variant="body1">{i18n.t('common.vehicleId')}</Label>
                    <FormStyle.default.TextWrapper className="userForm">
                      <Field
                        name="vehicleId"
                        onChange={(event) => {
                          // setNewValues('idsPubVehiclesOfMechanic', event)
                          handleEdit(event, {
                            field: 'idsPubVehiclesOfMechanic'
                          });
                          customProps.setInitialValues({
                            ...values,
                            idsPubVehiclesOfMechanic: event
                          });
                        }}
                        as={Select}
                        isMulti
                        disabled={checkIsDefaultFM()}
                        isClearable
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values.idsPubVehiclesOfMechanic}
                        suggestions={customProps.vehicleMechanicList}
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                </div>

              ) : null}
            </div>
            <div className="vehicleForsp">
              {values.driverRole ? (
                <>
                  <SubHeader
                    className="subheaderSection spireonDriver spireonVehicle"
                    text={i18n.t('users.vehicleForDriver')}
                    variant="h2"
                  />

                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="spNew"
                  >
                    <Label variant="body1">{i18n.t('common.vehicleId')}</Label>
                    <FormStyle.default.TextWrapper className="userForm">
                      <Field
                        name="driverVehicleId"
                        onChange={(event) => {
                          handleEdit(event, { field: 'idPubVehicleOfDriver' });
                          // setNewValues('idPubVehicleOfDriver', event)
                          customProps.setInitialValues({
                            ...values,
                            idPubVehicleOfDriver: event
                          });
                        }}
                        as={Select}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values.idPubVehicleOfDriver}
                        suggestions={customProps.vehicleDriverList}
                        isClearable
                        disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                  {customProps.type === 'edit' && !isDVIRFlagEnabled && (
                    <FormStyle.default.FieldsWrapper
                      width="100%"
                      className="spNew"
                    >
                      <Label variant="body1">
                        {i18n.t('common.deviceType')}
                      </Label>
                      <FormStyle.default.TextWrapper className="userForm">
                        <Field
                          name="deviceType"
                          handleOnChange={(event) => {
                            // setNewValues('deviceType', event)
                            handleEdit(event, { field: 'deviceType' });
                            customProps.setInitialValues({
                              ...values,
                              deviceType: event
                            });
                          }}
                          as={Input}
                          disabled
                          placeholderText={i18n.t('common.deviceType')}
                          value={values['deviceType']}
                          type="text"
                        />
                      </FormStyle.default.TextWrapper>
                    </FormStyle.default.FieldsWrapper>
                  )}
                   {isDVIRUserFlagEnabled && window.$environment.SERVICE_PROVIDER !== 'edvir' && (
                        <CheckBox
                          type="NormalGrey"
                          checked={values.isDvirOnly}
                          label={i18n.t('users.DVIROnly')}
                          onClick={(event) => {
                            handleEdit(event, { field: 'isDvirOnly' });
                            customProps.setInitialValues({
                              ...values,
                              isDvirOnly: event
                            });
                          }}
                          disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                        />
                    )}
                  {!isDVIRFlagEnabled &&
                    (<FormStyle.default.FieldsWrapper
                      width="100%"
                      className="spNew"
                    >
                      <FormStyle.default.CheckBoxWrapper
                        direction="column"
                        className="driverCheckbox"
                      >
                        <CheckBox
                          type="NormalGrey"
                          checked={values.enableYardMoves}
                          label={i18n.t('users.enableYardMoves')}
                          onClick={(event) => {
                            // setNewValues('enableYardMoves', event)
                            handleEdit(event, { field: 'enableYardMoves' });
                            customProps.setInitialValues({
                              ...values,
                              enableYardMoves: event
                            });
                          }}
                          disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                        />
                        {(hasEnabledFeature('dotTripEnabled') || values.enabledNonDotTripForCompany) && (
                          <CheckBox
                            type="NormalGrey"
                            checked={values.nonDotTrip}
                            label={i18n.t('users.enableNonDOTTrip')}
                            onClick={(event) => {
                              // setNewValues('nonDotTrip', event)
                              handleEdit(event, { field: 'nonDotTrip' });
                              customProps.setInitialValues({
                                ...values,
                                nonDotTrip: event
                              });
                            }}
                            disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                          />
                        )}
                        <CheckBox
                          type="NormalGrey"
                          checked={values.enablePersonalConveyance}
                          label={i18n.t('users.enablePersonalConveyance')}
                          onClick={(event) => {
                            // setNewValues('enablePersonalConveyance', event)
                            handleEdit(event, {
                              field: 'enablePersonalConveyance'
                            });
                            customProps.setInitialValues({
                              ...values,
                              enablePersonalConveyance: event
                            });
                          }}
                          disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                        />
                        <CheckBox
                          type="NormalGrey"
                          checked={values.agExemption}
                          label={i18n.t('users.aGExemption')}
                          onClick={(event) => {
                            // setNewValues('agExemption', event)
                            handleEdit(event, { field: 'agExemption' });
                            customProps.setInitialValues({
                              ...values,
                              agExemption: event
                            });
                          }}
                          disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                        />
                        <CheckBox
                          type="NormalGrey"
                          checked={values.bigDayShortHaul}
                          label={i18n.t('users.bigDayShortHaul')}
                          onClick={(event) => {
                            // setNewValues('bigDayShortHaul', event)
                            handleEdit(event, { field: 'bigDayShortHaul' });
                            customProps.setInitialValues({
                              ...values,
                              bigDayShortHaul: event
                            });
                          }}
                          disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                        />
                        {window.$environment.SERVICE_PROVIDER !== 'clutch' && (
                          <CheckBox
                            type="NormalGrey"
                            checked={values.exemptFromEld}
                            label={i18n.t('users.exemptFromUsingELD')}
                            onClick={(event) => {
                              event &&
                                customProps.setValidationlist({
                                  ...customProps.validationlist,
                                  ...annotationEld
                                });
                              handleEdit(event, { field: 'exemptFromEld' });
                              // setNewValues('exemptFromEld', event)
                              customProps.setInitialValues({
                                ...values,
                                exemptFromEld: event
                              });
                            }}
                            disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                          />
                        )}
                      </FormStyle.default.CheckBoxWrapper>
                    </FormStyle.default.FieldsWrapper>)}
                </>
              ) : null}
              {values.exemptFromEld && (
                <FormStyle.default.FieldsWrapper width="100%" className="spNew">
                  <Label variant="body1">{i18n.t('common.annotation')}</Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="annotationEld"
                      handleOnChange={(event) => {
                        // setNewValues('annotationEld', event)
                        handleEdit(event, { field: 'annotationEld' });
                        customProps.setInitialValues({
                          ...values,
                          annotationEld: event
                        });
                      }}
                      as={Input}
                      placeholderText={i18n.t('common.annotation')}
                      value={values['annotationEld']}
                      type="text"
                      disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                    />
                    {showError(
                      'annotationEld',
                      errors,
                      isNotValid,
                      customProps.hasApiStatus?.errorMessage
                    )}
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              )}
              {values.mechanicRole &&
                <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>

                  <SubHeader
                    className="subheaderSection spireonDriver spireonNewonly"
                    text={i18n.t('users.vehicleForMechanic')}
                    variant="h2"
                  />
                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="spNew spireonNewonly"
                  >
                    <Label variant="body1">{i18n.t('common.vehicleId')}</Label>
                    {customProps?.viewOnly ?
                      <span className='view-only-label'>
                        {vehicleNames.join(', ')}
                      </span> :
                      <FormStyle.default.TextWrapper className="userForm">
                        <Field
                          name="vehicleId"
                          onChange={(event) => {
                            // setNewValues('idsPubVehiclesOfMechanic', event)
                            handleEdit(event, {
                              field: 'idsPubVehiclesOfMechanic'
                            });
                            customProps.setInitialValues({
                              ...values,
                              idsPubVehiclesOfMechanic: event
                            });
                          }}
                          as={Select}
                          isMulti
                          disabled={checkIsDefaultFM()}
                          isClearable
                          placeholder={i18n.t('common.pleaseSelect')}
                          value={values.idsPubVehiclesOfMechanic}
                          suggestions={customProps.vehicleMechanicList}
                        />
                      </FormStyle.default.TextWrapper>
                    }
                  </FormStyle.default.FieldsWrapper>
                </div>
              }
              <>
                <SubHeader
                  className="subheaderSection spireonDriver roleSection spireonNew"
                  text={i18n.t('common.departments')}
                  variant="h2"
                />
                <div className="formWrap roleSection spireonNew">
                  <Department
                    values={values}
                    handleEdit={handleEdit}
                    i18n={i18n}
                    fetchTerminals={customProps.fetchTerminals}
                    fetchDepartments={customProps.fetchDepartments}
                    fetchSubDepartments={customProps.fetchSubDepartments}
                    regions={regions}
                    terminals={terminals}
                    departments={departments}
                    subDepartments={subDepartments}
                    disabledCriteria={disabledCriteria}
                    viewOnly={customProps?.viewOnly || checkIsDefaultFM()}
                  />
                </div>
              </>
            </div>
          </div>
          {!isDVIRFlagEnabled &&
            window?.$environment?.SERVICE_PROVIDER !== 'webfleet' &&
            !(insuracePortalUserRole && !values.driverRole) &&
            <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
              <SubHeader
                className="subheaderSection spireonDriver notspireonNew roleSection"
                text={i18n.t('common.departments')}
                variant="h2"
              />
              <div className="formWrap roleSection">
                <Department
                  values={values}
                  handleEdit={handleEdit}
                  i18n={i18n}
                  fetchTerminals={customProps.fetchTerminals}
                  fetchDepartments={customProps.fetchDepartments}
                  fetchSubDepartments={customProps.fetchSubDepartments}
                  regions={regions}
                  terminals={terminals}
                  departments={departments}
                  subDepartments={subDepartments}
                  viewOnly={checkIsDefaultFM()}
                  disabledCriteria={disabledCriteria}
                />
              </div>
            </div>
          }
          {values.mechanicRole && !hasRestrictedRole([ROLES.SERVICE_MANAGER]) && window.$environment.SERVICE_PROVIDER !== 'clutch' ? (
            <>
              <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>

                <SubHeader
                  className="subheaderSection spireonDriver notspireonNew"
                  text={i18n.t('users.vehicleForMechanic')}
                  variant="h2"
                />
                <div className="formWrap notspireonNew">
                  <FormStyle.default.FieldsWrapper
                    width="100%"
                    className="vehicleidMechanic"
                  >
                    <Label variant="body1">{i18n.t('common.vehicleId')}</Label>
                    <FormStyle.default.TextWrapper className="userForm">
                      <Field
                        name="vehicleId"
                        onChange={(event) => {
                          // setNewValues('idsPubVehiclesOfMechanic', event)
                          handleEdit(event, {
                            field: 'idsPubVehiclesOfMechanic'
                          });
                          customProps.setInitialValues({
                            ...values,
                            idsPubVehiclesOfMechanic: event
                          });
                        }}
                        as={Select}
                        isMulti
                        isClearable
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={values.idsPubVehiclesOfMechanic}
                        suggestions={customProps.vehicleMechanicList}
                        disabled={customProps.viewOnly || checkIsDefaultFM() || false}
                      />
                    </FormStyle.default.TextWrapper>
                  </FormStyle.default.FieldsWrapper>
                </div>
              </div>
            </>
          ) : null}

        </Styled.LeftWrapper>
        <Styled.RightWrapper>
          {isDVIRFlagEnabled && !hasRestrictedRole([ROLES.SERVICE_MANAGER]) &&
            (
              <>
                <SubHeader
                  className="subheaderSection spireonDriver notspireonNew roleSection"
                  text={i18n.t('common.departments')}
                  variant="h2"
                />
                <div className="formWrap roleSection">
                  <Department
                    values={values}
                    handleEdit={handleEdit}
                    i18n={i18n}
                    fetchTerminals={customProps.fetchTerminals}
                    fetchDepartments={customProps.fetchDepartments}
                    fetchSubDepartments={customProps.fetchSubDepartments}
                    viewOnly={checkIsDefaultFM()}
                    regions={regions}
                    terminals={terminals}
                    departments={departments}
                    subDepartments={subDepartments}
                    disabledCriteria={disabledCriteria}
                  />
                </div>
              </>)}
          {!isDVIRFlagEnabled && (!customProps.viewOnly || (customProps.viewOnly && isDocumentAvailable)) && window.$environment?.SERVICE_PROVIDER !== 'simplex' &&
            (<div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
              <SubHeader
                className="subheaderSection documentsHeader spireonDriver"
                text={i18n.t('common.documents')}
                variant="h2"
              />
              <Styled.documentWrapper>
                <UploadList
                  userId={values.id}
                  isEdit={type === 'add'}
                  list={uploadList.currentDocs}
                  saveDocument={customProps.saveDocument}
                  deleteDocument={customProps.deleteDocument}
                  apiStatus={customProps.apiStatus}
                  callback={customProps.callback}
                  downloadDocument={customProps.downloadDocument}
                  logBook={customProps.logBook}
                  viewOnly={customProps?.viewOnly || checkIsDefaultFM()}
                />
              </Styled.documentWrapper>
            </div>
            )}
          {customProps.showPolicies && layout !== 'Clutch' && (!customProps.viewOnly || (customProps.viewOnly && customProps.policies.length > 0)) && (
            <Styled.RightWrapper>
              <SubHeader
                className="subheaderSection policyHeader spireonDriver"
                text={i18n.t('common.policiesAndProcedures')}
                variant="h2"
              />
              <Policies
                list={customProps.policies}
                handleDownloadDoc={customProps.handleDownloadDoc}
                Styled={Styled}
              />
            </Styled.RightWrapper>
          )}
          {token?.roles?.includes('FLMANAGER') &&
            token?.enterpriseFeatureDto?.cycleRuleEnabled &&
            values.driverRole && !isDVIRFlagEnabled && (
              <div className={customProps?.newUI || window.$environment.WHITE_THEME ? 'section-wrapper' : ''}>
                <SubHeader
                  text={i18n.t('common.cycleRule')}
                  className="subheaderSection spireonDriver spnocycle"
                  variant="h2"
                />
                <FormStyle.default.FieldsWrapper className="spnocycle">
                  <Label htmlFor="assignedCycleRules" variant="body1">
                    {i18n.t('common.cycleRule')}
                  </Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="assignedCycleRules"
                      id="assignedCycleRules"
                      disabled={checkIsDefaultFM()}
                      onChange={(event) => {
                        if (
                          !event ||
                          !event.length ||
                          (event.length &&
                            !(
                              underscore
                                .pluck(event, 'value')
                                .includes('USA 60h/7 days') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('USA 70h/8 days') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('Texas 70h/7 day')
                            ))
                        ) {
                          handleEdit('', { field: 'assignedCycleExceptions' });
                          customProps.setInitialValues({
                            ...values,
                            assignedCycleExceptions: ''
                          });
                        }
                        if (
                          event &&
                          event.length &&
                          (underscore
                            .pluck(event, 'value')
                            .includes('USA 60h/7 days') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('USA 70h/8 days')) &&
                          (underscore
                            .pluck(event, 'value')
                            .includes('Local Radius') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('Texas 70h/7 day') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('Canada 70h/7 day South') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('Canada 120h/14 day South') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('Canada 70h/7 day North') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('Canada 120h/14 day North') ||
                            underscore.pluck(event, 'value').includes('Mexico'))
                        ) {
                          handleEdit(allVehicleTypes[0], {
                            field: 'assignedVehicleType'
                          });
                          customProps.setInitialValues({
                            ...values,
                            assignedVehicleType: allVehicleTypes[0]
                          });
                        }
                        if (!event || !event.length) {
                          handleEdit(allVehicleTypes[0], {
                            field: 'assignedVehicleType'
                          });
                          customProps.setInitialValues({
                            ...values,
                            assignedVehicleType: allVehicleTypes[0]
                          });
                        }
                        handleEdit(event, { field: 'assignedCycleRules' });
                        handleEdit('', { field: 'defaultCycleRule' });
                        customProps.setInitialValues({
                          ...values,
                          assignedCycleRules: event,
                          defaultCycleRule: ''
                        });
                      }}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={getFilterLocaleValues(values['assignedCycleRules'], i18n)}
                      suggestions={getFilterLocaleValues(allCycleRules, i18n)}
                      isClearable
                      isMulti
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>

                <FormStyle.default.FieldsWrapper className="spnocycle">
                  <Label htmlFor="assignedCycleExceptions" variant="body1">
                    {i18n.t('companyData.exceptions')}
                  </Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="assignedCycleExceptions"
                      id="assignedCycleExceptions"
                      onChange={(event) => {
                        handleEdit(event, { field: 'assignedCycleExceptions' });
                        customProps.setInitialValues({
                          ...values,
                          assignedCycleExceptions: event
                        });
                      }}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={getFilterLocaleValues(values['assignedCycleExceptions'], i18n)}
                      suggestions={getFilterLocaleValues(allCycleExceptions, i18n)}
                      disabled={checkIsDefaultFM()}
                      isDisabled={
                        !values?.assignedCycleRules ||
                        !values?.assignedCycleRules.length ||
                        (values?.assignedCycleRules.length &&
                          !(
                            underscore
                              .pluck(values?.assignedCycleRules, 'value')
                              .includes('USA 60h/7 days') ||
                            underscore
                              .pluck(values?.assignedCycleRules, 'value')
                              .includes('USA 70h/8 days') ||
                            underscore
                              .pluck(values?.assignedCycleRules, 'value')
                              .includes('Texas 70h/7 day')
                          ))
                      }
                      isClearable
                      isMulti
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>

                <FormStyle.default.FieldsWrapper className="spnocycle">
                  <Label htmlFor="defaultCycleRule" variant="body1">
                    {i18n.t('companyData.defaultCycleRule')}
                  </Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="defaultCycleRule"
                      id="defaultCycleRule"
                      onChange={(event) => {
                        handleEdit(event, { field: 'defaultCycleRule' });
                        customProps.setInitialValues({
                          ...values,
                          defaultCycleRule: event
                        });
                      }}
                      disabled={checkIsDefaultFM()}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={getFilterLocaleValues([values['defaultCycleRule']], i18n)}
                      suggestions={getFilterLocaleValues(values['assignedCycleRules'] || [], i18n)}
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>

                <FormStyle.default.FieldsWrapper className="spnocycle">
                  <Label htmlFor="assignedVehicleType" variant="body1">
                    {i18n.t('companyData.vehicleType')}
                  </Label>
                  <FormStyle.default.TextWrapper className="userForm">
                    <Field
                      name="assignedVehicleType"
                      id="assignedVehicleType"
                      onChange={(event) => {
                        handleEdit(event, { field: 'assignedVehicleType' });
                        customProps.setInitialValues({
                          ...values,
                          assignedVehicleType: event
                        });
                      }}
                      as={Select}
                      disabled={checkIsDefaultFM()}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={getFilterLocaleValues([values['assignedVehicleType']], i18n)}
                      suggestions={
                        getFilterLocaleValues(values?.assignedCycleRules &&
                          values?.assignedCycleRules.length &&
                          (
                            underscore
                              .pluck(values?.assignedCycleRules, 'value')
                              .includes('USA 60h/7 days') ||
                            underscore
                              .pluck(values?.assignedCycleRules, 'value')
                              .includes('USA 70h/8 days') ||
                            underscore
                              .pluck(values?.assignedCycleRules, 'value')
                              .find(rule => rule === 'Local Radius') !== undefined
                          )
                          &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Texas 70h/7 day') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Canada 70h/7 day South') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Canada 120h/14 day South') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Canada 70h/7 day North') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Canada 120h/14 day North') &&
                          !underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('Mexico')
                          ? allVehicleTypes
                          : [{ label: 'Property', value: 'Property' }], i18n)
                      }
                    />
                  </FormStyle.default.TextWrapper>
                </FormStyle.default.FieldsWrapper>
              </div>
            )}
        </Styled.RightWrapper>
      </Styled.Wrapper>
      {typeof customProps.hasApiStatus?.errorMessage === 'string' && (
        <Message
          type="error"
          asTags
          message={i18n.t(customProps.hasApiStatus.errorMessage)}
        />
      )}
      {(!customProps.viewOnly && !checkIsDefaultFM()) &&
        (<Styled.submitUser>
          <FormStyle.default.ButtonsWrapper className="submitSection">
            <Button
              className="buttonStyle"
              type="reset"
              label={i18n.t('common.cancel')}
              onClick={(e) => {
                cancelAction();
              }}
            />
            <Button
              type="submit"
              className="buttonStyle"
              label={i18n.t('common.save')}
              onClick={(e, d) => {
                const validateResponse = validateForm();
                validateResponse.then((data) => {
                  submitAction(data);
                });
              }}
            />
          </FormStyle.default.ButtonsWrapper>
        </Styled.submitUser>)
      }
    </>
  );
};

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};

export default RenderComponent;
