import React, { lazy } from 'react';

import { pageUrls, ROLES } from '@/config/constants/keys';
import { hasRestrictedRole } from '@/utils/utils';
const layout = window?.$environment?.CURRENT_LAYOUT;
const serviceProvider = window.$environment?.SERVICE_PROVIDER;

const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;

const TopActions = ({ i18n, updateCard, callback, Styled, history }) => {
  const updateId = 'updateCard';

  return (
    <Styled.Wrapper>
    <FormStyle.ButtonsWrapper className="creditCardWrapper">
      {serviceProvider === 'clutch' && !hasRestrictedRole([ROLES.SERVICE_MANAGER]) ? (
        <Button
          id="creditCardUpdate"
          type="submit"
          label={i18n.t('companyData.updateCardDetails')}
          onClick={() => {
            updateCard({
              updateId,
              callback: callback
            });
          }}
        />
      ) : (serviceProvider !== 'em' && serviceProvider !== 'tp' && serviceProvider!== 'cyntrx' && serviceProvider!== 'edvir' && serviceProvider!== 'webfleet' && serviceProvider!== 'riskTheory' && serviceProvider!== 'at' && serviceProvider!== 'eldx' && serviceProvider!== 'simplex' && serviceProvider!== 'azuga' && serviceProvider !== 'trackit' && serviceProvider !== 'ft' && serviceProvider !== 'hcss' && serviceProvider !== 'patriot' && !hasRestrictedRole([ROLES.SERVICE_MANAGER])) ? (
        <Button
          id="creditCardUpdate"
          type="submit"
          label={i18n.t('companyData.paymentInformation')}
          onClick={() => {
            history.push(pageUrls.paymentInformation);
          }}
        />
      ) : null}
    </FormStyle.ButtonsWrapper>
    </Styled.Wrapper>
  );
};
export default TopActions;
