import React, { useEffect, useState, lazy } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { pageUrls, imageUrls } from '@/config/constants/keys';
import Box from '@material-ui/core/Box';
import { getImagePath } from '@/utils/utils';

const layout = window?.$environment?.CURRENT_LAYOUT;
const Modal = require(`@/components/UI/Modal/${layout}`).default;
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));

const Index = ({ theme, filters, ...contentProps }) => {
  return (
    <Box style={{ ...theme.palette.contentStyle }}>
      <Component {...contentProps} />
    </Box>
  );
};
export default Index;

const Component = ({ message, Styled, permissionDenied, isPopUp }) => {
  useEffect(() => {
    if (isPopUp) {
      setSuspendedOpen(true);
      document.title = 'Company is suspended'
    }
  }, [isPopUp]);
  const [suspendedOpen, setSuspendedOpen] = useState(false);

  let serviceProvider = {
    gs: 'Gorilla safety',
    em: 'ELD Mandate Pro',
    trackon: 'Track On',
    tp: 'Trucker Path',
    spireon: 'Spireon',
    clutch: 'Clutch ELD'
  }

  const [ModalSuspend] = useState({
    header: '',
    content: () => (
      <div className="successpopup">
        <img
          src={getImagePath(imageUrls.confirmIcon)}
          alt="company suspended"
        />
        <h2> Company is suspended. Please contact {serviceProvider[window.$environment.SERVICE_PROVIDER]}. </h2>
        <div className="deletePopupBtn">
          <Button
            type="submit"
            label={i18n.t('common.ok')}
            onClick={() => {
              setSuspendedOpen(false);
            }}
          />
        </div>
      </div>
    )
  });
  const { i18n } = useTranslation();
  if (isPopUp) {
    return (
      <Modal
        open={suspendedOpen}
        data={ModalSuspend}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
      />
    );
  } else
    return (
      <Styled.Wrapper permissionDenied={permissionDenied}>
        {message && typeof message === 'string' ? (
          <span className="unauthorized">{message}</span>
        ) : message ? (
          message()
        ) : (
          <>
            <h1>{i18n.t('apology')}...</h1>
            <p>{i18n.t('page moved')}</p>
            <p>
              {window.$environment.SERVICE_PROVIDER === 'spireon'
                ? 'Please go back to'
                : i18n.t('send feedback')}
              <a
                href={
                  window.$environment.SERVICE_PROVIDER === 'spireon'
                    ? pageUrls.dashboard
                    : pageUrls.feedback
                }
                className="here"
              >
                {window.$environment.SERVICE_PROVIDER === 'spireon'
                  ? 'Summary'
                  : i18n.t('here')}
              </a>
            </p>
          </>
        )}
      </Styled.Wrapper>
    );
};

Component.propTypes = {
  message: PropTypes.string.isRequired
};
