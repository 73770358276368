import styled from 'styled-components';

const Wrapper = styled.div`
  ${({ theme }) => `
  .scoreSection {
    display: flex;
    gap: 8px;
    justify-content: space-around;
    .sections {
      width: 46%;
      .MuiBox-root {
        flex: 0 0 50% !important;
      }
      .MuiFormLabel-root{
        flex: 0 0 45% !important;
      }
      .MuiInputBase-root{
        margin-bottom: 0 !important;
      }
    }
  }
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  form {
    width: 100%;
    // padding: 3%;
  }
  #companyInfoSubmit {
    min-width: 210px;
  }
  .buttonSection {
    display: flex;
    justify-content: flex-end;
    .submitSection{
      .MuiBox-root:first-child {
      margin-right: 10%;
      }
    }
  }
  .companyInfoSubmit {
    margin-right: 1%;
  }
  .creditCardWrapper {
    padding-bottom: 1%;
    #creditCardUpdate {
        margin-top: 0;
    }
  }
  .subscriptionCompany {
    flex: 0 0 25%;
  }
  .cycleruleSection{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  `}
`;
const leftSection = styled.div`
  ${({ theme }) => `
  flex: 0 0 48%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .values {
    color: ${theme.colors.PRIMARY_CONTRAST};
  }
  .checkboxesCompany{
    display: flex;
    .agreeTerms{
      flex: 0 0 40%;
    }
  }
  .valueWrapper{
    min-height: 39px;
    .MuiFormLabel-root{
      padding-right: 18px;
      line-height: 1.3;
    }
  }
`}
`;
const rightSection = styled.div`
  ${({ theme }) => `
  flex: 0 0 48%;
  display: flex;
  justify-content: space-between;
`}
`;

const leftWrapper = styled.div`
  ${({ theme }) => `
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
  .values {
    color: ${theme.colors.PRIMARY_CONTRAST};
  }
  .checkboxesCompany{
    display: flex;
    .agreeTerms{
      flex: 0 0 40%;
    }
  }
  .valueWrapper{
    min-height: 39px;
    .MuiFormLabel-root{
      padding-right: 18px;
      line-height: 1.3;
    }
  }
`}
`;
const rightWrapper = styled.div`
  ${({ theme }) => `
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
  
 
`}
`;
const TextWrapper = styled.div`
  ${({ theme }) => `
  flex: 0 0 75%;
`}
`;

const FieldsWrapper = styled.div`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
`;
const wrapperSection = styled.div`
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flexbox;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  .companyForm {
    flex: 0 0 48%;
  }
`;
const InsuranceCompanyWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleSection = styled.div`
  display: flex;
  padding-right: 2%;
  justify-content: space-between;
  align-items: center;
  div:first-child {
    width: 90%;
  }

`;
const Styled = {
  Wrapper,
  TextWrapper,
  TitleSection,
  FieldsWrapper,
  leftSection,
  rightSection,
  wrapperSection,
  InsuranceCompanyWrapper,
  leftWrapper,
  rightWrapper
};

export default Styled;
