import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Pagination from 'material-ui-flat-pagination';
import lodash from 'lodash';

import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import { getImagePath } from '@/utils/utils';
import { imageUrls } from '@/config/constants/keys';
const layout = window?.$environment?.CURRENT_LAYOUT;
const SvgIcon = lazy(() => import(`@/components/SvgIcon/${layout}`));
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));

const EnhancedTableHead = ({
  order,
  orderBy,
  onRequestSort,
  sortClassName,
  headCells,
  actionsCount,
  i18n,
  actionRestricted,
  mergedColumn,
  headerOne,
  headerTwo,
  position,
  type,
  columnAlign,
  isViolationsTable
}) => {
  const createSortHandler = (property, isNumeric) => (event) => {
    onRequestSort(event, property, isNumeric);
  };

  return (
    <TableHead>
      {mergedColumn ? (
        <>
          <TableRow>
            {headerOne.map((headCell, index) => {
              return (
                <TableCell
                  colSpan={index == position ? 4 : ''}
                  rowSpan={index !== position ? 2 : ''}
                >
                  {i18n.t(headCell.label)}
                </TableCell>
              );
            })}
          </TableRow>
          <TableRow>
            {headerTwo.map((headCell, index) => {
              return <TableCell>{i18n.t(headCell.label)}</TableCell>;
            })}
          </TableRow>
        </>
      ) : (
        <TableRow>
          {headCells.map((headCell) => {
            if (headCell.id === 'actions') {
              return (
                <TableCell
                  key={headCell.id}
                  align={'center'}
                  colSpan={ actionsCount }
                >
                  {actionRestricted ? '' : i18n.t(headCell.label) || ''}
                </TableCell>
              );
            } else if (headCell.needSorting) {
              return (
                <TableCell
                  key={headCell.id}
                  align={columnAlign? columnAlign: 'right'}
                  onClick={createSortHandler(headCell.id, headCell.numeric)}
                  sortDirection={
                    orderBy === headCell.id && order ? order : false
                  }
                  className={headCell.id === orderBy ? 'active' : ''}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id && order ? order : 'asc'}
                    IconComponent={() => (
                      <Icon
                        className={
                          orderBy === headCell.id
                            ? sortClassName
                            : 'MuiTableSortLabel'
                        }
                      >
                        {orderBy === headCell.id && order ? (
                          <ArrowDropUpIcon />
                        ) : (
                          <SvgIcon value={'unsort'} className="unsort" />
                        )}
                      </Icon>
                    )}
                  >
                    {i18n.t(headCell.label)}
                  </TableSortLabel>
                </TableCell>
              );
            } else {
              return (
                <TableCell style={{display: isViolationsTable ? "none" : "table-cell"}} key={headCell.id} align={'left'}>
                  {i18n.t(headCell.label)}
                </TableCell>
              );
            }
          })}
        </TableRow>
      )}
    </TableHead>
  );
};
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc', '']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired
};

const EnhancedTable = ({
  columns,
  data,
  emptyMessage,
  actions,
  defaultSortBy,
  Styled,
  i18n,
  itemsPerPage,
  type,
  totalRecords,
  changeRequest,
  noPagination,
  actionRestricted,
  clickedRow,
  pagenationClicked,
  pageinationReset,
  headerOne,
  headerTwo,
  mergedColumn,
  position,
  colSpan,
  length,
  id,
  noTableActions,
  baseRequest,
  outPageCountValue,
  columnAlign,
  newUI,
  disablePadding,
  sortCallBack,
  isViolationsTable,
  setPagination
}) => {
  useEffect(() => {
    if (pageinationReset) {
      setPaginationOffset(0);
      setPage(0);
    } else {
      setPaginationOffset(paginationOffset);
      setPage(page);
    }
  }, [pageinationReset]);

  const [order, setOrder] = React.useState(defaultSortBy?.order || '');
  const [orderBy, setOrderBy] = React.useState(defaultSortBy?.sortBy || '');
  const numeric = columns.filter((item) => item.id === defaultSortBy?.sortBy);
  const [isNumeric, setIsNumeric] = React.useState(
    numeric[0]?.numeric || false
  );
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(itemsPerPage || 10);
  const [totalItems, setTotalItems] = React.useState(
    totalRecords || data.length
  );

  useEffect(() => {
    if(setPagination) setPagination(false)
  }, [page])
  const [sortClassName, setSortClassName] = React.useState('MuiTableSortLabel');
  const [paginationOffset, setPaginationOffset] = React.useState(0);
  useEffect(() => {
    handleRequestSort('', orderBy, isNumeric);
  }, []);
  useEffect(() => {
    setTotalItems(totalRecords || data.length);
  }, [totalRecords, data.length]);

  const desc = (a, b, orderBy, isNumeric) => {
    if (orderBy.includes('date')) {
      if (new Date(b[orderBy]) < new Date(a[orderBy])) {
        return -1;
      }
      if (new Date(b[orderBy]) > new Date(a[orderBy])) {
        return 1;
      }
    } else if (orderBy === 'pdf') {
      let firstValue = a[orderBy] ? 'val' : '';
      let secondValue = b[orderBy] ? 'val' : '';
      if (secondValue < firstValue) {
        return -1;
      }
      if (secondValue > firstValue) {
        return 1;
      }
    } else {
      let firstValue =
        typeof a[orderBy] === 'object' &&
        typeof a[orderBy]?.props?.children === 'object'
          ? a[orderBy]?.props?.children?.props?.children
          : typeof a[orderBy] === 'object'
          ? a[orderBy]?.props?.children
          : a[orderBy];
      let secondValue =
        typeof b[orderBy] === 'object' &&
        typeof b[orderBy]?.props?.children === 'object'
          ? b[orderBy]?.props?.children?.props?.children
          : typeof b[orderBy] === 'object'
          ? b[orderBy]?.props?.children
          : b[orderBy];
      if (isNumeric)
        firstValue =
          firstValue === null || firstValue === undefined
            ? Number.NEGATIVE_INFINITY
            : parseFloat(firstValue);
      else
        firstValue =
          firstValue === null || firstValue === undefined
            ? ''
            : firstValue?.toString()?.toLowerCase();
      if (isNumeric)
        secondValue =
          secondValue === null || secondValue === undefined
            ? Number.NEGATIVE_INFINITY
            : parseFloat(secondValue);
      else
        secondValue =
          secondValue === null || secondValue === undefined
            ? ''
            : secondValue?.toString()?.toLowerCase();

      if (secondValue < firstValue) {
        return -1;
      }
      if (secondValue > firstValue) {
        return 1;
      }
    }
  };
  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  const getSorting = (order, orderBy, isNumeric) => {
    return order === 'desc'
      ? (a, b) => -desc(a, b, orderBy, isNumeric)
      : (a, b) => desc(a, b, orderBy, isNumeric);
  };
  const handleRequestSort = (event, property, isNumeric) => {
    if (type === 'client') {
      const isDesc = orderBy === property && order === 'desc';
      setSortClassName(
        !isDesc
          ? 'MuiTableSortLabel-iconDirectionDesc'
          : 'MuiTableSortLabel-iconDirectionAsc'
      );
      setOrder(isDesc ? 'asc' : 'desc');
    } else if (type === 'server') {
      const isDesc = orderBy === property && order === 'desc';
      setSortClassName(
        !isDesc
          ? 'MuiTableSortLabel-iconDirectionDesc'
          : 'MuiTableSortLabel-iconDirectionAsc'
      );
      setOrder(isDesc ? 'asc' : 'desc');
      if (event) {
        const request = {
          sortCol: property,
          sortDir: !isDesc ? 'asc' : 'desc',
          displayStart: 1,
          displayLength: rowsPerPage
        };
        changeRequest({ ...baseRequest, ...request });
      }
    }
    setOrderBy(property);
    setIsNumeric(isNumeric);
    setPaginationOffset(0);
    setPage(0);
    if(sortCallBack){
      sortCallBack()
    }
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleClick = (row, clickedRowId) => {
    return clickedRow ? clickedRow(row, clickedRowId) : '';
  };
  const handleOnPaginationClick = (offset, page) => {
    if (type === 'server') {
      const request = orderBy
        ? {
            sortCol: orderBy,
            sortDir: order === 'asc' ? 'desc' : 'asc',
            displayStart: offset ? offset : 1,
            displayLength: rowsPerPage
          }
        : {
            displayStart: offset? offset : 1,
            displayLength: rowsPerPage
          };
      changeRequest({ ...baseRequest, ...request });
    }
    setPaginationOffset(offset);
    setPage(page - 1);
    pagenationClicked && pagenationClicked(true);
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const sourceData = [...data];
  const pageData =
    type === 'server' || noTableActions
      ? sourceData
      : orderBy
      ? stableSort(sourceData, getSorting(order, orderBy, isNumeric)).splice(
          page * rowsPerPage,
          rowsPerPage
        )
      : sourceData.splice(page * rowsPerPage, rowsPerPage);

  const handleChangeRowsPerPage = (event) => {
    if (type === 'server') {
      const request = orderBy
        ? {
            sortCol: orderBy,
            sortDir: order === 'asc' ? 'desc' : 'asc',
            displayStart: 1,
            displayLength: parseInt(event.target.value, 10)
          }
        : {
            displayStart: 1,
            displayLength: parseInt(event.target.value, 10)
          };
      changeRequest({ ...baseRequest, ...request });
    }
    outPageCountValue && outPageCountValue(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    if (type === 'server') {
      const request = orderBy
        ? {
            sortCol: orderBy,
            sortDir: order === 'asc' ? 'desc' : 'asc',
            displayStart: rowsPerPage * newPage,
            displayLength: rowsPerPage
          }
        : {
            displayStart: rowsPerPage * newPage,
            displayLength: rowsPerPage
          };
      changeRequest({ ...baseRequest, ...request });
    }
    pagenationClicked && pagenationClicked(true);
    setPage(newPage);
  };
  return (
    <Styled.Container newUI={newUI} disablePadding={disablePadding}>
      <Paper className={'classes.paper'}>
        <Styled.TableContainer>
          <Table
            className={'classes.table'}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced  table"
            id={id || ''}
          >
            <EnhancedTableHead
              classes={'classes'}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              sortClassName={sortClassName}
              headCells={columns}
              actionsCount={actions.length}
              i18n={i18n}
              actionRestricted={actionRestricted}
              mergedColumn={mergedColumn}
              position={position}
              colSpan={colSpan}
              length={length}
              headerOne={headerOne}
              headerTwo={headerTwo}
              type={type}
              columnAlign={columnAlign}
              isViolationsTable={isViolationsTable}
            />
            <TableBody>
              {pageData.map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-row${index}`;
                const columnsWithoutAction = columns.filter(
                  (item) => item.id !== 'Actions'
                );
                return (
                  <TableRow
                    hover={false}
                    onClick={(event) => handleClick(row, index)}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.name}
                    selected={isItemSelected}
                    id={labelId}
                  >
                    {columnsWithoutAction.map((column, number) => {
                      if( column.id != 'actions'){
                        return (
                          <TableCell
                            align={'left'}
                            key={'row' + number}
                            className={column.id === orderBy ? 'active' : ''}
                            width={column.width || 'auto'}
                          >
                            {typeof lodash.get(row, column.id) == 'string' 
                              ? column.id == 'role' 
                                ? lodash.get(row, column.id).split(',').map(ele => i18n.t(ele.trim())).toString() 
                                : lodash.get(row, column.id).indexOf(':') == -1 && !lodash.get(row, column.id).includes('null') ? i18n.t(lodash.get(row, column.id)) : lodash.get(row, column.id).includes('hours') ? lodash.get(row, column.id).replace('hours', i18n.t('hours')) : lodash.get(row, column.id)
                              : lodash.get(row, column.id)}
                          </TableCell>
                        );
                      }
                      
                    })}
                    {!actionRestricted &&
                      actions.map((action, number) => {
                        return (
                          action.icon(row, index) ? <TableCell
                            align={'center'}
                            key={'row' + 'action' + number}

                          >
                            {action.icon(row, index)}
                          </TableCell> : null
                        );
                      })}
                  </TableRow>
                );
              })}
              {pageData.length < 1 && (
                <TableRow style={{ height: 53 }} className="nodataspireon">
                  <TableCell className="noData" colSpan={columns.length + 1}>
                    {typeof emptyMessage === 'string'
                      ? i18n.t(emptyMessage)
                      : emptyMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Styled.TableContainer>
        {!noPagination ? (
          window.$environment.CURRENT_LAYOUT === 'SpireonOld' ? (
            <Styled.PaginationWrapper>
              <TablePagination
                component="div"
                count={totalItems}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage="Show"
              />
            </Styled.PaginationWrapper>
          ) : window.$environment.CURRENT_LAYOUT === 'SpireonNew' ? (
            <Styled.PaginationContainer>
              <TablePagination
                component="div"
                count={totalItems}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Styled.PaginationContainer>
          ) : (
            <Styled.PaginationContainer>
              <Pagination
                limit={rowsPerPage}
                offset={paginationOffset}
                total={totalItems}
                page={page}
                currentPageColor={'primary'}
                otherPageColor={'secondary'}
                onClick={(e, offset, page) => {
                  handleOnPaginationClick(offset, page);
                }}
                size={'small'}
              />
            </Styled.PaginationContainer>
          )
        ) : null}
      </Paper>
    </Styled.Container>
  );
};

const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onChangePage } = props;

  const [textValue, setTextValue] = useState(count ? '1' : '0');
  useEffect(() => {
    setTextValue(count ? '1' : '0');
  }, [count]);

  useEffect(() => {
    if (page == 0) setTextValue(1);
  }, [page]);

  const handleFirstPageButtonClick = (event) => {
    setTextValue(1);
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    setTextValue(page);
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    setTextValue(page + 2);
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    setTextValue(Math.max(0, Math.ceil(count / rowsPerPage)));
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <img
          src={getImagePath(
            page === 0
              ? imageUrls.firstPageDisabledIcon
              : imageUrls.firstPageIcon
          )}
          alt="firstPageIcon"
        />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <img
          src={getImagePath(
            page === 0 ? imageUrls.prevPageDisabledIcon : imageUrls.prevPageIcon
          )}
          alt="prevPageIcon"
        />
      </IconButton>
      <p className="pagenoLabel">Page</p>
      <div className="pageInput">
        <Input
          value={textValue}
          handleOnChange={(event) => {
            const number = /^\d+$/;
            const isValid = event.match(number);
            if (isValid) {
              let source = parseInt(event, 10);
              let value = source;

              if (source < 1) value = 1;
              else if (source > Math.ceil(count / rowsPerPage))
                value = page + 1;

              setTextValue(value);
              onChangePage('', value - 1);
            } else setTextValue('');
          }}
          type="text"
          className="pageInput"
        />
      </div>

      {/* <p>of</p>
      <p>{' ' + count}</p> */}
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <img
          src={getImagePath(
            page >= Math.ceil(count / rowsPerPage) - 1
              ? imageUrls.nextPageDisabledIcon
              : imageUrls.nextPageIcon
          )}
          alt="nextPageIcon"
        />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <img
          src={getImagePath(
            page >= Math.ceil(count / rowsPerPage) - 1
              ? imageUrls.lastPageDisabledIcon
              : imageUrls.lastPageIcon
          )}
          alt="lastPageIcon"
        />
      </IconButton>
    </>
  );
};
export default EnhancedTable;
