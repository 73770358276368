import { ROLES } from '@/config/constants/keys';

const routeConfigs = [
  {
    url: '/user/profile',
    component: 'Profile',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'profile',
    title: 'Edit Profile',
    dependency: ['dvirOnly']
  },
  {
    url: '/welcome',
    component: 'Welcome',
    title: 'Welcome',
    roles: ['OWNER'],
    layout: 'welcome',
    module: 'welcome',
    dependency: ['dvirOnly']
  },
  {
    url: '/logs/archive_list',
    component: 'ArchiveLogs',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER'],
    subscriptions: '',
    module: 'archiveLogsList',
    title: 'Logs'
  },
  {
    url: '/downloadCenter/list',
    component: 'DownloadCenter',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'downloadCenterEnabled',
    title: 'Download Center'
  },
  {
    url: '/scheduling/list',
    component: 'ManageScheduling',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'manageScheduling',
    title: 'Manage Scheduling'
  },
  {
    url: '/logs/add',
    component: 'AddLogs',
    roles: ['FLMANAGER'],
    module: 'logsAdd',
    title: 'Logs'
  },
  {
    url: '/camera',
    component: 'Camera',
    roles: ['FLMANAGER'],
    module: 'cameraList',
    title: 'Camera Events',
  },
  {
    url: '/logs/realTimeList',
    component: 'RealTimeLogs',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER'],
    module: 'realTimeLogsList',
    title: 'Logs',
    subscriptions: 'wLogs'
  },
  {
    url: '/dashboard',
    component: 'Dashboard',
    title: 'Dashboard',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    layout: 'plain',
    subscriptions: 'wDashboard',
    module: 'dashboard'
  },
  {
    url: '/vehicle/dtc/:id',
    component: 'ViewDCA',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'viewDCA',
    title: 'Diagnostic Code Alerts',
  },
  {
    url: '/driverStatus/list',
    component: 'DriverStatusViewMore',
    roles: ['FLMANAGER', 'ADMIN'],
    title: 'Driver Status',
    module: 'driverStatusViewMore'
  },
  {
    url: '/violations/list',
    component: 'PotentialViolations',
    roles: ['FLMANAGER', 'ADMIN'],
    title: 'Violations',
    module: 'potentialViolationsViewMore'
  },
  {
    url: '/',
    component: 'InitialLoad',
    title: 'Constitution Risk Management',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    layout: 'plain',
    module: 'root',
    dependency: ['dvirOnly']
  },
  {
    url: '/logs/logbook/:id',
    component: 'LogBook',
    roles: ['FLMANAGER', 'DRIVER', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'logBook',
    title: 'Log Book'
  },
  {
    url: '/logs/history/:id',
    component: 'LogHistory',
    roles: ['FLMANAGER'],
    subscriptions: '',
    module: 'logsHistory',
    title: 'Log History'
  },
  {
    url: '/logs/ud_list',
    component: 'UndefinedDriverList',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    subscriptions: '',
    module: 'logsUdList',
    title: 'Logs'
  },
  {
    url: '/logs/edit/:id',
    component: 'EditDailyLog',
    roles: ['FLMANAGER', 'DRIVER'],
    subscriptions: '',
    module: 'logsEdit',
    title: 'Logs'
  },
  {
    url: '/logs/view/:id',
    component: 'EditDailyLog',
    roles: ['FLMANAGER', 'ADMIN'],
    subscriptions: '',
    module: 'logsView',
    title: 'Logs'
  },
  {
    url: '/logs/list',
    component: 'ManageLogs',
    roles: ['FLMANAGER', 'ADMIN', 'DRIVER', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    subscriptions: '',
    module: 'logsList',
    title: 'Logs'
  },

  {
    url: '/user/list',
    component: 'Users',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    subscriptions: 'wUsersList',
    module: 'userList',
    title: 'Manage Users',
    dependency: ['dvirOnly']
  },
  {
    url: '/violations/list',
    component: 'PotentialViolations',
    roles: ['FLMANAGER', 'ADMIN'],
    subscriptions: '',
    module: 'potentialViolationsViewMore',
    title: 'Violations'
  },
  {
    url: '/company/edit',
    component: 'CompanyData',
    roles: ['FLMANAGER', 'ADMIN'],
    subscriptions: '',
    module: 'companyDataEdit',
    title: 'Company Data',
  },
  {
    url: '/policyholders/edit/:id',
    component: 'EditCompany',
    roles: ['ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    subscriptions: '',
    module: 'companiesEdit',
    title: 'Edit Policy Holder',
  },
  {
    url: '/inspections/customdvir',
    component: 'CustomDvir',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'customDvirEnabled',
    title: 'Custom DVIR',
    dependency: ['customDvirEnabled', 'dvirOnly']

  },
  {
    url: '/inspections/customdvir/edit/:id',
    component: 'AddOrEditNewForm',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'addOrEditNewForm',
    title: 'Edit Custom DVIR',
    dependency: ['customDvirEnabled', 'dvirOnly']
  },

  {
    url: '/inspections/customdvir/add',
    component: 'AddOrEditNewForm',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'addOrEditNewForm',
    title: 'Add Custom DVIR',
    dependency: ['customDvirEnabled', 'dvirOnly']
  },
  {
    url: '/inspections/customdvir/addTrailer',
    component: 'AddOrEditNewFormTrailer',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'AddOrEditNewFormTrailer',
    title: 'Add Custom DVIR',
    dependency: ['customDvirEnabled', 'dvirOnly']
  },
  {
    url: '/inspections/customdvir/editTrailer/:id',
    component: 'AddOrEditNewFormTrailer',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'AddOrEditNewFormTrailer',
    title: 'Edit Custom DVIR',
    dependency: ['customDvirEnabled', 'dvirOnly']
  },
  {
    url: '/policyholders/add',
    component: 'AddCompany',
    roles: ['ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    subscriptions: '',
    module: 'addCompanies',
    title: 'Add Policy Holder',
  },
  {
    url: '/user/add',
    component: 'AddUser',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    subscriptions: '',
    module: 'addUser',
    title: 'Add User',
    dependency: ['dvirOnly']
  },
  {
    url: '/user/edit/:id',
    component: 'EditUser',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    subscriptions: 'wUserDetails',
    module: 'editUser',
    title: 'User details',
    dependency: ['dvirOnly']
  },
  {
    url: '/vehicle/status/list',
    component: 'VehicleStatusList',
    roles: ['FLMANAGER', 'ADMIN'],
    subscriptions: '',
    module: 'vehicleStatusViewMore',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled', 'dvirOnly']
  },
  {
    url: '/vehicle/list',
    component: 'Vehicles',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    subscriptions: 'wVehiclesList',
    module: 'vehicleList',
    title: 'Manage Vehicles',
    dependency: ['dvirOnly']
  },
  {
    url: '/vehicle/add',
    component: 'AddVehicle',
    roles: ['ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    subscriptions: '',
    module: 'addVehicle',
    title: 'Add vehicle',
    dependency: ['dvirOnly']

  },
  {
    url: '/vehicle/edit/:id',
    component: 'EditVehicle',
    roles: ['ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER, 'FLMANAGER'],
    subscriptions: 'wVehicleDetails',
    module: 'editVehicle',
    title: 'Edit vehicle',
    dependency: ['dvirOnly']

  },
  {
    url: '/trailer/add',
    component: 'AddTrailer',
    roles: ['ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'addTrailer',
    title: 'Add Trailer',
    dependency: ['dvirOnly']

  },
  {
    url: '/trailer/edit/:id',
    component: 'EditTrailer',
    roles: ['ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER, 'FLMANAGER'],
    module: 'editTrailer',
    title: 'Edit Trailer',
    dependency: ['dvirOnly']

  },
  {
    url: '/driverStatus/history',
    component: 'DriverStatusHistory',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'driverStatusHistory',
    title: 'History of Driver',
    dependency: ['dvirOnly']

  },
  {
    url: '/vehicle/history',
    component: 'Vehicles',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'vehicleHistory',
    title: 'Manage Vehicles',
    dependency: ['dvirOnly']

  },
  {
    url: '/alerts/requests',
    component: 'DeveloperRequests',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'alertsRequests',
    title: 'Developer Permission Requests',
  },
  {
    url: '/department/list/:id',
    component: 'Department',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'departmentsList',
    title: 'Add Department',
    dependency: ['dvirOnly']

  },
  {
    url: '/report/mileage',
    component: 'IFTAMileage',
    roles: ['FLMANAGER', 'ADMIN'],
    subscriptions: 'wIFTAReport',
    module: 'reportMileage',
    title: 'IFTA Mileage'
  },
  {
    url: '/report/ifta/list',
    component: 'IFTAList',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'iftaList',
    title: 'IFTA Mileage',
    dependency: ['taxableMilesViewEnabled']
  },
  {
    url: '/report/state/add',
    component: 'AddState',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'addState',
    title: 'Add State',
    dependency: ['taxableMilesViewEnabled']
  },
  {
    url: '/report/state/edit/:id',
    component: 'EditState',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'editState',
    title: 'Edit State',
    dependency: ['taxableMilesViewEnabled']
  },
  {
    url: '/report',
    component: 'Report',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'reportsEnabled',
    title: 'Reports',
    dependency: ['dvirOnly']

  },
  {
    url: '/logs/fmcsaLogs',
    component: 'DotAudit',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'fmcsaLogs',
    title: 'DOT Audit'
  },

  {
    url: '/inspections/list',
    component: 'Inspections',
    roles: ['FLMANAGER', 'ADMIN'],
    subscriptions: 'wInspections',
    module: 'inspections',
    title: 'Inspections',
    dependency: ['dvirOnly']
  },
  {
    url: '/usdot/management',
    component: 'UsDotNoManagement',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'usDotManagementenabled',
    title: 'USDOT No. Management Page'
  },
  {
    url: '/inspections/advanceConfig',
    component: 'AdvancedConfig',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'advancedDvirEnabled',
    title: 'Advance Config',
    dependency: ['dvirOnly']

  },

  {
    url: '/logs/edit/0/:id',
    component: 'EditDailyLog',
    roles: ['FLMANAGER'],
    module: 'editLog',
    title: 'Logs'
  },
  {
    url: '/logs/logbookFilter/:id',
    component: 'LogBook',
    roles: ['FLMANAGER'],
    module: 'logbookFilterId',
    title: 'Log Book'
  },
  {
    url: '/logs/logbookFilter/:id/:date',
    component: 'LogBook',
    roles: ['FLMANAGER'],
    module: 'logbookFilterIdDate',
    title: 'Log Book'
  },
  {
    url: '/fuel/list',
    component: 'FuelConsumptionReport',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'fuelConsumptionReport',
    title: 'Fuel Track List',
    subscriptions: 'wFuelConsumptionReport'
  },
  {
    url: '/policy/edit',
    component: 'Policies',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'policyEdit',
    title: 'Policies and Procedures',
    subscriptions: 'wPoliciesAndProcedures'
  },
  {
    url: '/fuel/history/:id',
    component: 'RefillsHistory',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'refillsHistory',
    title: 'History of Refills'
  },
  {
    url: '/dashboard/geometris/hardDecceleration/detail',
    component: 'HardDeceleration',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'hardDeceleration',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: '/dashboard/geometris/hardAcceleration/detail',
    component: 'HardAcceleration',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'hardAcceleration',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: '/dashboard/geometris/speeding/detail',
    component: 'Speeding',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'speeding',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: '/dashboard/geometris/driveHrsExceeding/detail',
    component: 'DriveHrsExceeding',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'driveHrsExceeding',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: '/dashboard/geometris/fuelEconomy/detail',
    component: 'FuelEconomy',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'fuelEconomy',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: '/dashboard/geometris/seatBeltWarning/detail',
    component: 'SeatBeltWarning',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'seatBeltWarning',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: '/dashboard/geometris/hardTurn/detail',
    component: 'HardTurn',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'hardTurn',
    title: 'Vehicle Status',
    dependency: ['geometrisEnabled']
  },
  {
    url: '/inspections/edit/:id',
    component: 'EditWorkOrders',
    roles: ['FLMANAGER', 'MECHANIC', ROLES.SERVICE_MANAGER],
    module: 'editWorkOrder',
    title: 'Inspection Details',
    dependency: ['dvirOnly', 'dvirSpecific']
  },
  {
    url: '/workorders/list',
    component: 'WorkOrders',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    subscriptions: 'wReportAndMaintenance',
    module: 'workOrders',
    title: 'Work Orders'
  },
  {
    url: '/workorders/add',
    component: 'AddWorkOrders',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC'],
    module: 'addWorkOrder',
    title: 'Work Order Details'
  },
  {
    url: '/workorders/edit/:id',
    component: 'EditWorkOrders',
    roles: ['FLMANAGER', 'MECHANIC'],
    module: 'editWorkOrder',
    title: 'Work Order Details'
  },
  {
    url: '/parts/list',
    component: 'Parts',
    roles: ['FLMANAGER', 'MECHANIC'],
    module: 'partsInventoryEnabled',
    title: 'Parts'
  },
  {
    url: '/vehicle/notInUse/INSHOP',
    component: 'NotInUseVehicles',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'vehicleNotInUse',
    title: 'Manage Vehicles',
    dependency: ['dvirOnly']
  },
  {
    url: '/vehicle/notInUse/OUTOFSERVICE',
    component: 'OutOfService',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'outOfService',
    title: 'Manage Vehicles',
    dependency: ['dvirOnly']
  },
  {
    url: '/policyholders/list',
    component: 'FleetCompanies',
    roles: [ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'fleetCompanies',
    title: 'Manage Policy Holders',
  },
  {
    url: '/policyholders/upcomingRenewals/list',
    component: 'FleetCompanies',
    roles: [ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'upcomingRenewalCompanies',
    title: 'Manage Policy Holders',
  },
  {
    url: '/dailyDocuments/list',
    component: 'DailyDocuments',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'dailyDocuments',
    title: 'DailyDocuments',
    subscriptions: 'wDailyDocuments'
  },
  {
    url: '/accidents/list',
    component: 'Accidents',
    roles: ['FLMANAGER', 'ADMIN', 'MECHANIC', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'accidentsList',
    title: 'Accidents',
    subscriptions: 'wAccidents'
  },
  {
    url: '/alerts',
    component: 'Alert',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'alertViewMore',
    title: 'Alerts',
    subscriptions: 'wAlerts'
  },
  {
    url: '/scorecard/quickStats',
    component: 'QuickStats',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'quickStats',
    title: 'Quick Stats',
  },
  {
    url: '/scorecard/report',
    component: 'ScorecardReport',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'scorecardReport',
    title: 'Scorecard Report',
  },
  {
    url: '/scorecard/:id',
    component: 'Scorecard',
    roles: ['ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'scoreCard',
    title: 'Scorecard',
  },  
  {
    url: '/scorecard',
    component: 'Scorecard',
    roles: ['ADMIN', 'FLMANAGER'],
    module: 'fleetScoreCard',
    title: 'Scorecard',
  },
  {
    url: '/alerts/all',
    component: 'RadiusComplianceAlerts',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'radiusComplianceAlertsViewMore',
    title: 'Alerts',
    subscriptions: ''
  },
  {
    url: '/alerts/all/:id',
    component: 'RadiusComplianceAlerts',
    roles: ['FLMANAGER', 'ADMIN', ROLES.UNDER_WRITER, ROLES.LOSS_CONTROL, ROLES.CLAIM_ADJUSTER],
    module: 'fleetAlertsViewMore',
    title: 'Alerts',
    subscriptions: ''
  },
  {
    url: '/company/document/edit',
    component: 'Documents',
    roles: ['FLMANAGER', 'ADMIN'],
    module: 'companyDocument',
    title: 'Company documents',
    subscriptions: 'wCompanyDocuments'
  },
];

export default routeConfigs;
