import React, { useEffect } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { colors } from '../../../../config/theme/variables';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';

const { forwardRef, useImperativeHandle } = React;

const SelectFn = forwardRef((props, ref) => {
  const i18n = useTranslation();
  const { Styled, searchWithStart } = props;
  const theme = useTheme();

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: colors.PRIMARY_MAIN,
      width: '100%',
      border: `2px solid ${colors.LIGHT_BORDER}`,
      cursor: 'pointer',
      borderRadius: '0px',
      ':hover': {
        border: `2px solid ${colors.LIGHT_BORDER}`
      }
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        borderRadius: '0px',
        color: isFocused ? 'colors.WHITE' : 'colors.PRIMARY_CONTRAST',
        cursor: 'pointer',
        backgroundColor:
          !isDisabled && isFocused
            ? theme.palette.secondary.main
            : isSelected
            ? theme.PRIMARY_MAIN
            : theme.PRIMARY_MAIN,
        ':hover': {
          backgroundColor: theme.palette.secondary.main,
          color: 'colors.WHITE'
        },
        width: '100%',
        overflow: 'hidden',
        fontFamily: theme.palette.fontFamily
      };
    },
    multiValue: (styles, { data }) => {
      const color = colors.PRIMARY_MAIN;
      return {
        ...styles,
        backgroundColor: color,
        cursor: 'pointer',
        border: '1px solid #817777',
      };
    },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        color: 'colors.PRIMARY_CONTRAST',
        cursor: 'pointer',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'colors.WHITE',
      cursor: 'pointer'
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: 'colors.WHITE',

      ':hover': {
        backgroundColor: colors.PRIMARY_MAIN,
        color: 'colors.WHITE'
      },
      cursor: 'pointer'
    })
  };
  useImperativeHandle(ref, () => ({
    clearValue() {
      setTimeout(() => {
        setSingle(null);
      }, 100);
    }
  }));
  const [single, setSingle] = React.useState(null);

  useEffect(() => {
    if (props.setNullForChat && props.setNullForChat === 'clear') {
      setSingle(null);
    }
  }, [props.setNullForChat, single]);
  const handleChangeSingle = (value, event) => {
    var result = [];
    if (value && value.length > 0) {
      if (value[value.length - 1].value === props.allOption.value) {
        setSingle(props.suggestions);
        return props.onChange(props.suggestions);
      } else if (value.length === props.suggestions.length) {
        result = value.filter(
          (option) => option.value !== props.allOption.value
        );
        setSingle(result);
        return props.onChange(result);
      }

      setSingle(value);
      return props.onChange(value);
    }

    setSingle(value);
    return props.onChange(value);
  };

  const customFilter = (option, inputValue) => {
    let a = option?.label.toString();
    let b = inputValue && inputValue.toString();
    if (searchWithStart) {
      if (b) {
        return a?.toLowerCase().startsWith(b.toLowerCase());
      } else return a.includes(b);
    } else {
      if (b) {
        return a?.toLowerCase().includes(b.toLowerCase());
      } else return a.includes(b);
    }
  };
  const idGenerator = (min, max) => {
    return min + Math.floor(Math.random() * (max - min + 1));
  }
  if (props.allowSelectAll) {
    return (
      <Styled.SelectWrapper>
        <Select
          {...props}
          defaultValue={''}
          isMulti={props.isMulti}
          name="colors"
          options={
            props.suggestions
              ? [{...props.allOption, label: i18n.t(props?.allOption?.label || "")}, ...props.suggestions]
              : props.suggestions
          }
          className="basic-multi-select"
          classNamePrefix="select"
          styles={colourStyles}
          placeholder={props.placeholder}
          onChange={handleChangeSingle}
          value={single}
          filterOption={(a, e) => customFilter(a, e)}
          id={props.id ? props.id : `select_menu_${idGenerator(5000, 1)}`}
          blurInputOnSelect={true}
        />
      </Styled.SelectWrapper>
    );
  } else {
    return (
      <Styled.SelectWrapper className={props.className ? props.className : ''}>
        <Select
          {...props}
          defaultValue={''}
          isMulti={props.isMulti}
          name="colors"
          options={props.suggestions}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={colourStyles}
          placeholder={props.placeholder}
          onChange={handleChangeSingle}
          isClearable={props.isClearable}
          isDisabled={props.disabled || false}
          filterOption={(a, e) => customFilter(a, e)}
          id={props.id ? props.id : `select_menu_${idGenerator(5000, 1)}`}
        />
      </Styled.SelectWrapper>
    );
  }
});

SelectFn.propTypes = {
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
};

SelectFn.defaultProps = {
  allOption: {
    label: 'Select All',
    value: '*'
  }
};

export default SelectFn;
