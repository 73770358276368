import React, { lazy } from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import underscore from 'underscore';
import ReactDatePicker from 'react-datepicker';

import {
  phoneNumberValidation,
  objectArrayFromSameField,
  hasEnabledFeature, 
  getDateLocale,
  getFilterLocaleValues
} from '@/utils/utils';
import { getCookie } from '@/services/cookie';
import { COOKIE_AUTH_DETAILS, pageUrls } from '@/config/constants/keys';
import { UI_DATE_LOWER_FORMAT } from '@/config/constants/static';
const layout = window.$environment?.CURRENT_LAYOUT;
const Input = lazy(() => import(`@/components/UI/Input/${layout}`));
const Label = lazy(() => import(`@/components/UI/FormLabel/${layout}`));
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Button = lazy(() => import(`@/components/UI/Button/${layout}`));
const SubHeader = lazy(() => import(`@/components/SubHeader/${layout}`));
const Select = lazy(() => import(`@/components/UI/Select/${layout}`));
const FormStyle = require(`@/components/UI/Form/${layout}/serverStyle`).default;
const CheckBoxToggle = lazy(() => import(`@/components/UI/Checkbox/${layout}`));
const Link = lazy(() => import(`@/components/UI/Link`));
const RadioButton = lazy(() => import(`@/components/UI/Radio/${layout}`));

const RenderComponent = ({
  values,
  handleEdit,
  errors,
  isNotValid,
  validateForm,
  customProps: {
    i18n,
    Styled,
    history,
    hasSaveApiStatus,
    states,
    underWriters,
    lossControllers,
    radiusCompliances,
    type,
    allCycleRules,
    allCycleExceptions,
    allVehicleTypes
  },
  submitAction,
  cancelAction
}) => {
  const token = getCookie(COOKIE_AUTH_DETAILS);
  const maintArray = [1, 2, 3, 4, 5, 6, 7];
  const maintPeriod = objectArrayFromSameField(maintArray);
  const showError = (field, validationErrors, isNotValid, apiError) => {
    if (validationErrors[field] && !isNotValid) {
      return <Message type="error" message={errors[field]} />;
    }
    if (apiError?.[field]) {
      return <Message type="error" message={apiError[field]} />;
    }
  };
  const dotUpdation = (value) => {
    return value.substring(0, 9);
  };

  return (
    <Styled.Wrapper>
      {type === 'edit' && (
        <div className="section-wrapper">
          <SubHeader
            text={i18n.t('common.mainInformation')}
            className="subheaderSection companyTitle"
            variant="h2"
          />
          <Styled.wrapperSection>
            <Styled.leftWrapper>
              <div className="companyForm firstRow">
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="company" variant="body1">
                    {i18n.t('companies.companyName')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="company"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'company' })
                      }
                      as={Input}
                      placeholder={i18n.t('companies.companyName')}
                      value={values['company']}
                      type="text"
                      className="borderStyle"
                      id="company"
                      onLoadFocus
                    />
                    {showError(
                      'company',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="dotNumber" variant="body1">
                    {i18n.t('common.dotNumber')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="dotNumber"
                      id="dotNumber"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'dotNumber' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.dotNumber')}
                      value={values['dotNumber']}
                      type="text"
                      updateValue={(e) => dotUpdation(e)}
                    />
                    {showError(
                      'dotNumber',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                {layout !== 'Clutch' && (
                  <>
                    <FormStyle.FieldsWrapper>
                      <Label htmlFor="maintenancePeriod" variant="body1">
                        {i18n.t('companyData.maintainancePeriodMonth')}
                      </Label>
                      <FormStyle.TextWrapper>
                        <Field
                          name="maintenancePeriod"
                          id="maintenancePeriod"
                          onChange={(event) =>
                            handleEdit(event, { field: 'maintenancePeriod' })
                          }
                          as={Select}
                          placeholderText={i18n.t(
                            'companyData.maintainancePeriod'
                          )}
                          value={getFilterLocaleValues([values['maintenancePeriod']], i18n)}
                          suggestions={getFilterLocaleValues(maintPeriod, i18n)}
                        />
                        {showError(
                          'maintenancePeriod',
                          errors,
                          isNotValid,
                          hasSaveApiStatus?.errorMessage
                        )}
                      </FormStyle.TextWrapper>
                    </FormStyle.FieldsWrapper>
                    <FormStyle.FieldsWrapper>
                      <Label htmlFor="maintenanceMileage" variant="body1">
                        {i18n.t('companyData.maintainanceMileage')}
                      </Label>
                      <FormStyle.TextWrapper>
                        <Field
                          name="maintenanceMileage"
                          id="maintenanceMileage"
                          handleOnChange={(event) =>
                            handleEdit(event, { field: 'maintenanceMileage' })
                          }
                          as={Input}
                          placeholder={i18n.t(
                            'companyData.maintainanceMileage'
                          )}
                          value={values['maintenanceMileage']}
                          type="text"
                          className="borderStyle"
                        />
                        {showError(
                          'maintenanceMileage',
                          errors,
                          isNotValid,
                          hasSaveApiStatus?.errorMessage
                        )}
                      </FormStyle.TextWrapper>
                    </FormStyle.FieldsWrapper>
                    <FormStyle.FieldsWrapper>
                      <FormStyle.TextWrapper className="subscriptionCompany">
                        <Link
                          id="subscriptionTier"
                          newPage
                          href={pageUrls.subscriptionLink}
                        >
                          {i18n.t('registration.subscriptionTier')}
                        </Link>
                      </FormStyle.TextWrapper>
                      <FormStyle.CheckBoxWrapper direction="row">
                        <RadioButton
                          aria-labelledby="subscriptionTier"
                          value={values.subscriptionTier}
                          options={
                            window.$environment.SERVICE_PROVIDER === 'em'
                              ? [
                                  { label: 'Fleet Xpress', value: 'STANDARD' },
                                  { label: 'Xtreme', value: 'PRO' }
                                ]
                              : window.$environment.SERVICE_PROVIDER === 'tp'
                              ? [
                                  { label: 'Professional', value: 'LITE' },
                                  { label: 'Deluxe', value: 'STANDARD' },
                                  { label: 'Fleet', value: 'PRO' }
                                ]
                              : [
                                  { label: 'Prime8 ELD', value: 'LITE' },
                                  { label: 'Fleet Xpress', value: 'STANDARD' },
                                  { label: 'Xtreme', value: 'PRO' }
                                ]
                          }
                          onClick={(event) => {
                            handleEdit(event, {
                              field: 'subscriptionTier',
                              type: 'radio'
                            });
                          }}
                        />
                      </FormStyle.CheckBoxWrapper>
                    </FormStyle.FieldsWrapper>
                  </>
                )}
              </div>
              <div className="companyForm">
                {window.$environment.SERVICE_PROVIDER !== 'clutch' &&
                  window.$environment.SERVICE_PROVIDER !== 'riskTheory' && (
                    <>
                      <FormStyle.FieldsWrapper className="valueWrapper">
                        <Label variant="body1">
                          {i18n.t('companyData.contractExpirationDate')}
                        </Label>
                        <FormStyle.TextWrapper className="values">
                          {values.contractExpirationDate}
                        </FormStyle.TextWrapper>
                      </FormStyle.FieldsWrapper>

                      <FormStyle.FieldsWrapper className="valueWrapper">
                        <Label variant="body1">
                          {i18n.t('companyData.currentSubscriptionCost')}
                        </Label>
                        <FormStyle.TextWrapper className="values">
                          {values.currentSubscriptionCost}
                        </FormStyle.TextWrapper>
                      </FormStyle.FieldsWrapper>
                    </>
                  )}
                <FormStyle.FieldsWrapper className="valueWrapper">
                  <Label variant="body1">
                    {i18n.t('companyData.noOfTrucks')}
                  </Label>
                  <FormStyle.TextWrapper className="values">
                    {values.initialTrucks}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper className="valueWrapper">
                  <Label variant="body1">
                    {i18n.t('companyData.homeTimeZone')}
                  </Label>
                  <FormStyle.TextWrapper className="values">
                    {values.timeZone}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <div className="checkboxesCompany oldviewAlign">
                  {token.roles?.includes('FLMANAGER') && (
                    <FormStyle.FieldsWrapper
                      htmlFor="skipVehicleFilter"
                      className="agreeTerms"
                    >
                      <FormStyle.TextWrapper>
                        <CheckBoxToggle
                          aria-labelledby="agree"
                          type="NormalGrey"
                          checked={values.skipVehicleFilter}
                          label={i18n.t('companyData.skipcompanyCheckbox')}
                          onClick={(event) =>
                            handleEdit(event, {
                              field: 'skipVehicleFilter'
                            })
                          }
                          name="skipVehicleFilter"
                        />
                      </FormStyle.TextWrapper>
                    </FormStyle.FieldsWrapper>
                  )}
                  {token?.roles?.includes('FLMANAGER') &&
                    hasEnabledFeature('editOilFieldWaitingEnabled') && (
                      <FormStyle.FieldsWrapper
                        htmlFor="showOw"
                        className="agreeTerms"
                      >
                        <FormStyle.TextWrapper>
                          <CheckBoxToggle
                            aria-labelledby="agree"
                            type="NormalGrey"
                            checked={values.showOw}
                            label={i18n.t('companyData.enablecompanyCheckbox')}
                            onClick={(event) =>
                              handleEdit(event, {
                                field: 'showOw'
                              })
                            }
                            name="showOw"
                          />
                        </FormStyle.TextWrapper>
                      </FormStyle.FieldsWrapper>
                    )}
                </div>
              </div>
            </Styled.leftWrapper>
            <Styled.rightWrapper>
              <div className="companyForm">
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="address" variant="body1">
                    {i18n.t('common.address')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="address"
                      id="address"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'address' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.address')}
                      value={values['address']}
                      type="text"
                    />
                    {showError(
                      'address',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="city" variant="body1">
                    {i18n.t('common.city')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="city"
                      id="city"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'city' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.city')}
                      value={values['city']}
                      type="text"
                    />
                    {showError(
                      'city',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="state" variant="body1">
                    {i18n.t('common.state')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="state"
                      id="state"
                      onChange={(event) => {
                        handleEdit(event, { field: 'state' });
                      }}
                      as={Select}
                      placeholderText={i18n.t('common.state')}
                      value={values['state']}
                      suggestions={states}
                    />
                    {showError(
                      'state',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="zipCode" variant="body1">
                    {i18n.t('common.zipCode')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="zipCode"
                      id="zipCode"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'zipCode' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.zipCode')}
                      value={values['zipCode']}
                      type="text"
                    />
                    {showError(
                      'zipCode',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="companyPhone" variant="body1">
                    {i18n.t('common.phone')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="companyPhone"
                      id="companyPhone"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'companyPhone' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.phone')}
                      value={values['companyPhone']}
                      type="text"
                      updateValue={phoneNumberValidation}
                    />
                    {showError(
                      'companyPhone',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper width="100%">
                  <Label variant="body1" htmlFor="insuranceExpDate">
                    {i18n.t('companyData.expiryDate')}
                  </Label>
                  <FormStyle.TextWrapper className="userForm">
                    <Field
                      name="insuranceExpDate"
                      onChange={(event) => {
                        handleEdit(event, {
                          field: 'insuranceExpDate',
                          type: 'date'
                        });
                        // setNewValues('hireDate', event)
                      }}
                      locale={getDateLocale()}
                      as={ReactDatePicker}
                      placeholderText={UI_DATE_LOWER_FORMAT}
                      value={values['insuranceExpDate']}
                      type="date"
                      showPopperArrow={false}
                      showTimeSelect={false}
                      showDisabledMonthNavigation
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      isClearable={true}
                      minDate={new Date()}
                    />
                    {showError(
                      'insuranceExpDate',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                {
                  hasEnabledFeature('cameraIntegrationEnabled') &&  window.$environment.SERVICE_PROVIDER == 'riskTheory' && 
                    <FormStyle.FieldsWrapper>
                    <Label htmlFor="speedLimit" variant="body1">
                      {i18n.t('companies.speedLimit')}
                    </Label>
                    <FormStyle.TextWrapper>
                      <Field
                        name="speedLimit"
                        id="speedLimit"
                        handleOnChange={(event) => 
                          handleEdit(event, { field: 'speedLimit' })
                        }
                        as={Input}
                        placeholder={i18n.t('companies.speedLimit')}
                        value={values['speedLimit']}
                        type="number"
                      />
                      {showError(
                        'speedLimit',
                        errors,
                        isNotValid,
                        hasSaveApiStatus?.errorMessage
                      )}
                    </FormStyle.TextWrapper>
                  </FormStyle.FieldsWrapper>
                }
              </div>
              <div className="companyForm">
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="contactPerson" variant="body1">
                    {i18n.t('companyData.contactPerson')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="contactPerson"
                      id="contactPerson"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'contactPerson' })
                      }
                      as={Input}
                      placeholder={i18n.t('companyData.contactPerson')}
                      value={values['contactPerson']}
                      type="text"
                    />
                    {showError(
                      'contactPerson',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                <FormStyle.FieldsWrapper>
                  <Label htmlFor="otherLocationContactPerson" variant="body1">
                    {i18n.t('companyData.otherLocationContactPerson')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="otherLocationContactPerson"
                      id="otherLocationContactPerson"
                      handleOnChange={(event) =>
                        handleEdit(event, {
                          field: 'otherLocationContactPerson'
                        })
                      }
                      as={Input}
                      placeholder={i18n.t(
                        'companyData.otherLocationContactPerson'
                      )}
                      value={values['otherLocationContactPerson']}
                      type="text"
                    />
                    {showError(
                      'otherLocationContactPerson',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                <FormStyle.FieldsWrapper>
                  <Label htmlFor="otherLocationAddress" variant="body1">
                    {i18n.t('companyData.otherLocationAddress')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="otherLocationAddress"
                      id="otherLocationAddress"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'otherLocationAddress' })
                      }
                      as={Input}
                      placeholder={i18n.t('companyData.otherLocationAddress')}
                      value={values['otherLocationAddress']}
                      type="text"
                    />
                    {showError(
                      'otherLocationAddress',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                <FormStyle.FieldsWrapper>
                  <Label htmlFor="otherLocationPhone" variant="body1">
                    {i18n.t('companyData.otherLocationPhoneNumber')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="otherLocationPhone"
                      id="otherLocationPhone"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'otherLocationPhone' })
                      }
                      as={Input}
                      placeholder={i18n.t(
                        'companyData.otherLocationPhoneNumber'
                      )}
                      value={values['otherLocationPhone']}
                      type="text"
                    />
                    {showError(
                      'otherLocationPhone',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                <FormStyle.FieldsWrapper>
                  <Label htmlFor="website" variant="body1">
                    {i18n.t('companyData.website')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="website"
                      id="website"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'website' })
                      }
                      as={Input}
                      placeholder={i18n.t('companyData.website')}
                      value={values['website']}
                      type="text"
                    />
                    {showError(
                      'website',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
              </div>
            </Styled.rightWrapper>
          </Styled.wrapperSection>
        </div>
      )}
      <Styled.InsuranceCompanyWrapper>
        <Styled.leftSection>
          {type === 'edit' &&
            token?.roles?.includes('FLMANAGER') &&
            token?.enterpriseFeatureDto?.cycleRuleEnabled && (
              <div className="cycleruleSection">
                <SubHeader
                  text={i18n.t('common.cycleRule')}
                  className="subheaderSection companyTitle"
                  variant="h2"
                />
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="assignedCycleRules" variant="body1">
                    {i18n.t('common.cycleRule')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="assignedCycleRules"
                      id="assignedCycleRules"
                      onChange={(event) => {
                        if (
                          !event ||
                          !event.length ||
                          (event.length &&
                            !(
                              underscore
                                .pluck(event, 'value')
                                .includes('USA 60h/7 days') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('USA 70h/8 days') ||
                              underscore
                                .pluck(event, 'value')
                                .includes('Texas 70h/7 day')
                            ))
                        ) {
                          handleEdit('', {
                            field: 'assignedCycleExceptions'
                          });
                        }
                        if (
                          event &&
                          event.length &&
                          (underscore
                            .pluck(event, 'value')
                            .includes('USA 60h/7 days') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('USA 70h/8 days')) &&
                          (underscore
                            .pluck(event, 'value')
                            .includes('Local Radius') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('Texas 70h/7 day') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('Canada 70h/7 day South') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('Canada 120h/14 day South') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('Canada 70h/7 day North') ||
                            underscore
                              .pluck(event, 'value')
                              .includes('Canada 120h/14 day North') ||
                            underscore.pluck(event, 'value').includes('Mexico'))
                        ) {
                          handleEdit(allVehicleTypes[0], {
                            field: 'assignedVehicleType'
                          });
                        }
                        if (!event || !event.length) {
                          handleEdit(allVehicleTypes[0], {
                            field: 'assignedVehicleType'
                          });
                        }
                        handleEdit(event, { field: 'assignedCycleRules' });
                        handleEdit('', { field: 'defaultCycleRule' });
                      }}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={getFilterLocaleValues(values['assignedCycleRules'], i18n)}
                      suggestions={getFilterLocaleValues(allCycleRules, i18n)}
                      isClearable
                      isMulti
                    />
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                <FormStyle.FieldsWrapper>
                  <Label htmlFor="assignedCycleExceptions" variant="body1">
                    {i18n.t('companyData.exceptions')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="assignedCycleExceptions"
                      id="assignedCycleExceptions"
                      onChange={(event) => {
                        handleEdit(event, {
                          field: 'assignedCycleExceptions'
                        });
                      }}
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={getFilterLocaleValues(values['assignedCycleExceptions'], i18n)}
                      suggestions={getFilterLocaleValues(allCycleExceptions, i18n)}
                      isDisabled={
                        !values?.assignedCycleRules ||
                        !values?.assignedCycleRules.length ||
                        (values?.assignedCycleRules.length &&
                          !(
                            underscore
                              .pluck(values?.assignedCycleRules, 'value')
                              .includes('USA 60h/7 days') ||
                            underscore
                              .pluck(values?.assignedCycleRules, 'value')
                              .includes('USA 70h/8 days') ||
                            underscore
                              .pluck(values?.assignedCycleRules, 'value')
                              .includes('Texas 70h/7 day')
                          ))
                      }
                      isClearable
                      isMulti
                    />
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                <FormStyle.FieldsWrapper>
                  <Label htmlFor="defaultCycleRule" variant="body1">
                    {i18n.t('companyData.defaultCycleRule')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="defaultCycleRule"
                      id="defaultCycleRule"
                      onChange={(event) =>
                        handleEdit(event, { field: 'defaultCycleRule' })
                      }
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={getFilterLocaleValues([values['defaultCycleRule']], i18n)}
                      suggestions={getFilterLocaleValues(values['assignedCycleRules'] || [], i18n)}
                    />
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                <FormStyle.FieldsWrapper>
                  <Label htmlFor="assignedVehicleType" variant="body1">
                    {i18n.t('companyData.vehicleType')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="assignedVehicleType"
                      id="assignedVehicleType"
                      onChange={(event) =>
                        handleEdit(event, { field: 'assignedVehicleType' })
                      }
                      as={Select}
                      placeholder={i18n.t('common.pleaseSelect')}
                      value={getFilterLocaleValues([values['assignedVehicleType']], i18n)}
                      suggestions={
                        getFilterLocaleValues(values?.assignedCycleRules &&
                        values?.assignedCycleRules.length &&
                        (underscore
                          .pluck(values?.assignedCycleRules, 'value')
                          .includes('USA 60h/7 days') ||
                          underscore
                            .pluck(values?.assignedCycleRules, 'value')
                            .includes('USA 70h/8 days')) &&
                        !underscore
                          .pluck(values?.assignedCycleRules, 'value')
                          .includes('Local Radius') &&
                        !underscore
                          .pluck(values?.assignedCycleRules, 'value')
                          .includes('Texas 70h/7 day') &&
                        !underscore
                          .pluck(values?.assignedCycleRules, 'value')
                          .includes('Canada 70h/7 day South') &&
                        !underscore
                          .pluck(values?.assignedCycleRules, 'value')
                          .includes('Canada 120h/14 day South') &&
                        !underscore
                          .pluck(values?.assignedCycleRules, 'value')
                          .includes('Canada 70h/7 day North') &&
                        !underscore
                          .pluck(values?.assignedCycleRules, 'value')
                          .includes('Canada 120h/14 day North') &&
                        !underscore
                          .pluck(values?.assignedCycleRules, 'value')
                          .includes('Mexico')
                          ? allVehicleTypes
                          : [{ label: ' Property', value: 'Property' }], i18n)
                      }
                    />
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
              </div>
            )}
          {type === 'add' && (
            <>
              <div className="cycleruleSection">
                <SubHeader
                  text={i18n.t('common.mainInformation')}
                  className="subheaderSection companyTitle"
                  variant="h2"
                />
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="company" variant="body1">
                    {i18n.t('companies.companyName')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="company"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'company' })
                      }
                      as={Input}
                      placeholder={i18n.t('companies.companyName')}
                      value={values['company']}
                      type="text"
                      className="borderStyle"
                      id="company"
                      onLoadFocus
                    />
                    {showError(
                      'company',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="dotNumber" variant="body1">
                    {i18n.t('common.dotNumber')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="dotNumber"
                      id="dotNumber"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'dotNumber' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.dotNumber')}
                      value={values['dotNumber']}
                      type="text"
                      updateValue={(e) => dotUpdation(e)}
                    />
                    {showError(
                      'dotNumber',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="address" variant="body1">
                    {i18n.t('common.address')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="address"
                      id="address"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'address' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.address')}
                      value={values['address']}
                      type="text"
                    />
                    {showError(
                      'address',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="city" variant="body1">
                    {i18n.t('common.city')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="city"
                      id="city"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'city' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.city')}
                      value={values['city']}
                      type="text"
                    />
                    {showError(
                      'city',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                <FormStyle.FieldsWrapper>
                  <Label htmlFor="state" variant="body1">
                    {i18n.t('common.state')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="state"
                      id="state"
                      onChange={(event) => {
                        handleEdit(event, { field: 'state' });
                      }}
                      as={Select}
                      placeholderText={i18n.t('common.state')}
                      value={values['state']}
                      suggestions={states}
                    />
                    {showError(
                      'state',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper width="100%">
                  <Label variant="body1">
                    {i18n.t('companyData.expiryDate')}
                  </Label>
                  <FormStyle.TextWrapper className="userForm">
                    <Field
                      name="insuranceExpDate"
                      onChange={(event) => {
                        handleEdit(event, {
                          field: 'insuranceExpDate',
                          type: 'date'
                        });
                        // setNewValues('hireDate', event)
                      }}
                      locale={getDateLocale()}
                      as={ReactDatePicker}
                      placeholderText={UI_DATE_LOWER_FORMAT}
                      value={values['insuranceExpDate']}
                      type="date"
                      showPopperArrow={false}
                      showTimeSelect={false}
                      showDisabledMonthNavigation
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      isClearable={true}
                    />
                    {showError(
                      'insuranceExpDate',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>

                {
                  hasEnabledFeature('cameraIntegrationEnabled') &&  window.$environment.SERVICE_PROVIDER == 'riskTheory' && 
                    <FormStyle.FieldsWrapper>
                    <Label htmlFor="speedLimit" variant="body1">
                      {i18n.t('companies.speedLimit')}
                    </Label>
                    <FormStyle.TextWrapper>
                      <Field
                        name="speedLimit"
                        id="speedLimit"
                        handleOnChange={(event) => 
                          handleEdit(event, { field: 'speedLimit' })
                        }
                        as={Input}
                        placeholder={i18n.t('companies.speedLimit')}
                        value={values['speedLimit']}
                        type="number"
                      />
                      {showError(
                        'speedLimit',
                        errors,
                        isNotValid,
                        hasSaveApiStatus?.errorMessage
                      )}
                    </FormStyle.TextWrapper>
                  </FormStyle.FieldsWrapper>
                }    
              </div>
              <div className="cycleruleSection">
                <SubHeader
                  text={i18n.t('companyData.fleetManagerInformation')}
                  className="subheaderSection companyTitle"
                  variant="h2"
                />
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="firstName" variant="body1">
                    {i18n.t('common.firstName')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="firstName"
                      id="firstName"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'firstName' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.firstName')}
                      value={values['firstName']}
                      type="text"
                    />
                    {showError(
                      'firstName',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="lastName" variant="body1">
                    {i18n.t('common.lastName')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="lastName"
                      id="lastName"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'lastName' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.lastName')}
                      value={values['lastName']}
                      type="text"
                    />
                    {showError(
                      'lastName',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="email" variant="body1">
                    {i18n.t('common.email')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="email"
                      id="email"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'email' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.email')}
                      value={values['email']}
                      type="text"
                    />
                    {showError(
                      'email',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="userPhone" variant="body1">
                    {i18n.t('common.phone')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="userPhone"
                      id="userPhone"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'userPhone' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.phone')}
                      value={values['userPhone']}
                      updateValue={phoneNumberValidation}
                      type="text"
                    />
                    {showError(
                      'userPhone',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
                <FormStyle.FieldsWrapper>
                  <Label htmlFor="password" variant="body1">
                    {i18n.t('common.password')}
                  </Label>
                  <FormStyle.TextWrapper>
                    <Field
                      name="password"
                      id="password"
                      handleOnChange={(event) =>
                        handleEdit(event, { field: 'password' })
                      }
                      as={Input}
                      placeholder={i18n.t('common.password')}
                      value={values['password']}
                      type="text"
                    />
                    {showError(
                      'password',
                      errors,
                      isNotValid,
                      hasSaveApiStatus?.errorMessage
                    )}
                  </FormStyle.TextWrapper>
                </FormStyle.FieldsWrapper>
              </div>
            </>
          )}
        </Styled.leftSection>
        <Styled.rightSection>
          <div className="cycleruleSection">
            <SubHeader
              text={i18n.t('companyData.insuranceSettings')}
              className="subheaderSection companyTitle"
              variant="h2"
            />
            <FormStyle.FieldsWrapper>
              <Label htmlFor="underwriterId" variant="body1">
                {i18n.t('companyData.underwriter')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="underwriterId"
                  id="underwriterId"
                  onChange={(event) =>
                    handleEdit(event, { field: 'underwriterId' })
                  }
                  as={Select}
                  placeholder={i18n.t('common.pleaseSelect')}
                  value={values['underwriterId']}
                  suggestions={underWriters || []}
                />
                {showError(
                  'underwriterId',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
            <FormStyle.FieldsWrapper>
              <Label htmlFor="lossControlId" variant="body1">
                {i18n.t('companyData.lossControl')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="lossControlId"
                  id="lossControlId"
                  onChange={(event) =>
                    handleEdit(event, { field: 'lossControlId' })
                  }
                  as={Select}
                  placeholder={i18n.t('common.pleaseSelect')}
                  value={values['lossControlId']}
                  suggestions={lossControllers || []}
                />
                {showError(
                  'lossControlId',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
            <FormStyle.FieldsWrapper>
              <Label htmlFor="radiusTolerance" variant="body1">
                {i18n.t('common.radiusTolerance')}
              </Label>
              <FormStyle.TextWrapper>
                <Field
                  name="radiusTolerance"
                  id="radiusTolerance"
                  handleOnChange={(event) =>
                    handleEdit(event, { field: 'radiusTolerance' })
                  }
                  as={Input}
                  placeholder={i18n.t('common.radiusTolerance')}
                  value={values['radiusTolerance']}
                  type="text"
                />
                {showError(
                  'radiusTolerance',
                  errors,
                  isNotValid,
                  hasSaveApiStatus?.errorMessage
                )}
              </FormStyle.TextWrapper>
            </FormStyle.FieldsWrapper>
          </div>
        </Styled.rightSection>
      </Styled.InsuranceCompanyWrapper>
      {hasSaveApiStatus?.errorMessage && (
        <Message type="error" message={hasSaveApiStatus.errorMessage} />
      )}
      <div className="buttonSection">
        <FormStyle.ButtonsWrapper className="submitSection">
          <Button
            className="buttonStyle"
            type="reset"
            label={i18n.t('common.cancel')}
            onClick={(e) => {
              cancelAction();
            }}
          />
          <Button
            id="companyInfoSubmit"
            type="submit"
            label={
              hasSaveApiStatus?.isLoading ? (
                <CircularProgress color="inherit" />
              ) : (
                i18n.t('Save')
              )
            }
            onClick={() => {
              const validateResponse = validateForm();
              validateResponse.then((data) => submitAction(data));
            }}
          />
        </FormStyle.ButtonsWrapper>
      </div>
    </Styled.Wrapper>
  );
};

export default RenderComponent;

RenderComponent.propTypes = {
  values: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isNotValid: PropTypes.bool.isRequired,
  customProps: PropTypes.object.isRequired,
  validateForm: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired
};
